import * as React from "react";
import {view} from "react-easy-state";
import Button, {ButtonSize, ButtonType} from "../../atom/Button/Button";
import styles from './MenuContainer.module.scss'
import {WindowCreator} from "../../service/WindowCreator";
import ButtonContainer from "../../atom/ButtonContainer/ButtonContainer";
import {WindowCloser} from "../../service/WindowCloser";
import {TutorialState, tutorialStore} from "../../store/TutorialStore";
import Container from "../../../di/container";

const MenuContainer = () => {
    const createWindow = (name: string) => {
        WindowCreator(name);
        WindowCloser('menu')
    };

    return (
        <div className={styles.container}>
            <div className={styles.column}>
                <ButtonContainer>
                    <Button text={'Equipment'} icon={'<i class="bi bi-shield-fill"></i>'} type={ButtonType.TYPE_MENU} click={() => {createWindow('equipment')}} size={ButtonSize.TYPE_WIDE}/>
                </ButtonContainer>
                <ButtonContainer>
                    <Button text={'Abilities Tree'} icon={'<i class="bi bi-fire"></i>'} type={ButtonType.TYPE_MENU} click={() => {createWindow('abilitiesTree')}} size={ButtonSize.TYPE_WIDE}/>
                </ButtonContainer>
                <ButtonContainer>
                    <Button text={'Npc Log'} icon={'<i class="bi bi-book-half"></i>'} type={ButtonType.TYPE_MENU} click={() => {createWindow('npcLog')}} size={ButtonSize.TYPE_WIDE}/>
                </ButtonContainer>
                <ButtonContainer>
                    <Button text={'Achievements'} icon={'<i class="bi bi-trophy-fill"></i>'} type={ButtonType.TYPE_MENU} click={() => {createWindow('achievementsWindow')}} size={ButtonSize.TYPE_WIDE}/>
                </ButtonContainer>
                <ButtonContainer>
                    <Button text={'Chat'} icon={'<i class="bi bi-chat-left-text-fill"></i>'} type={ButtonType.TYPE_MENU} click={() => {createWindow('chat')}} size={ButtonSize.TYPE_WIDE}/>
                </ButtonContainer>
                <ButtonContainer>
                    <Button text={'Settings'} icon={'<i class="bi bi-gear-fill"></i>'} type={ButtonType.TYPE_MENU} click={() => {createWindow('settingsWindow')}} size={ButtonSize.TYPE_WIDE}/>
                </ButtonContainer>
            </div>
            <div className={styles.column}>
                <ButtonContainer>
                    <Button text={'Inventory'} icon={'<i class="bi bi-bag-fill"></i>'} type={ButtonType.TYPE_MENU} click={() => {
                        if (tutorialStore.state === TutorialState.STATE_MENU_CLICK) {
                            tutorialStore.state = TutorialState.STATE_INVENTORY_OPENED_INFO;
                        }

                        createWindow('inventory')}
                    } size={ButtonSize.TYPE_WIDE}/>
                </ButtonContainer>
                <ButtonContainer>
                    <Button text={'Statistics'} icon={'<i class="bi bi-mortarboard-fill"></i>'} type={ButtonType.TYPE_MENU} click={() => {createWindow('statistics')}} size={ButtonSize.TYPE_WIDE}/>
                </ButtonContainer>
                <ButtonContainer>
                    <Button text={'Quest Log'} icon={'<i class="bi bi-book-fill"></i>'} type={ButtonType.TYPE_MENU} click={() => {createWindow('questLog')}} size={ButtonSize.TYPE_WIDE}/>
                </ButtonContainer>
                <ButtonContainer>
                    <Button text={'Collections'} icon={'<i class="bi bi-trophy-fill"></i>'} type={ButtonType.TYPE_MENU} click={() => {createWindow('collectionsWindow')}} size={ButtonSize.TYPE_WIDE} isActive={false}/>
                </ButtonContainer>
                <ButtonContainer>
                    <Button text={'Daily Reward'} icon={'<i class="bi bi-award-fill"></i>'} type={ButtonType.TYPE_MENU} click={() => {
                        Container.getNetworkSender().sendDailyRewardOpen();
                    }} size={ButtonSize.TYPE_WIDE}/>
                </ButtonContainer>
                <ButtonContainer>
                    <Button text={'Leaderboard'} icon={'<i class="bi bi-list-ol"></i>'} type={ButtonType.TYPE_MENU} click={() => {
                        Container.getNetworkSender().sendLeaderboardOpen();
                        createWindow('leaderboardWindow')
                        //Container.getNetworkSender().sendHighscoresOpen();
                    }} size={ButtonSize.TYPE_WIDE}/>
                </ButtonContainer>
                <ButtonContainer>
                    <Button text={'Exit Game'} icon={'<i class="bi bi-box-arrow-right"></i>'} type={ButtonType.TYPE_MENU} click={() => {
                        WindowCreator(
                            'yes-no',
                            {
                                title: 'Logout',
                                text: 'Are you sure want to logout?\n\nIf you\'re not registered, your progress will be lost.',
                                yesCallback: () => {
                                    Container.getNetworkSender().sendLogout();
                                    window.location.href = 'https://www.herodonia.com/thank-you-for-playing-herodonia/';
                                },
                                noCallback: () => {}
                            }
                        );
                    }} size={ButtonSize.TYPE_WIDE}/>
                </ButtonContainer>
            </div>
        </div>
    );
}

export default view(MenuContainer);