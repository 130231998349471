export const WindowCreator = (
    name: string,
    details: object = {}
) => {
    let event = new CustomEvent('window-creator', {
        detail: {
            name: name,
            ...details
        },
    });
    window.dispatchEvent(event);
}