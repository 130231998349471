import {store} from "react-easy-state";

type CutsceneStoreType = {
    text: string,
}

const cutsceneStore: CutsceneStoreType = store({
    text: '',
});

export default cutsceneStore;