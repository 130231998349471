import * as React from "react";
import styles from './Item.module.scss'
import {useLongPress} from "use-long-press";
import {useState} from "react";
import globalStore from "../../store/GlobalStore";
import {ClientItem} from "../../../util/ClientItems";

//const clientItems = require('../../../config/clientItems.json');

export enum ItemPriceType {
    TYPE_DEFAULT = '#ffffff',
    TYPE_RED = '#ee3232',
    TYPE_GREEN = '#36bd00',
}

export type ItemProps = {
    position: number,
    details: object,
    id: number;
    quantity?: number;
    forceShowQuantity?: boolean,
    price?: number|null;
    priceType?: ItemPriceType,
    isSelected?: boolean;
    isSelectable?: boolean;
    onClick?: (selected: boolean, position: number, item: {}) => void,
    onLongClick?: null|((position: number, item: {}) => void),
};

const Item = ({position, details = {}, id, quantity = 1, forceShowQuantity = false, price = null, priceType = ItemPriceType.TYPE_DEFAULT, isSelected = false, isSelectable = false, onClick = (selected: boolean) => {}, onLongClick = null}: ItemProps) => {
    const [hover, setHover] = useState(false);
    let spriteId: number = ClientItem(id)['spriteId'];

    const handleClick = (event) => {
        // @ts-ignore
        if (window.justLongClicked === true) {
            return;
        }

        let currentSelected: boolean = !isSelected;

        globalStore.clickPosition = [
            event.clientX ?? event.changedTouches[0].clientX,
            event.clientY ?? event.changedTouches[0].clientY,
        ];

        if (onClick !== undefined) {
            onClick(currentSelected, position, details);
            event.stopPropagation();
        }
    }

    const longPressBind = useLongPress((longPressEvent: React.MouseEvent<Element> | React.TouchEvent<Element> | undefined) => {
        if (onLongClick !== null) {
            onLongClick(position, details);
            return;
        }

        handleClick(longPressEvent);

        let event = new CustomEvent('hold-on-item', {
            detail: {
                details: details,
                // @ts-ignore
                clientX: longPressEvent.clientX ?? longPressEvent.changedTouches[0].clientX,
                // @ts-ignore
                clientY: longPressEvent.clientY ?? longPressEvent.changedTouches[0].clientY
            },
        });
        window.dispatchEvent(event);
    }, {
        onFinish: () => {
            setTimeout(() => {
                // @ts-ignore
                window.justLongClicked = false
            }, 5);
        },
        captureEvent: true
    });

    const getOffsetX = (): number => {
        return (spriteId) % 32;
    }

    const getOffsetY = (): number => {
        return Math.floor(spriteId / 32);
    }

    const px = getOffsetX() / 31*100; //columns - 1 (32 -1)
    const py = getOffsetY() / 21*100; //rows - 1

    // @ts-ignore
    const upgradeLevelStars = details.upgradeLevel > 1 ? details.upgradeLevel : 0;

    return (
        <div className={`${styles.wrapper} ${isSelected ? styles.selected : ''}`}
             data-item-id={id}
             onMouseEnter={(eventt) => {
                 let event1 = new CustomEvent('hold-on-item', {
                     detail: {
                         details: details,
                         // @ts-ignore
                         clientX: eventt.clientX ?? longPressEvent.changedTouches[0].clientX,
                         // @ts-ignore
                         clientY: eventt.clientY ?? longPressEvent.changedTouches[0].clientY
                     },
                 });
                 window.dispatchEvent(event1);
             }}
             onMouseLeave={() => {
                 let event2 = new CustomEvent('hold-on-item-finish');
                 window.dispatchEvent(event2);
            }}
        >
            <div className={`${styles.item} ${quantity < 1 ? styles.inactive : ''}`}
                 onClick={handleClick}
                 {...longPressBind}
                 style={{
                     backgroundPosition: px + "% " + py + '%'
            }}/>
            {upgradeLevelStars > 0 &&
                <span className={styles.upgradeLevel}>
                    {'*'.repeat(upgradeLevelStars)}
                </span>
            }
            {(price !== null) &&
                <span className={styles.price} style={{
                    color: ItemPriceType.TYPE_GREEN
                }}>
                    {price}
                </span>
            }
            {(quantity > 1 || forceShowQuantity || priceType === ItemPriceType.TYPE_GREEN) &&
                <span className={styles.quantity}>
                        {quantity}
                </span>
            }
        </div>
    );
}

export default Item;