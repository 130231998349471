import 'phaser';
import {CreatureService} from "../service/creatureService";
import {EffectService} from "../service/effectService";
import {Creature, CreatureType} from "../model/creature";
import NetworkSender from "../network/networkSender";
import NetworkReceiver from "../network/networkReceiver";
import {MapService} from "../service/mapService";
import Container from "../di/container";
import {CutsceneService} from "../service/cutsceneService";
import {LightLevel, LightService} from "../service/lightService";
import {LoginState, loginStore} from "../ui/store/LoginStore";
import {TutorialState, tutorialStore} from "../ui/store/TutorialStore";
import {AnimatedMapService} from "../service/animatedMapService";
import {SoundEffect, SoundService} from "../service/soundService";
import {PlaySound} from "../util/PlaySound";
import {loadSettings} from "../ui/store/SettingsStore";

export class CursorsWSAD {
    // @ts-ignore
    public up: Phaser.Input.Keyboard.Key;
    // @ts-ignore
    public down: Phaser.Input.Keyboard.Key;
    // @ts-ignore
    public left: Phaser.Input.Keyboard.Key;
    // @ts-ignore
    public right: Phaser.Input.Keyboard.Key;
}

export class MainScene extends Phaser.Scene {
    private MAX_VIEWPORT_TILES = 19;

    // @ts-ignore
    private networkSender: NetworkSender;
    // @ts-ignore
    private networkReceiver: NetworkReceiver;

    private creatureService: CreatureService;
    private effectService: EffectService;
    // @ts-ignore
    private camera: Phaser.Cameras.Scene2D.Camera;
    // @ts-ignore
    private cursors: CursorsWSAD;
    // @ts-ignore
    private pointer: Phaser.Input.Pointer;
    private lastMoveTime: number = 0;
    private smoothFactor: number = 0.8775;
    public myPlayer: Creature|undefined = undefined;
    public myPlayerId: number|undefined;
    public target: Creature|undefined;
    public zoom: number = 1.0;
    public isCameraFreeze: boolean = false;
    private mapService: MapService;
    private cutsceneService: CutsceneService;
    private lightService: LightService;
    private animatedMapService: AnimatedMapService;
    private soundService: SoundService;

    constructor() {
        super({
            key: "MainScene"
        });

        Phaser.Scene.call(this, { key: 'MainScene', active: true });

        // @ts-ignore
        window.mainScene = this;

        this.creatureService = Container.getCreatureService();
        this.effectService = Container.getEffectService();
        this.mapService = Container.getMapService();
        this.cutsceneService = Container.getCutsceneService();
        this.lightService = Container.getLightService();
        this.animatedMapService = Container.getAnimatedMapService();
        this.soundService = Container.getSoundService();
    }

    preload(): void {
        this.mapService.preload();
        this.load.spritesheet('sprites-all', 'assets/images/herodonia_sprites.png', { frameWidth: 32, frameHeight: 32 });
        this.load.spritesheet('effects', 'assets/images/effects.png', { frameWidth: 32, frameHeight: 32 });

        this.creatureService.preloadSpritesheets();
        this.creatureService.preloadMisc();
        this.lightService.preload();
        this.soundService.preload();
        this.camera = this.cameras.main;
    }

    create(): void {
        loadSettings();

        this.mapService.create();

        this.creatureService.preloadAnimations();
        this.effectService.preloadAnimations();
        this.lightService.create();
        this.soundService.create();

        this.cursors = <CursorsWSAD> this.input.keyboard.addKeys({
            up:Phaser.Input.Keyboard.KeyCodes.W,
            down:Phaser.Input.Keyboard.KeyCodes.S,
            left:Phaser.Input.Keyboard.KeyCodes.A,
            right:Phaser.Input.Keyboard.KeyCodes.D
        }, false);

        this.pointer = this.input.activePointer;

        this.networkReceiver = Container.getNetworkReceiver();
        this.networkSender = Container.getNetworkSender();

        const zoomBase = this.cameras.main.height > this.cameras.main.width ? this.cameras.main.height : this.cameras.main.width
        this.zoom = zoomBase / 32 / this.MAX_VIEWPORT_TILES;
        this.cameras.main.setZoom(this.zoom);

        //todo: figure why this works - without it view is blurred until toogle fullscreen, so maybe this clears some cache?
        this.game.scale.setGameSize(this.cameras.main.width, this.cameras.main.height);

        this.input.on('pointerup', (pointer) => {
            if (tutorialStore.state !== TutorialState.STATE_HIDDEN &&
                tutorialStore.state !== TutorialState.STATE_COLLECTABLES_CLICK
            ) {
                return;
            }

            let touchX: number = this.input.activePointer.x;
            let touchY: number = this.input.activePointer.y;

            let touchedX = Math.floor((this.camera.worldView.x + (touchX/this.zoom)) / 32);
            let touchedY = Math.floor((this.camera.worldView.y + touchY/this.zoom) / 32);

            const touchedCreature: Creature|undefined = this.creatureService.getCreatureByPosition(touchedX, touchedY);

            if (touchedCreature === undefined || this.myPlayerId === touchedCreature.getId()) {
                this.networkSender.sendClickOnMap(touchedX, touchedY);
            }
        });

        this.cameras.main.fadeIn(500, 42, 42, 85);
        this.cameras.main.setScroll(152*32 - this.camera.width * 0.5, 297*32 - this.camera.height * 0.5);
        this.lightService.setLightLevel(LightLevel.LIGHT_LEVEL_DAWN, true);
        this.creatureService.addCreature(1337, 148, 299, 'Legendary Hero', CreatureType.TYPE_HIDDEN_PLAYER, 100, 100, 1000, '19', 6);
        this.creatureService.addCreature(1338, 157, 298, 'Water Slime', CreatureType.TYPE_HIDDEN_MONSTER, 100, 100, 1000, '17', 2);
        this.creatureService.addCreature(1339, 147, 295, 'Finch', CreatureType.TYPE_HIDDEN_MONSTER, 100, 100, 1000, 'finch', 6);
        this.creatureService.addCreature(1340, 160, 295, 'Wolf', CreatureType.TYPE_HIDDEN_MONSTER, 100, 100, 1000, 'wolf', 4);
        this.myPlayerId =  1337;
        this.myPlayer = this.creatureService.getCreature(1337);
        this.mapService.updateEnvironment();

        this.scale.on('resize', this.onResize, this);
    }

    update(time: number, delta: number): void {
        this.updatePlayerMovement(time);

        if (this.isCameraFreeze) {
            //todo
        } else {
            if (this.myPlayer && loginStore.state === LoginState.STATE_HIDDEN) {
                this.camera.scrollX = this.smoothFactor * this.camera.scrollX + (1 - this.smoothFactor) * (this.myPlayer.getPixelsX() + 16 - this.camera.width * 0.5);
                this.camera.scrollY = this.smoothFactor * this.camera.scrollY + (1 - this.smoothFactor) * (this.myPlayer.getPixelsY() + 16 - this.camera.height * 0.5);
            } else {
                this.cameras.main.setScroll(152*32 - this.camera.width * 0.5, 297*32 - this.camera.height * 0.5);
            }
        }

        this.lightService.update(time, delta);
        this.animatedMapService.update(time, delta);
    }

    public onResize(): void {
        const zoomBase = this.cameras.main.height > this.cameras.main.width ? this.cameras.main.height : this.cameras.main.width
        this.zoom = zoomBase / 32 / this.MAX_VIEWPORT_TILES;
        this.cameras.main.setZoom(this.zoom);
    }

    public getMyPlayer(): Creature|undefined {
        return this.myPlayer;
    }

    public onPlayerLogged(id: number): void {
        this.soundService.unmute();

        this.myPlayer = this.creatureService.getCreature(id);

        if (this.myPlayer === undefined) {
            throw 'Player creature was not obtained before login was processed.';
        }

        this.myPlayer.isMyPlayer = true;

        this.mapService.updateEnvironment();

        this.camera = this.cameras.main;
        this.camera.scrollX = this.myPlayer.getPixelsX() + 16 - this.camera.width * 0.5;
        this.camera.scrollY = this.myPlayer.getPixelsY() + 16 - this.camera.height * 0.5;

        this.scene.remove('LoadingScene');
    }

    public updatePlayerMovement(time): void {
        if (tutorialStore.state !== TutorialState.STATE_HIDDEN) {
            return;
        }

        if (!this.myPlayer?.isMyPlayer) {
            return;
        }

        // @ts-ignore
        if (window.isUiOpen === true) {
            return;
        }

        let moveLeft = false;
        let moveRight = false;
        let moveUp = false;
        let moveDown = false;

        if (this.cursors.down.isDown || moveDown) {
            this.networkSender.sendPlayerMove(2);
            this.lastMoveTime = time;
        } else if (this.cursors.up.isDown || moveUp) {
            this.networkSender.sendPlayerMove(8);
            this.lastMoveTime = time;
        } else if (this.cursors.left.isDown || moveLeft) {
            this.networkSender.sendPlayerMove(4);
            this.lastMoveTime = time;
        } else if (this.cursors.right.isDown || moveRight) {
            this.networkSender.sendPlayerMove(6);
            this.lastMoveTime = time;
        }
    };

    public setSmoothFactor(factor: number = 0.8775): void {
        this.smoothFactor = factor;
    }

    public cameraBlip(): void {
        this.cameras.main.fadeIn(2500, 255, 255, 255);
    }

    public cameraBlipDeath(): void {
        this.cameras.main.fadeIn(10000, 0, 0, 0);
    }

    public cameraZoom(zoom: number, duration: number = 1000): void {
        this.cameras.main.zoomEffect.start(this.zoom * zoom, duration, 'Sine.easeInOut', true);
    }
}