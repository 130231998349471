import * as React from "react";
import styles from './ItemContainer.module.scss'
import Item, {ItemPriceType} from "../../atom/Item/Item";
import {useEffect, useState} from "react";
import {view} from "react-easy-state";
import {ResetSelectedItems} from "../../service/ResetSelectedItems";
import ItemEmpty from "../../atom/ItemEmpty/ItemEmpty";

type ItemContainerProps = {
    title?: string,
    items?: any,
    priceType?: ItemPriceType,
    isSelectable?: boolean,
    isSelectableMultiple?: boolean,
    onSelect?: (selectedPositions: number[], selectedIds: number[], item: {}) => void,
    noSelectOnlyClick?: boolean,
    centered?: boolean,
    capacity?: number,
    canRender?: null | ((item: object) => boolean),
};

const ItemContainer = ({title = '', items = {}, priceType = ItemPriceType.TYPE_DEFAULT, isSelectable = true, isSelectableMultiple = true, onSelect = () => {}, noSelectOnlyClick = false, centered = false, capacity = 0, canRender = null}: ItemContainerProps) => {
    const [selectedCount, setSelectedCount] = useState<number>(0);
    const [selectedItemPositions, setSelectedItemPositions] = useState<number[]>([]);
    const [realIsSelectable, setRealIsSelectable] = useState<boolean>(isSelectable);

    useEffect(() => {
        window.addEventListener('reset-selected-items', (event) => {
            setSelectedCount(0);
            setSelectedItemPositions([]);
        });
    }, []);

    const getSelectedItemPositions = (): number[] => {
        return selectedItemPositions;
    }

    const getSelectedItemIds = (currentSelectedPositions: number[]): number[] => {
        let itemIds: number[] = [];

        items.forEach((item: any, index: number) => {
            if (currentSelectedPositions.includes(index)) {
                itemIds.push(item['id']);
            }
        });

        return itemIds;
    }

    const onClickOnItem = (selected: boolean, position: number, item: {}) => {
        if (!isSelectable) {
            return;
        }

        if (noSelectOnlyClick) {
            onSelect([position], getSelectedItemIds([position]), item);
            return;
        }

        ResetSelectedItems();

        let selectedPositions: number[] = selectedItemPositions;
        if (selected) {
            if (!isSelectableMultiple) {
                setRealIsSelectable(false);
                selectedPositions = [];
            }

            selectedPositions.push(position)
        } else {
            if (!isSelectableMultiple) {
                setRealIsSelectable(isSelectable);
            }

            selectedPositions = selectedPositions.filter((item) => {
                return item !== position;
            });
        }

        setSelectedItemPositions(selectedPositions);
        setSelectedCount(selectedPositions.length);

        onSelect(selectedPositions, getSelectedItemIds(selectedPositions), item);
    }

    let renderedItems = 0;

    return (
        <div className={styles.itemWrapper}>
            {title &&
            <span className={`${styles.title} ${styles.centered}`}>
                {title}
            </span>
            }
            <div className={`${styles.itemContainer} ${centered ? styles.centered : ''}`}>
                {items.map((item: any, index: number) => {
                    renderedItems++;

                    if (canRender !== null) {
                        if (!canRender(item)) {
                            return null;
                        }
                    }

                    return <Item key={index}
                                 position={index}
                                 details={item}
                                 id={item.id}
                                 quantity={item.quantity ?? item.count ?? 1}
                                 price={item.price ?? null}
                                 priceType={priceType}
                                 isSelectable={realIsSelectable}
                                 isSelected={selectedItemPositions.includes(index)}
                                 onClick={onClickOnItem}
                    />;
                })}
                {capacity > 0 &&
                [...Array(Math.max(0, capacity - renderedItems))].map((e, i) => <ItemEmpty key={i}/>)
                }
            </div>
        </div>
    );
}

export default view(ItemContainer);