export class MapContainer {
    public sprite: Phaser.GameObjects.Sprite;
    public itemId: number;
    public count: number;

    public x: number;
    public y: number;
    public chunkId: number;

    public id: number;

    public constructor(sprite: Phaser.GameObjects.Sprite, itemId: number, count: number, x: number, y: number, chunkId: number, id: number) {
        this.sprite = sprite;
        this.itemId = itemId;
        this.count = count;
        this.x = x;
        this.y = y;
        this.chunkId = chunkId;
        this.id = id;
    }
}