import React, {useEffect, useState} from 'react';
import { store, view } from 'react-easy-state';
import styles from './ServerMessageManager.module.scss'
import {ColorByMessageType} from "../../../util/ColorByType";
import {UniqueId} from "../../../util/UniqueId";

type ServerMessageData = {
    text: string,
    type: number,
    key: string,
}

type ServerMessagesStateType = {
    messages: ServerMessageData[],
}

const serverMessagesState: ServerMessagesStateType = store({
    messages: [],
});

const ServerMessageManager = view((props: any) => {
    useEffect(() => {
        window.addEventListener('server-message-create', (event) => {
            // @ts-ignore
            createServerMessage(event.detail.type, event.detail.text);
            event.stopImmediatePropagation();
        });
        return () => (undefined);
    }, []);

    useEffect(() => {
    }, [props]);

    const createServerMessage = (type: number, text: string) => {
        let key = UniqueId();
        let messageData: ServerMessageData = {
            type: type,
            text: text,
            key: key,
        };

        serverMessagesState.messages.push(messageData);

        setTimeout(() => {
            removeServerMessage(key);
        }, 5000);
    }

    const removeServerMessage = (key: string) => {
        serverMessagesState.messages = serverMessagesState.messages.filter((message, index) => {
            return message.key !== key;
        });
    }

    const displayedTexts: string[] = [];
    const messagesWithoutDuplicated = serverMessagesState.messages.filter((message, index) => {
        if (displayedTexts.includes(message.text)) {
            return false;
        }

        displayedTexts.push(message.text);
        return true;
    });

    return (
        <div className={styles.serverMessageWrapper}>
            {messagesWithoutDuplicated.map((message, index) => (
                <p className={styles.message}
                     key={message.key}
                     style={{color: ColorByMessageType(message.type)}}
                >
                    {message.text}
                </p>
            ))}
        </div>
    );
});

export default ServerMessageManager;