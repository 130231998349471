import {store} from "react-easy-state";

type ChatStoreType = {
    inputMessage: string,
    messagesNew: [string, number][]
}

const chatStore: ChatStoreType = store({
    inputMessage: '',
    messagesNew: [],
});

export default chatStore;