export const ColorByMessageType = (type: number, darkMode: boolean = false) => {
    switch (type) {
        case 0:
        default:
            return darkMode ? '#000000' : '#ffffff'; /** NORMAL **/
        case 1:
            return darkMode ? '#ce2701' : '#ff4400'; /** WARNING **/
        case 2:
            return darkMode ? '#004c7a' : '#009dff'; /** BLUE **/
        case 3:
            return darkMode ? '#007777' : '#00ffff'; /** BLUE_LIGHT **/
        case 4:
            return darkMode ? '#006006' : '#01f10b'; /** GREEN **/
        case 5:
            return darkMode ? '#9b6500' : '#d28600'; /** GOLDEN **/
        case 6:
            return darkMode ? '#9f8500' : '#ffd41a'; /** YELLOW **/
        case 7:
            return darkMode ? '#595959' : '#c7c7c7'; /** GREY_LIGHT **/
        case 8:
            return darkMode ? '#592a85' : '#7135ab'; /** VIOLET_DARK **/
        case 9:
            return darkMode ? '#a13700' : '#ff6a00'; /** ORANGE **/
        case 10:
            return darkMode ? '#00600c' : '#01a10d'; /** GREEN_DARK **/
        case 11:
            return darkMode ? '#606060' : '#9a9a9a'; /** GREY_DARK **/
    }
}