import * as React from "react";
import styles from './Loading.module.scss'
import {LightenDarkenColor} from "../../../util/LightenDarkenColor";
import {useEffect, useState} from "react";

export type LoadingProps = {
    color: string, /** Required. Hex of background color */
    borderColor?: string, /** Optional. Hex of border color */
    ms: number, /** Required. Number of milliseconds that element will be displayed */
    text?: string, /** Optional. Text that'll be displayed inside element */
    successText?: string /** Optional. Text that'll be displayed after initial time will pass. If not provided - "text" will be used.*/
    overlay?: boolean /** Optional. If true - element will be displayed on top of other elements, if false - element will be inserted into div */
};

const Loading = ({color, borderColor = '#222222', ms, text, successText, overlay}: LoadingProps) => {
    const [currentText, setCurrentText] = useState<string>(text ?? '');

    useEffect(() => {
        if (text === undefined) {
            return;
        }

        setTimeout(() => setCurrentText(successText ?? text), ms);
    }, [])

    return (
        <div className={`${styles.wrapper} ${overlay ? styles.overlay : ''}`} style={{
            animationDuration: (ms*2) + 'ms',
            borderColor: borderColor
        }}>
            <div className={styles.inner} style={{
                animationDuration: ms + 'ms',
                backgroundColor: color,
                borderTopColor: LightenDarkenColor(color, 20),
                borderBottomColor: LightenDarkenColor(color, -55),
            }}/>
            {text !== undefined &&
            <h1 className={styles.text}>{currentText}</h1>
            }
        </div>
    );
};

export default Loading;