import WebSocketGetter from "./webSocketGetter";
import settingsStore from "../ui/store/SettingsStore";

export default class NetworkSender {
    private socket: WebSocket;

    constructor(socket: WebSocketGetter) {
        this.socket = socket.get();
    }

    public ping(): void {
        this.socket.send(JSON.stringify({packet: "ping"}));
    };

    public sendPlayerMove(direction: any): void {
        this.socket.send(JSON.stringify({packet: "playerMove", direction: direction}));
    };

    public sendTarget(creatureId: number|null): void {
        this.socket.send(JSON.stringify({packet: "target", creatureId: creatureId}));
    };

    public sendClickOnMap(x: number, y: number): void {
        this.socket.send(JSON.stringify({packet: "clickOnMap", x: x, y: y}));
    };

    public sendCollectTopContainer(x: number, y: number, uniqueId: number|null, position: number): void {
        this.socket.send(JSON.stringify({packet: "collectContainer", x: x, y: y, id: uniqueId, position: position}));
    }

    public sendCollectTopContainerAll(x: number, y: number, uniqueId: number|null): void {
        this.socket.send(JSON.stringify({packet: "collectContainerAll", x: x, y: y, id: uniqueId}));
    }

    public sendDestroyInventoryItem(position: number, quantity: number): void {
        this.socket.send(JSON.stringify({packet: "destroyInventoryItem", position: position, quantity: quantity}));
    }

    public sendEquipItem(position: number): void {
        this.socket.send(JSON.stringify({packet: "equipItem", position: position}));
    }

    public sendDeEquipItem(position: number): void {
        this.socket.send(JSON.stringify({packet: "deEquipItem", position: position}));
    }

    public sendUseInventoryItem(position: number): void {
        this.socket.send(JSON.stringify({packet: "useItem", position: position}));
    }

    public sendUseInventoryItemById(id: number): void {
        this.socket.send(JSON.stringify({packet: "useItemById", id: id}));
    }

    public sendAddShortcut(slot: number, itemId: number): void {
        this.socket.send(JSON.stringify({packet: "addShortcut", slot: slot, id: itemId}));
    }

    public sendRemoveShortcut(slot: number): void {
        this.socket.send(JSON.stringify({packet: "removeShortcut", slot: slot}));
    }

    public sendAddStatisticsPoint(type: string): void {
        this.socket.send(JSON.stringify({packet: "addStatisticsPoint", type: type}));
    }

    public sendUseSpell(spellId: number): void {
        this.socket.send(JSON.stringify({packet: "useSpell", spellId: spellId}));
    }

    public sendChatMessage(msg: string): void {
        this.socket.send(JSON.stringify({packet: "chat", msg: msg}));
    }

    public sendLoginAccount(email: string, password: string): void {
        this.socket.send(JSON.stringify({
            packet: "loginAccount",
            email: email,
            password: password
        }));
    }

    public sendRegisterAccount(name: string, email: string, password: string, terms: boolean, newsletter: boolean, promo: string): void {
        this.socket.send(JSON.stringify({
            packet: "registerAccount",
            name: name,
            email: email,
            password: password,
            terms: terms,
            newsletter: newsletter,
            promo: promo,
        }));
    }

    public sendCreateAccountAndPlayer(name: string, email: string, password: string, terms: boolean, newsletter: boolean, promo: string): void {
        this.socket.send(JSON.stringify({
            packet: "createAccountAndPlayer",
            name: name,
            email: email,
            password: password,
            terms: terms,
            newsletter: newsletter,
            promo: promo,
        }));
    }

    public sendPasswordResetInitialize(email: string): void {
        this.socket.send(JSON.stringify({
            packet: "passwordResetInitialize",
            email: email,
        }))
    }

    public sendPasswordResetConfirmation(email: string, confirmationCode: string, newPassword: string): void {
        this.socket.send(JSON.stringify({
            packet: "passwordResetConfirmation",
            email: email,
            confirmationCode: confirmationCode,
            newPassword: newPassword,
        }))
    }

    public sendLoginPlayer(name: string): void {
        this.socket.send(JSON.stringify({
            packet: "loginPlayer",
            name: name
        }));
    }

    public sendLoginAnotherAccount(): void {
        this.socket.send(JSON.stringify({
            packet: "loginAnotherAccount",
        }));
    }

    public sendPlayAsGuest(): void {
        this.socket.send(JSON.stringify({
            packet: "playAsGuest"
        }));
    }

    public sendBuyFromNpc(itemId: number, quantity: number): void {
        this.socket.send(JSON.stringify({packet: "npcSellItem", itemId: itemId, quantity: quantity}));
    }

    public sendSellToNpc(itemId: number, quantity: number): void {
        this.socket.send(JSON.stringify({packet: "npcBuyItem", itemId: itemId, quantity: quantity}));
    }

    public sendNpcDialogueChoice(choiceId: number): void {
        this.socket.send(JSON.stringify({packet: "npcDialogueChoice", choiceId: choiceId}));
    }

    public sendNpcTradeStart(): void {
        this.socket.send(JSON.stringify({packet: "npcShopInit"}));
    }

    public sendCraftProceed(x: number, y: number, key: number, itemId: number|null = null): void {
        this.socket.send(JSON.stringify({packet: "craftProceed", x: x, y: y, key: key, itemId: itemId}));
    }

    public sendNpcStorageStore(position: number, quantity: number): void {
        this.socket.send(JSON.stringify({packet: "npcStorageStore", position: position, quantity: quantity}));
    }

    public sendNpcStorageWithdraw(position: number, quantity: number): void {
        this.socket.send(JSON.stringify({packet: "npcStorageWithdraw", position: position, quantity: quantity}));
    }

    public sendLeaderboardOpen(): void {
        this.socket.send(JSON.stringify({packet: "leaderboard"}));
    }

    public sendHighscoresOpen(): void {
        this.socket.send(JSON.stringify({packet: "highscores"}));
    }

    public sendDailyRewardOpen(): void {
        this.socket.send(JSON.stringify({packet: "dailyRewardOpen"}));
    }

    public sendDailyRewardClaim(): void {
        this.socket.send(JSON.stringify({packet: "dailyRewardClaim"}));
    }

    public sendLevelUpBoostClaim(type: string): void {
        this.socket.send(JSON.stringify({packet: "levelUpBoostClaim", type: type}));
    }

    public sendLevelUpWindowClosed(): void {
        this.socket.send(JSON.stringify({packet: "levelUpWindowClosed"}));
    }

    public sendLogout(): void {
        this.socket.send(JSON.stringify({packet: "logout"}));
    }
}