import * as React from "react";
import styles from './Input.module.scss';
import {view} from "react-easy-state";

export type AbstractInputProps = {
    name: string,
    onUpdate: (inputName: string, value: string) => void,
    label: string|undefined,
    value: string|number,
    error?: string|undefined,
    properties: object;
};

const AbstractInput = ({ name, onUpdate, label = undefined, value, error = undefined, properties = {}}: AbstractInputProps) => {
    // @ts-ignore
    const isRequired = properties.required ? styles['required'] : '';
    return (
        <div className={styles.inputWrapper}>
            {label &&
            <span className={styles.label}>
                {label}
                {isRequired &&
                <span className={styles.required}> *</span>
                }
            </span>
            }
            <input value={value} onInput={(e) => {
                let value = (e.target as HTMLInputElement).value;
                onUpdate(name, value);
            }} className={`${styles.input} ${error !== undefined ? styles.error : ''}`} {...properties}/>
            {error !== undefined &&
                <small>{error}</small>
            }
        </div>
    );
};

export default view(AbstractInput);