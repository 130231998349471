import {store} from "react-easy-state";

type NpcLogStoreType = {
    npcs: {[index: string]: string[]},
}

const npcLogStore: NpcLogStoreType = store({
    npcs: {},
});

export default npcLogStore;