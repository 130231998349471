import * as React from "react";
import AbstractInput from "./AbstractInput";
import {view} from "react-easy-state";

export type TextInputProps = {
    type?: string,
    name: string,
    onUpdate: (inputName: string, value: string) => void,
    label?: string,
    placeholder?: string,
    defaultValue?: string,
    value?: string,
    error?: string|undefined,
    minLength?: number,
    maxLength?: number,
    required?: boolean,
};

const TextInput = ({ type = 'text', name, onUpdate, label = undefined, placeholder = '', value = '', error = undefined, minLength = 0, maxLength = 999999, required = false }: TextInputProps) => {
    return (
        <AbstractInput onUpdate={onUpdate} name={name} label={label} value={value} error={error} properties={{
            type: type,
            label: label,
            placeholder: placeholder,
            minLength: minLength,
            maxLength: maxLength,
            required: required
        }}/>
    );
};

export default view(TextInput);