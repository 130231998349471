import {NotificationType} from "../atom/Notification/Notification";

export const NotificationCreator = (
    isTop: boolean,
    type: NotificationType,
    title: string,
    text: string,
    expiration: number = 5000,
    items: object[] = [],
) => {
        let event = new CustomEvent('notification-create', {
        detail: {
            isTop: isTop,
            type: type,
            title: title,
            text: text,
            expiration: expiration,
            items: items,
        },
    });
    window.dispatchEvent(event);
}