import {store} from "react-easy-state";

type QuestObjectivesStoreType = {
    objectives: Map<string, Map<string, string>>,
}

const questObjectivesStore: QuestObjectivesStoreType = store({
    objectives: new Map<string, Map<string, string>>(),
});

export default questObjectivesStore;