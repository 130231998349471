export class Light {
    private mask: Phaser.GameObjects.Image;
    private maskColor: Phaser.GameObjects.Image;
    private x: number;
    private y: number;
    private chunkId: number;
    private enabled: boolean;
    private tint: number;

    public constructor(
        tint: number,
        mask: Phaser.GameObjects.Image,
        maskColor: Phaser.GameObjects.Image,
        x: number,
        y: number,
        chunkId: number,
        enabled: boolean = true
    ) {
        this.tint = tint;
        this.mask = mask;
        this.maskColor = maskColor;
        this.x = x;
        this.y = y;
        this.chunkId = chunkId;
        this.enabled = enabled;
    }

    getMask(): Phaser.GameObjects.Image {
        return this.mask;
    }

    setMask(value: Phaser.GameObjects.Image) {
        this.mask = value;
    }

    getMaskColor(): Phaser.GameObjects.Image {
        return this.maskColor;
    }

    setMaskColor(value: Phaser.GameObjects.Image) {
        this.maskColor = value;
    }

    getX(): number {
        return this.x;
    }

    setX(value: number) {
        this.x = value;
    }

    getY(): number {
        return this.y;
    }

    setY(value: number) {
        this.y = value;
    }

    getChunkId(): number {
        return this.chunkId;
    }

    setChunkId(value: number) {
        this.chunkId = value;
    }

    getEnabled(): boolean {
        return this.enabled;
    }

    setEnabled(value: boolean) {
        this.enabled = value;
    }

    getTint(): number {
        return this.tint;
    }

    setTint(tint: number) {
        this.tint = tint;
    }
}