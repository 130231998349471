export const DomElementPositionAndSize = (elementType: string, elementContents: string): DOMRect|null => {
    const elements = document.evaluate("//" + elementType + "[contains(., '" + elementContents + "')]", document, null, XPathResult.ANY_TYPE, null );

    const element = elements.iterateNext();
    if (element === null) {
        return null;
    }

    // @ts-ignore
    return element.getBoundingClientRect() as DOMRect;
}

export const DomElementPositionAndSizeByAttribute = (attribute: string, value: string): DOMRect|null => {
    const element = document.querySelector('[' + attribute + '="' + value + '"]');

    if (element === null) {
        return null;
    }

    return element.getBoundingClientRect() as DOMRect;
}