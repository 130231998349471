import React, {useEffect} from 'react';
import styles from './Notification.module.scss'
import ItemRow from "../../component/ItemRow/ItemRow";

export enum NotificationType {
    TYPE_SUCCESS = 'success',
    TYPE_INFO = 'info',
    TYPE_ALERT = 'alert'
}

export type NotificationData = {
    type: NotificationType;
    title: string,
    text: string,
    expiration: number,
    items: object[],
    index: string
    notificationsLeft: number,
}

const Notification = ({type, title, text, expiration, items, index, notificationsLeft}: NotificationData) => {
    useEffect(() => {
        return () => (undefined);
    }, []);

    useEffect(() => {
    }, [index]);

    const accentClassName: string = styles[type];

    return (
        <div className={styles.notificationWrapper}>
            <span className={styles.notification} style={{
                animationDuration: expiration + 'ms'
            }}>
                <div className={styles.title}>
                    {title}
                </div>
                <div className={`${styles.divider} ${accentClassName}`}/>
                <div className={styles.text} dangerouslySetInnerHTML={{__html: text}}>
                </div>
                {items.map((item, itemIndex) => {
                    return <ItemRow
                        // @ts-ignore
                        key={index + item.id}
                        position={itemIndex}
                        details={item}
                        // @ts-ignore
                        id={item.id}
                        // @ts-ignore
                        quantity={item.quantity}
                        isSelectable={false}
                    />
                })}
                {notificationsLeft > 0 &&
                    <div className={styles.notificationsLeft}>{notificationsLeft}</div>
                }
            </span>
        </div>
    );
};

export default Notification;