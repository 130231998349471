import {ActionsPopupDetail, ActionsPopupDetailSingular} from "../manager/ActionsPopup/ActionsPopupDetail";

export const ActionsPopupCreator = (details: ActionsPopupDetailSingular[]) => {
    let detail: ActionsPopupDetail = {
        details: details,
    }

    let event = new CustomEvent('actions-popup-create', {
        detail: detail,
    });
    window.dispatchEvent(event);
}