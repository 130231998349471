import * as React from "react";
import {view} from "react-easy-state";
import Window, {WindowProps, WindowStyle} from "./Window";
import Accordion from "../component/Accordion/Accordion";
import collectionsStore, {KnownItem} from "../store/CollectionsStore";
import ItemContainer from "../component/ItemContainer/ItemContainer";

const CollectionsWindow = ({children, windowState}: WindowProps) => {
    const getTitleColor = (unlocked: number, total: number): string => {
        if (unlocked < total) {
            return '#eeeeee';
        }

        return '#3cd700';
    }

    return (
        <Window title={'<i class="bi bi-trophy-fill"></i> Collections'} windowState={windowState} windowStyle={WindowStyle.STYLE_SCROLL_NARROW}>
            {!!collectionsStore.collections.length &&
            <Accordion>
                {
                    collectionsStore.collections.map((collection) => {
                        let unlocked: number = 0;
                        let total: number = collection.items.length;

                        let items = collection.items.map((item) => {
                            item.quantity = Number(KnownItem(item.id));
                            unlocked += item.quantity;

                            return item;
                        });

                        return <ul key={collection.title} data-title={'(' + unlocked + '/' + total + ') ' + collection.title} data-color={getTitleColor(unlocked, total)}>
                            <ItemContainer
                                items={items}
                                isSelectable={false}
                                isSelectableMultiple={false}
                                noSelectOnlyClick={true}
                            />
                        </ul>
                    })
                }
            </Accordion>
            }
        </Window>
    );
};

export default view(CollectionsWindow);