import * as React from "react";
import styles from './LargeBar.module.scss';
import {view} from "react-easy-state";

export enum LargeBarType {
    TYPE_HEALTH = 'health',
    TYPE_MANA = 'mana',
    TYPE_EXPERIENCE = 'experience',
}

type MyProps = {
    type: LargeBarType,
    isDisplayNumber: boolean,
    text?: string,
    value: number,
    maxValue: number,
};

const LargeBar = ({type, isDisplayNumber, value, maxValue, text = ''}: MyProps) => {
    return (
        <div className={styles.largeBar}>
            <div className={`${styles.largeBarFill} ${styles[type]}`} style={{
                width: value/maxValue*100.0 + '%'
            }}/>
                <span className={styles.text}>
                    {isDisplayNumber &&
                    text + value + '/' + maxValue}
                </span>
        </div>
    );
}

export default view(LargeBar);