import * as React from "react";
import styles from './RangeSlider.module.scss';
import {view} from "react-easy-state";
import {ForwardedRef, forwardRef, useState} from "react";

export type RangeSliderUncontrolledProps = {
    name: string,
    min: number,
    max: number,
    defaultValue: number
};

const RangeSliderUncontrolled = forwardRef(({name, min, max, defaultValue}: RangeSliderUncontrolledProps, ref: ForwardedRef<HTMLInputElement>) => {
    const [value, setValue] = useState<number>(defaultValue);

    return (
        <div className={styles.container}>
            <span className={styles.min}>{min}</span>
            <input type="range" min={min} max={max} defaultValue={defaultValue} ref={ref} className={styles.slider} onChange={() => {
                // @ts-ignore
                setValue(ref.current.value)}
            }/>
            <span className={styles.max}>{max}</span>
            <p className={styles.name}>{name}: <span className={styles.value}>{value}</span></p>
        </div>
    );
});

export default view(RangeSliderUncontrolled);