import {store} from "react-easy-state";

type StorageStoreType = {
    items: any[],
}

const storageStore: StorageStoreType = store({
    items: [],
});

export default storageStore;

export const getQuantityOfItemId = (itemId: number): number => {
    let quantity = 0;
    storageStore.items.forEach((item) => {
        if (item.id !== itemId) {
            return;
        }

        quantity += item.quantity;
    });

    return quantity;
}