import * as React from "react";
import styles from './ItemStatistics.module.scss'
import {useEffect, useRef, useState} from "react";
import {FormatItemStatistics} from "./FormatItemStatistics";

const ItemStatistics = () => {
    const [show, setShow] = useState<boolean>(false);
    const [statistics, setStatistics] = useState<any>([]);
    const [position, setPosition] = useState<[number, number]>([0, 0]);

    const formattedStatistics = FormatItemStatistics(statistics);

    const statisticsElement = useRef(null);

    useEffect(() => {
        if (statisticsElement.current) {
            observer.current.observe(statisticsElement.current);
        }

        window.addEventListener('hold-on-item', (event) => {
            // @ts-ignore
            if (Object.keys(event.detail.details).length < 1) {
                return;
            }

            setShow(true);
            // @ts-ignore
            setPosition([event.detail.clientX, event.detail.clientY]);
            // @ts-ignore
            setStatistics(event.detail.details);

            // @ts-ignore
            const height = statisticsElement.current.clientHeight;
            // @ts-ignore
            resizeIfNeeded(height, event.detail.clientX, event.detail.clientY);
        });
        window.addEventListener('hold-on-item-finish', (event) => {
            closeHandler();
        });
        return () => {
            if (statisticsElement.current !== null) {
                observer.current.unobserve(statisticsElement.current);
            }
        };
    }, []);

    const closeHandler = () => {
        setShow(false);
    }

    let notSeparatorRendered = false;
    let [positionX, positionY] = position;
    // @ts-ignore
    window.statisticsPosition = [positionX, positionY];

    const observer = React.useRef(
        new ResizeObserver(entries => {
            const { height } = entries[0].contentRect;
            resizeIfNeeded(height);
        })
    );

    const resizeIfNeeded = (height: number, positionX: number|undefined = undefined, positionY: number|undefined = undefined) => {
        // @ts-ignore
        const positionRealX = positionX != undefined ? positionX : window.statisticsPosition[0];
        // @ts-ignore
        const positionRealY = positionY != undefined ? positionY : window.statisticsPosition[1];
        // @ts-ignore
        if ((height + positionRealY) > (window.innerHeight - 32)) {
            // @ts-ignore
            setPosition([positionRealX, window.innerHeight - height - 32])
        }
    }

    return (
        <div className={styles.statisticsOverlay} style={{
            visibility: show ? "hidden" : "hidden",
        }} onClick={() => {
            closeHandler();
        }}
        >
            <div ref={statisticsElement} className={`${styles.statistics} ${show ? styles.active : styles.inactive}`} style={{
                visibility: show ? "visible" : "hidden",
                opacity: show ? 1 : 0,
                marginLeft: position[0] > window.innerWidth/2 ? positionX - 16 : positionX + 16,
                marginTop: positionY,
                transform: position[0] > window.innerWidth/2 ? "translateX(-100%)" : "inherit"
            }}>
                <h2 className={styles.title}>
                    {statistics.name}
                </h2>
                <ul className={styles.statisticsList}>
                    {
                        Object.keys(formattedStatistics).map(function(key) {
                            if (formattedStatistics[key] === 'separator') {
                                if (!notSeparatorRendered) {
                                    return;
                                } else {
                                    notSeparatorRendered = false;
                                }

                                return <div className={styles.separator} key={key}/>;
                                //return <hr className={styles.separator} key={key}/>
                            } else {
                                notSeparatorRendered = true;
                            }

                            return <li className={styles.statisticsEntry} key={key} dangerouslySetInnerHTML={{ __html: formattedStatistics[key] }}/>
                        })
                    }
                </ul>
            </div>
        </div>
    );
}

export default ItemStatistics;