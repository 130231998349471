import * as React from "react";
import {view} from "react-easy-state";
import Window, {WindowProps, WindowStyle} from "./Window";
import Register from "../component/Register/Register";

const RegisterWindow = ({children, windowState}: WindowProps) => {
    return (
        <Window title={'<i class="bi bi-person-bounding-box"></i>\n Create New Account'} windowState={windowState} windowStyle={WindowStyle.STYLE_NARROW}>
            <Register/>
        </Window>
    );
};

export default view(RegisterWindow);