import * as React from "react";
import {view} from "react-easy-state";
import Window, {WindowProps, WindowStyle} from "./Window";
import RangeSlider from "../atom/RangeSlider/RangeSlider";
import settingsStore, {saveSettings} from "../store/SettingsStore";
import Container from "../../di/container";

const SettingsWindow = ({children, windowState}: WindowProps) => {
    return (
        <Window title={'<i class="bi bi-gear-fill"></i> Settings'} windowState={windowState} windowStyle={WindowStyle.STYLE_NARROW_FORCE}>
            <h2>Sound</h2>
            <RangeSlider
                name={'Effects Volume'}
                min={0}
                max={100}
                value={settingsStore.effectsVolume}
                onChange={(value: number) => {
                    settingsStore.effectsVolume = value;
                    saveSettings();
                }}
            />
            <RangeSlider
                name={'Background Volume'}
                min={0}
                max={100}
                value={settingsStore.backgroundVolume}
                onChange={(value: number) => {
                    settingsStore.backgroundVolume = value;
                    Container.getSoundService().volumeUpdated();
                    saveSettings();
                }}
            />
            <RangeSlider
                name={'Music Volume'}
                min={0}
                max={100}
                value={settingsStore.musicVolume}
                onChange={(value: number) => {
                    settingsStore.musicVolume = value;
                    Container.getSoundService().volumeUpdated();
                    saveSettings();
                }}
            />
        </Window>
    );
};

export default view(SettingsWindow);