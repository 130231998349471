import * as React from "react";
import styles from './SkillDescription.module.scss'
import {useLongPress} from "use-long-press";
import {ItemTypesToTitle} from "../../../util/ItemTypeToTitle";

const spells = require('../../../config/clientSpells.json');

type SkillDescriptionProps = {
    position: number,
    id: number;
    isIcon?: boolean,
    isDisabled?: boolean,
    isSelected?: boolean;
    isSelectable?: boolean;
    onClick?: null|((selected: boolean, position: number, id: number) => void),
    onLongClick?: null|((id: number) => void),
    children?: React.ReactNode,
};

const SkillDescription = ({position, id, isIcon = false, isDisabled = false, isSelected = false, isSelectable = false, onClick = null, onLongClick = null, children = []}: SkillDescriptionProps) => {
    const name: string = spells[id]['name'];
    const description: string = spells[id]['description'];
    const image: string = spells[id]['image'];
    const parentIds: number[] = spells[id]['parentIds'];
    const archetype: string = spells[id]['archetype'];
    const requiredStrength: number = spells[id]['requiredStrength'];
    const requiredMagic: number = spells[id]['requiredMagic'];
    const requiredAgility: number = spells[id]['requiredAgility'];
    const cooldown: number = spells[id]['cooldown'];
    const manaConsumption: number = spells[id]['manaConsumption'];
    const requiredWeaponTypes: number[] = spells[id]['requiredWeaponTypes'];

    const handleClick = (event) => {
        // @ts-ignore
        if (window.justLongClicked === true) {
            return;
        }

        let currentSelected: boolean = !isSelected;

        if (onClick !== null) {
            onClick(currentSelected, position, id);
            event.stopPropagation();
            event.preventDefault();
        }
    }

    const longPressBind = useLongPress(() => {
        if (onLongClick !== null) {
            onLongClick(id);
            return;
        }
    }, {
        onFinish: () => {
            setTimeout(() => {
                // @ts-ignore
                window.justLongClicked = false
            }, 5);
        }
    });

    return (
        <div className={`${styles.wrapper} ${isIcon ? styles.isIcon : ''} ${isSelected ? styles.selected : ''} ${isDisabled ? styles.disabled : ''}`}
             onClick={handleClick}
             {...longPressBind}
        >
            <div className={`${styles.iconWrapper} ${isDisabled ? styles.disabled : ''}`}>
                <img className={`${styles.image} ${isDisabled ? styles.disabled : ''}`}
                     src={"assets/ui/icon/" + image + ".png"}
                />
            </div>
            {!isIcon &&
            <div className={styles.description}>
                <div className={styles.title}>{name}</div>
                <div className={styles.italic}>Requirements: <b>{requiredStrength}</b> Strength, <b>{requiredMagic}</b> Magic, <b>{requiredAgility}</b> Agility</div>
                <div className={styles.paragraph}>{description}</div>
                <div className={styles.italic}>Mana usage: {manaConsumption}</div>
                {requiredWeaponTypes.length > 0 &&
                <div className={styles.italic}>Required Weapon: {ItemTypesToTitle(requiredWeaponTypes)}</div>
                }
                {requiredWeaponTypes.length === 0 &&
                <div className={styles.italic}>No Weapon Required</div>
                }
            </div>
            }
            {children}
        </div>
    );
}

export default SkillDescription;