import * as React from "react";
import {useState} from "react";
import {view} from "react-easy-state";
import Window, {WindowProps, WindowStyle} from "./Window";
import ItemContainer from "../component/ItemContainer/ItemContainer";
import ButtonContainer from "../atom/ButtonContainer/ButtonContainer";
import EquipmentContainer from "../component/EquipmentContainer/EquipmentContainer";
import inventoryStore from "../store/InventoryStore";
import Container from "../../di/container";
import equipmentStore from "../store/EquipmentStore";
import EquipmentStatistics from "../component/EquipmentStatistics/EquipmentStatistics";

const EquipmentWindow = ({children, windowState}: WindowProps) => {
    const [selectedPositionOrSlot, setSelectedPositionOrSlot] = useState<number|null>(null);
    const [isEquipment, setIsEquipment] = useState<boolean>(false);
    const [isActive, setIsActive] = useState<boolean>(false);

    const equipmentItems = equipmentStore;
    const inventoryItems = inventoryStore.items;

    return (
        <Window title={'<i class="bi bi-shield-fill"></i> Equipment'} windowState={windowState} windowStyle={WindowStyle.STYLE_NORMAL}>
            <ButtonContainer wrap={false} largeGap={true}>

                <EquipmentStatistics/>

                <EquipmentContainer
                    onSelect={(selectedId, selectedName, item) => {
                        setSelectedPositionOrSlot(selectedId);
                        setIsEquipment(true);
                        setIsActive(selectedName !== null && equipmentItems[selectedName]['itemType'] > 0);

                        if (selectedId !== null) {
                            Container.getNetworkSender().sendDeEquipItem(selectedId);
                        }
                    }}
                />

                <ItemContainer
                    items={inventoryItems}
                    canRender={(item) => {
                        return item['itemType'] !== undefined && item['itemType'] > 0 && item['itemType'] <= 13;
                    }}
                    isSelectable={true}
                    isSelectableMultiple={false}
                    centered={false}
                    onSelect={(selectedPositions, selectedIds) => {
                        setSelectedPositionOrSlot(selectedPositions.length ? selectedPositions[0] : null);
                        setIsEquipment(false);
                        setIsActive(!!(selectedPositions.length && inventoryItems[selectedPositions[0]]['itemType'] > 0));

                        if (selectedPositions.length && inventoryItems[selectedPositions[0]]['itemType'] > 0) {
                            Container.getNetworkSender().sendEquipItem(selectedPositions[0]);
                        }
                    }}
                />
            </ButtonContainer>
        </Window>
    );
};

export default view(EquipmentWindow);