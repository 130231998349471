import {MainScene} from "../scene/mainScene";
import {Creature} from "../model/creature";
import {LightService} from "./lightService";
import {AnimatedMapService} from "./animatedMapService";
import {UniqueId} from "../util/UniqueId";
import {ContainerService} from "./containerService";
import {PlaySound} from "../util/PlaySound";
import {BackgroundSound, MusicSound, SoundEffect, SoundType} from "./soundService";
import {StopSound} from "../util/StopSound";

enum MapLayers {
    GROUND = 0,
    BORDERS = 1,
    OBJECT = 2,
    ADDITIONAL_OBJECTS = 3,
    COLLECTABLES = 4,
    CUSTOM_TILES = 5,
    ABOVE_CREATURES = 6,
    ROOFTOPS = 7
}

export class MapService {
    public canRemoveChunks: boolean = true;

    private mapIdToFile: {} = {
        0: '0.json',
        1: '1.json',
    }
    public currentMapId: number = 0;
    private currentTimestamp: number = 0;

    private maps: {} = {};
    private displayedChunks: [] = [];
    private downloadedChunks: {} = {};
    private filePrefix: number = 0;
    private chunkWidth: number = 0;
    private chunkHeight: number = 0;
    private nbChunksHorizontal: number = 0;
    private nbChunksVertical: number = 0;
    private lastChunkID: number = 0;

    private previousSounds: string[] = [];

    private readonly scene: MainScene;
    private readonly lightService: LightService;
    private readonly animatedMapService: AnimatedMapService;
    private readonly containerService: ContainerService;

    constructor(mainScene: MainScene, lightService: LightService, animatedMapService: AnimatedMapService, containerService: ContainerService) {
        this.scene = mainScene;
        this.lightService = lightService;
        this.animatedMapService = animatedMapService;
        this.containerService = containerService;
    }

    public preload(): void {
        this.scene.load.json('masterMapData0', 'assets/map/chunks/0/master.json?' + UniqueId);
        this.scene.load.json('masterMapData1', 'assets/map/chunks/1/master.json?' + UniqueId);

        this.scene.cache.tilemap.events.on('add',(cache: any, key: any) => {
            // @ts-ignore
            this.downloadedChunks[key.split('k')[1]] = true;
            this.displayChunk(key);
        });

        this.scene.load.spritesheet(
            'new_tilemap', 'assets/map/new_tilemap.png', { frameWidth: 32, frameHeight: 32 }
        );
    }

    public create(): void {
        let masterMapData = this.scene.cache.json.get('masterMapData' + this.currentMapId);

        this.filePrefix = masterMapData.filePrefix;
        this.chunkWidth = masterMapData.chunkWidth;
        this.chunkHeight = masterMapData.chunkHeight;
        this.nbChunksHorizontal = masterMapData.nbChunksHorizontal;
        this.nbChunksVertical = masterMapData.nbChunksVertical;
        this.lastChunkID = (this.nbChunksHorizontal * this.nbChunksVertical) - 1;

        let worldWidth = masterMapData.nbChunksHorizontal * masterMapData.chunkWidth;
        let worldHeight = masterMapData.nbChunksVertical * masterMapData.chunkHeight;
        this.scene.cameras.main.setBounds(0, 0, worldWidth*32, worldHeight*32);
    }

    public computeChunkID(x: number, y: number): number {
        let tileX = Math.floor(x/32);
        let tileY = Math.floor(y/32);
        let chunkX = Math.floor(tileX/this.chunkWidth);
        let chunkY = Math.floor(tileY/this.chunkHeight);

        return (chunkY * this.nbChunksHorizontal) + chunkX;
    };

    public getXYofPlayer(player: Creature): [number, number] {
        return [
            Math.floor(player.getPositionX()),
            Math.floor(player.getPositionY())
        ];
    }

    public hideOrShowRooftops(player: Creature): void {
        const [x, y] = this.getXYofPlayer(player);
        const chunkId = this.computeChunkID(x*32, y*32);

        const map: Phaser.Tilemaps.Tilemap = this.maps[chunkId];

        if (map === undefined) {
            return;
        }

        const tiles = map.getTilesWithinWorldXY(x*32 - 64, y*32 - 96, 160, 192, {isNotEmpty: true}, undefined, 'rooftops');
        let showRooftops = true;
        if (tiles !== null && tiles.length > 0) {
            showRooftops = false;
        }

        Object.keys(this.maps).forEach((chunkId) => {
            const layer = this.maps[chunkId].getLayer('rooftops');

            if (layer !== null) {
                layer.tilemapLayer.setVisible(showRooftops);
            }
        });

        this.lightService.setUnderRooftop(!showRooftops);
    }

    public findDiffArrayElements(firstArray: number[], secondArray: number[]): number[] {
        return firstArray.filter((i) => {
            return secondArray.indexOf(i) < 0;
        });
    };

    public updateEnvironment(mapId: number|null = null): void {
        if (mapId !== null && this.currentMapId !== mapId) {
            Object.keys(this.maps).forEach((chunkId) => {
                this.removeChunk(Number(chunkId));
            });

            this.maps = {};

            this.displayedChunks = [];
            this.downloadedChunks = {};

            this.currentTimestamp = Date.now();
            this.currentMapId = mapId;
            this.create();
        }

        let player = this.scene.getMyPlayer();
        if (player === undefined) {
            return;
        }

        let chunkID = this.computeChunkID(player.getPixelsX(), player.getPixelsY());
        let chunks = this.listAdjacentChunks(chunkID);
        let newChunks = this.findDiffArrayElements(chunks, this.displayedChunks);
        let oldChunks = this.findDiffArrayElements(this.displayedChunks, chunks);

        newChunks.forEach((c) => {
            this.scene.load.tilemapTiledJSON(this.currentTimestamp + 'chunk' + c, 'assets/map/chunks/' + this.currentMapId + '/chunk-' + this.filePrefix + '-' + c + '.json');
            this.displayChunk(this.currentTimestamp + 'chunk' + c);
        });

        if (newChunks.length > 0) {
            this.scene.load.start();
        }

        oldChunks.forEach((c) => {
            this.removeChunk(c);
        });

        this.playSoundsOnTile(<Creature>this.scene.getMyPlayer());

        if (this.currentMapId === 0) {
            this.hideOrShowRooftops(player);
        }
    };

    public displayChunk(key: string): void {
        // @ts-ignore
        if (this.downloadedChunks[key.split('k')[1]] === undefined) {
            return;
        }

        let map = this.scene.make.tilemap({ key: key});

        let tiles = map.addTilesetImage('Default', 'new_tilemap');

        let chunkId = parseInt(key.split('k')[1]);
        let chunkX = (chunkId % this.nbChunksHorizontal) * this.chunkWidth;
        let chunkY = Math.floor(chunkId/this.nbChunksHorizontal) * this.chunkHeight;

        for (let layer = MapLayers.GROUND; layer <= MapLayers.ROOFTOPS; layer++) {
            let lastLayer = map.createLayer(layer, tiles, chunkX*32, chunkY*32);

            if (layer >= MapLayers.ABOVE_CREATURES) {
                lastLayer.setDepth(10000 + layer);
                if (layer === MapLayers.ABOVE_CREATURES) {
                    lastLayer.setAlpha(0.9);
                }
            }
        }

        //LIGHTS
        map.objects[1].objects.forEach((light) => {
            let tint = 0xff7200;
            let radius = 1.0;

            if (light.properties !== undefined) {
                light.properties.forEach((property) => {
                    switch (property.name) {
                        case 'Radius':
                            radius = property.value;
                            break;
                        case 'Tint':
                            tint = Number('0x' + property.value.slice(3));
                            break;
                    }

                });
            }
            // @ts-ignore
            this.lightService.addLight(chunkId, Math.floor(light.x/32), Math.floor(light.y/32), radius, tint);
        });
        //END LIGHTS

        // @ts-ignore
        map.sounds = this.scene.cache.tilemap.entries.entries[key].data.sounds;

        // @ts-ignore
        this.maps[chunkId] = map;
        // @ts-ignore
        this.displayedChunks.push(chunkId);

        this.animatedMapService.initChunk(map, chunkId);

        this.playSoundsOnTile(<Creature>this.scene.getMyPlayer());

        if (this.currentMapId === 0) {
            this.hideOrShowRooftops(<Creature>this.scene.getMyPlayer());
        }
    };

    public removeChunk(chunkId: number): void {
        if (!this.canRemoveChunks) {
            return;
        }

        const layers = this.maps[chunkId].layers;
        const length = layers.length;

        for (let i = 0; i < length; i++)
        {
            if (layers[i].tilemapLayer)
            {
                layers[i].tilemapLayer.destroy(false);
            }
        }

        layers.length = 0;

        this.maps[chunkId].currentLayerIndex = 0;

        this.maps[chunkId].destroy();
        delete this.maps[chunkId];

        // @ts-ignore
        let idx = this.displayedChunks.indexOf(chunkId);
        if (idx > -1) {
            this.displayedChunks.splice(idx,1);
        }

        this.lightService.clearChunk(chunkId);
        this.animatedMapService.clearChunk(chunkId);
        this.containerService.clearChunk(chunkId);
    };

    public listAdjacentChunks(chunkID: number): number[] {
        let chunks: number[] = [];
        let isAtTop = (chunkID < this.nbChunksHorizontal);
        let isAtBottom = (chunkID > this.lastChunkID - this.nbChunksHorizontal);
        let isAtLeft = (chunkID % this.nbChunksHorizontal === 0);
        let isAtRight = (chunkID % this.nbChunksHorizontal === this.nbChunksHorizontal - 1);

        chunks.push(chunkID);

        if (!isAtTop) chunks.push(chunkID - this.nbChunksHorizontal);
        if (!isAtBottom) chunks.push(chunkID + this.nbChunksHorizontal);
        if (!isAtLeft) chunks.push(chunkID-1);
        if (!isAtRight) chunks.push(chunkID+1);
        if (!isAtTop && !isAtLeft) chunks.push(chunkID - 1 - this.nbChunksHorizontal);
        if (!isAtTop && !isAtRight) chunks.push(chunkID + 1 - this.nbChunksHorizontal);
        if (!isAtBottom && !isAtLeft) chunks.push(chunkID - 1 + this.nbChunksHorizontal);
        if (!isAtBottom && !isAtRight) chunks.push(chunkID + 1 + this.nbChunksHorizontal);

        return chunks;
    };

    public addCustomTile(x: number, y: number, tileId: number): void {
        let chunkId = this.computeChunkID(x*32, y*32);

        const map: Phaser.Tilemaps.Tilemap = this.maps[chunkId];

        if (map === undefined) {
            return;
        }

        let customTilesLayer = map.getLayer('custom_tiles');
        if (!customTilesLayer) {
            return;
        }

        const tile = customTilesLayer.tilemapLayer.putTileAtWorldXY(tileId, x*32, y*32);
        this.animatedMapService.initTile(tile, chunkId);
    }

    public removeCustomTile(x: number, y: number): void {
        let chunkId = this.computeChunkID(x*32, y*32);

        const map: Phaser.Tilemaps.Tilemap = this.maps[chunkId];

        if (map === undefined) {
            return;
        }

        let customTilesLayer = map.getLayer('custom_tiles');
        if (!customTilesLayer) {
            return;
        }

        const tileId = customTilesLayer.tilemapLayer.getTileAtWorldXY(x*32, y*32).index;
        customTilesLayer.tilemapLayer.removeTileAtWorldXY(x*32, y*32);

        const sprite = this.scene.add.sprite(x * 32, y * 32, 'new_tilemap', tileId - 1).setOrigin(0, 0).setDepth(7);
        this.scene.tweens.add({
            targets: sprite,
            duration: 250,
            alpha: 0.0,
            onComplete: () => {
                sprite.destroy();
            }
        });
    }

    public addCollectable(x: number, y: number, tileId: number): void {
        let chunkId = this.computeChunkID(x*32, y*32);

        const map: Phaser.Tilemaps.Tilemap = this.maps[chunkId];

        if (map === undefined) {
            return;
        }

        let collectablesLayer = map.getLayer('collectables');
        if (!collectablesLayer) {
            return;
        }

        collectablesLayer.tilemapLayer.putTileAtWorldXY(tileId, x*32, y*32);

        // this.animatedMapService.initChunk(map, chunkId);
    }

    public removeCollectable(x: number, y: number): void {
        let chunkId = this.computeChunkID(x*32, y*32);

        const map: Phaser.Tilemaps.Tilemap = this.maps[chunkId];

        if (map === undefined) {
            return;
        }

        let collectablesLayer = map.getLayer('collectables');
        if (!collectablesLayer) {
            return;
        }

        const tileId = collectablesLayer.tilemapLayer.getTileAtWorldXY(x*32, y*32).index;
        collectablesLayer.tilemapLayer.removeTileAtWorldXY(x*32, y*32);

        const sprite = this.scene.add.sprite(x * 32, y * 32, 'new_tilemap', tileId - 1).setOrigin(0, 0).setDepth(7);
        this.scene.tweens.add({
            targets: sprite,
            duration: 250,
            alpha: 0.0,
            onComplete: () => {
                sprite.destroy();
            }
        });
    }

    public addContainer(ownerId: number|null, x: number, y: number, id: number): void {
        let chunkId = this.computeChunkID(x*32, y*32);

        this.containerService.addContainer(ownerId, x, y, id, chunkId);
    }

    public removeContainerOwner(x: number, y: number, id: number): void {
        this.containerService.removeContainerOwner(x, y, id);
    }

    public removeContainer(x: number, y: number, id: number): void {
        this.containerService.removeContainer(x, y, id);
    }

    public playSoundsOnTile(player: Creature): void {
        const [x, y] = this.getXYofPlayer(player);
        const chunkId = this.computeChunkID(x*32, y*32);

        const map: Phaser.Tilemaps.Tilemap = this.maps[chunkId];

        if (map === undefined) {
            return;
        }

        // @ts-ignore
        const sounds = map.sounds?.[x]?.[y] ?? [];

        let startSounds = sounds.filter(x => !this.previousSounds.includes(x));
        let stopSounds = this.previousSounds.filter(x => !sounds.includes(x));

        startSounds.forEach((sound: string) => {
            PlaySound(MusicSound[sound] ?? BackgroundSound[sound], true);
        });

        stopSounds.forEach((sound: string) => {
            StopSound(MusicSound[sound] ?? BackgroundSound[sound]);
        });

        this.previousSounds = sounds;

    }
}