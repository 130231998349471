import React, {useEffect, useState} from 'react';
import { store, view } from 'react-easy-state';
import shortcutsStore from "../../store/ShortcutsStore";
import styles from './ShortcutsManager.module.scss'
import ShortcutContainer from "../../component/ShortcutContainer/ShortcutContainer";
import AutoAimShortcut from "../../atom/AutoAimShortcut/AutoAimShortcut";

const ShortcutsManager = () => {
    return (
        <div className={styles.shortcutsWrapper} data-area={'shortcut-container'}>
            {Object.keys(shortcutsStore.shortcuts).map((slot, index) => {
                return <ShortcutContainer key={index} slot={Number(slot)} shortcut={shortcutsStore.shortcuts[slot]}/>
            })}
            <AutoAimShortcut key={7} slot={7}/>
        </div>
    );
};

export default view(ShortcutsManager);