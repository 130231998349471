import * as React from "react";
import {view} from "react-easy-state";
import styles from './TutorialScreen.module.scss';
import {TutorialState, tutorialStore} from "../../store/TutorialStore";
import InfoAction from "../../component/InfoActionContainer/InfoAction";
import {DomElementPositionAndSize, DomElementPositionAndSizeByAttribute} from "../../../util/DomElementPositionAndSize";
import FocusOverlay from "./FocusOverlay/FocusOverlay";
import TypedP from "../../atom/TypedP/TypedP";
import npcStore from "../../store/NpcStore";
import Container from "../../../di/container";
import {WindowCloser} from "../../service/WindowCloser";

const TutorialScreen = () => {
    const tellerName = 'Mysterious Deity';

    return (
        <div className={styles.container}>
            {tutorialStore.state === TutorialState.STATE_SHORTCUTS_INFO &&
                <>
                    <FocusOverlay/>
                    <InfoAction title={tellerName} image={'goddess'} hideNext={true} clickToNext={true} callback={() => {
                        tutorialStore.state = TutorialState.STATE_SHORTCUTS_CLICK;
                    }} stepCallback={() => {
                        if (!npcStore.forceDisplay) {
                            npcStore.forceDisplay = true;
                            return false;
                        }

                        npcStore.forceDisplay = false;
                        return true;
                    }}>
                        <TypedP key={11} text={'I\'m glad that you\'ve made it back to the world of the living.'} forceDisplay={npcStore.forceDisplay} onComplete={() => {npcStore.forceDisplay = true;}}/>
                        <TypedP key={12} text={'It looks like you\'re heavily wounded though...'} forceDisplay={npcStore.forceDisplay} onComplete={() => {npcStore.forceDisplay = true;}}/>
                        <TypedP key={13} text={'I gave you a healing potion, it is in your inventory.'} forceDisplay={npcStore.forceDisplay} onComplete={() => {npcStore.forceDisplay = true;}}/>
                        <TypedP key={14} text={'Tap on the bottom-right icon on your screen to use shortcut and heal yourself.'} forceDisplay={npcStore.forceDisplay} onComplete={() => {npcStore.forceDisplay = true;}}/>
                    </InfoAction>
                </>
            }
            {tutorialStore.state === TutorialState.STATE_SHORTCUTS_CLICK &&
                <FocusOverlay
                    left={DomElementPositionAndSizeByAttribute('data-item-id', '46')?.x + 'px'}
                    top={DomElementPositionAndSizeByAttribute('data-item-id', '46')?.y + 'px'}
                    width={DomElementPositionAndSizeByAttribute('data-item-id', '46')?.width + 'px'}
                    height={DomElementPositionAndSizeByAttribute('data-item-id', '46')?.height + 'px'}
                    callback={() => {
                        tutorialStore.state = TutorialState.STATE_COLLECTABLES_INFO;
                    }}
                />
            }
            {tutorialStore.state === TutorialState.STATE_COLLECTABLES_INFO &&
                <>
                    <FocusOverlay/>
                    <InfoAction title={tellerName} image={'goddess'} hideNext={true} clickToNext={true} callback={() => {
                        tutorialStore.state = TutorialState.STATE_COLLECTABLES_CLICK;
                    }} stepCallback={() => {
                        if (!npcStore.forceDisplay) {
                            npcStore.forceDisplay = true;
                            return false;
                        }

                        npcStore.forceDisplay = false;
                        return true;
                    }}>
                        <TypedP key={21} text={'Perfect! You\'re healthy again.'} forceDisplay={npcStore.forceDisplay} onComplete={() => {npcStore.forceDisplay = true;}}/>
                        <TypedP key={22} text={'Next thing is to collect item that is lying around you.'} forceDisplay={npcStore.forceDisplay} onComplete={() => {npcStore.forceDisplay = true;}}/>
                        <TypedP key={23} text={'In order to do that, simply tap it.'} forceDisplay={npcStore.forceDisplay} onComplete={() => {npcStore.forceDisplay = true;}}/>
                    </InfoAction>
                </>
            }
            {tutorialStore.state === TutorialState.STATE_COLLECTABLES_CLICK &&
            <FocusOverlay
                left={'calc(50% - 100px)'}
                top={'calc(50% - 100px)'}
                width={'200px'}
                height={'200px'}
                callback={() => {
                    tutorialStore.state = TutorialState.STATE_COLLECTABLES_INFO;
                }}
            />
            }
            {tutorialStore.state === TutorialState.STATE_INVENTORY_INFO &&
                <>
                    <FocusOverlay/>
                    <InfoAction  title={tellerName} image={'goddess'} hideNext={true} clickToNext={true} callback={() => {
                        tutorialStore.state = TutorialState.STATE_HIDDEN;
                        Container.getCutsceneService().play('innRouteScene');
                    }} stepCallback={() => {
                        if (!npcStore.forceDisplay) {
                            npcStore.forceDisplay = true;
                            return false;
                        }

                        npcStore.forceDisplay = false;
                        return true;
                    }}>
                        <TypedP key={31} text={'Perfect! Item was picked into your Inventory. You can browse it later, by going to Menu and then to Inventory.'} forceDisplay={npcStore.forceDisplay} onComplete={() => {npcStore.forceDisplay = true;}}/>
                        <TypedP key={32} text={'That\'s the end of guide for now. Feel free to roam in the village - you might be interested in what\'ll Inn Keeper - Casimir say.'} forceDisplay={npcStore.forceDisplay} onComplete={() => {npcStore.forceDisplay = true;}}/>
                        <TypedP key={33} text={'He is located to the north from your current location. I\'ll show you how to get there.'} forceDisplay={npcStore.forceDisplay} onComplete={() => {npcStore.forceDisplay = true;}}/>
                    </InfoAction>
                </>
            }
            {/*Second tutorial (fighting)*/}
            {tutorialStore.state === TutorialState.STATE_SETTING_SHORTCUT_INFO &&
            <>
                <FocusOverlay/>
                <InfoAction title={tellerName} image={'goddess'} hideNext={true} clickToNext={true} callback={() => {
                    WindowCloser('statistics');
                    tutorialStore.state = TutorialState.STATE_SETTING_SHORTCUT_CLICK;
                }} stepCallback={() => {
                    if (!npcStore.forceDisplay) {
                        npcStore.forceDisplay = true;
                        return false;
                    }

                    npcStore.forceDisplay = false;
                    return true;
                }}>
                    <TypedP key={41} text={'I\'m glad that you have made it so far! Now I will teach you how to use special skills.'} forceDisplay={npcStore.forceDisplay} onComplete={() => {npcStore.forceDisplay = true;}}/>
                    <TypedP key={42} text={'I will point Shortcuts - you need to hold your finger or mouse on one to select your desired skill.'} forceDisplay={npcStore.forceDisplay} onComplete={() => {npcStore.forceDisplay = true;}}/>
                </InfoAction>
            </>
            }
            {tutorialStore.state === TutorialState.STATE_SETTING_SHORTCUT_CLICK &&
            <FocusOverlay
                left={DomElementPositionAndSizeByAttribute('data-area', 'shortcut-container')?.x + 'px'}
                top={DomElementPositionAndSizeByAttribute('data-area', 'shortcut-container')?.y + 'px'}
                width={DomElementPositionAndSizeByAttribute('data-area', 'shortcut-container')?.width + 'px'}
                height={DomElementPositionAndSizeByAttribute('data-area', 'shortcut-container')?.height + 'px'}
                callback={() => {
                tutorialStore.state = TutorialState.STATE_SETTING_SHORTCUT_SELECT_SKILL_INFO;
            }}
            />
            }
            {tutorialStore.state === TutorialState.STATE_SETTING_SHORTCUT_SELECT_SKILL_INFO &&
            <>
                <FocusOverlay/>
                <InfoAction title={tellerName} image={'goddess'} hideNext={true} clickToNext={true} callback={() => {
                    tutorialStore.state = TutorialState.STATE_SETTING_SHORTCUT_SELECT_SKILL_CLICK;
                }} stepCallback={() => {
                    if (!npcStore.forceDisplay) {
                        npcStore.forceDisplay = true;
                        return false;
                    }

                    npcStore.forceDisplay = false;
                    return true;
                }}>
                    <TypedP key={51} text={'Here is a list of available Skills and Inventory contents that can have Shortcut.'} forceDisplay={npcStore.forceDisplay} onComplete={() => {npcStore.forceDisplay = true;}}/>
                    <TypedP key={52} text={'Click on one of them to use it during your next fight.'} forceDisplay={npcStore.forceDisplay} onComplete={() => {npcStore.forceDisplay = true;}}/>
                </InfoAction>
            </>
            }
            {tutorialStore.state === TutorialState.STATE_SETTING_SHORTCUT_SELECT_SKILL_CLICK &&
            <FocusOverlay
                left={DomElementPositionAndSizeByAttribute('data-area', 'skill-container')?.x + 'px'}
                top={DomElementPositionAndSizeByAttribute('data-area', 'skill-container')?.y + 'px'}
                width={DomElementPositionAndSizeByAttribute('data-area', 'skill-container')?.width + 'px'}
                height={DomElementPositionAndSizeByAttribute('data-area', 'skill-container')?.height + 'px'}
                callback={() => {
                    tutorialStore.state = TutorialState.STATE_HIDDEN;
                }}
            />
            }
        </div>
    );
};

export default view(TutorialScreen);