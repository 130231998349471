import * as React from "react";
import {useRef, useState} from "react";
import {view} from "react-easy-state";
import Window, {WindowProps, WindowStyle} from "./Window";
import ItemContainer from "../component/ItemContainer/ItemContainer";
import {ButtonType} from "../atom/Button/Button";
import TextContainer from "../atom/TextContainer/TextContainer";
import inventoryStore from "../store/InventoryStore";
import Container from "../../di/container";
import {WindowCreator} from "../service/WindowCreator";
import {ActionsPopupCreator} from "../service/ActionsPopupCreator";
import {ActionsPopupDetailSingular} from "../manager/ActionsPopup/ActionsPopupDetail";
import RangeSliderUncontrolled from "../atom/RangeSlider/RangeSliderUncontrolled";
import {PlaySound} from "../../util/PlaySound";
import {SoundEffect} from "../../service/soundService";

const InventoryWindow = ({children, windowState}: WindowProps) => {
    let selectedPositionsLocal: number[] = [];
    let selectedIdsLocal: number[] = [];
    const [usable, setUsable] = useState(false);
    let items = inventoryStore.items;

    const elementRef = useRef<HTMLInputElement>(null);

    return (
        <Window title={'<i class="bi bi-bag-fill"></i> Inventory'} windowState={windowState} windowStyle={WindowStyle.STYLE_NORMAL}>
            <TextContainer text={' You have ' + inventoryStore.gold + ' Gold Coins.'} centered={true} overflowHidden={true}/>
            <ItemContainer
                items={inventoryStore.items}
                isSelectable={true}
                isSelectableMultiple={false}
                noSelectOnlyClick={true}
                onSelect={(selectedPositions, selectedIds) => {
                    PlaySound(SoundEffect.CLICK);

                    selectedPositionsLocal = selectedPositions;
                    selectedIdsLocal = selectedIds;

                    let details: ActionsPopupDetailSingular[] = [];

                    if (items[selectedPositions[0]]['usable'] === true) {
                        setUsable(true);
                        details.push({
                            type: ButtonType.TYPE_ACCEPT,
                            text: 'Use',
                            callback: () => {
                                Container.getNetworkSender().sendUseInventoryItem(selectedPositionsLocal[0]);
                            },
                        });
                    } else {
                        setUsable(false);
                    }

                    details.push({
                        type: ButtonType.TYPE_NO,
                        text: 'Destroy',
                        callback: () => {
                            WindowCreator(
                                'yes-no',
                                {
                                    title: 'Are you sure?',
                                    text: 'Slide to select quantity to be destroyed.',
                                    yesCallback: () => {
                                        // @ts-ignore
                                        Container.getNetworkSender().sendDestroyInventoryItem(selectedPositionsLocal[0], elementRef.current.value);
                                        PlaySound(SoundEffect.DESTROY);
                                    },
                                    noCallback: () => {},
                                    rangeSlider:
                                        <RangeSliderUncontrolled
                                            name={'Quantity'}
                                            min={1}
                                            max={items[selectedPositions[0]]['quantity']}
                                            defaultValue={items[selectedPositions[0]]['quantity']}
                                            ref={elementRef}
                                        />
                                }
                            );
                        },
                    });

                    ActionsPopupCreator(details);
                }}
                capacity={20}
            />
        </Window>
    );
};

export default view(InventoryWindow);