import {store} from "react-easy-state";

type StatisticsStoreType = {
    name: string,
    level: number,
    archetype: string,
    mana: number,
    maxMana: number,
    health: number,
    maxHealth: number,
    experience: number,
    maxExperience: number,
    capacity: number,
    maxCapacity: number,
    attack: number,
    maxDamage: number,
    defence: number,
    damageReduction: number,
    blockPercent: number,
    strength: number,
    bonusStrength: number,
    magicPower: number,
    bonusMagicPower: number,
    agility: number,
    bonusAgility: number,
    availableStatisticsPoints: number,
}

const statisticsStore: StatisticsStoreType = store({
    name: '',
    level: 0,
    archetype: '',
    mana: 0,
    maxMana: 0,
    health: 0,
    maxHealth: 0,
    experience: 0,
    maxExperience: 0,
    capacity: 0,
    maxCapacity: 0,
    attack: 0,
    maxDamage: 0,
    defence: 0,
    damageReduction: 0,
    blockPercent: 0,
    strength: 0,
    bonusStrength: 0,
    magicPower: 0,
    bonusMagicPower: 0,
    agility: 0,
    bonusAgility: 0,
    availableStatisticsPoints: 0,
});

export default statisticsStore;