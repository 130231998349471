import * as React from "react";
import {view} from "react-easy-state";

export type TabProps = {
    label: string,
    isActive: boolean,
    children: React.ReactNode,
    color?: string,
    icon?: object,
};

const Tab = (props: TabProps) => {
    return (
        <>
            {
                // @ts-ignore
                props.children.map((child: React.ReactNode, index: number) => {
                    return {child};
                })
            }
        </>
    );
}

export default view(Tab);