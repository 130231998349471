import * as React from "react";
import {view} from "react-easy-state";
import Button, {ButtonSize, ButtonType} from "../../atom/Button/Button";
import ButtonContainer from "../../atom/ButtonContainer/ButtonContainer";
import PasswordInput from "../../atom/Input/PasswordInput";
import EmailInput from "../../atom/Input/EmailInput";
import styles from './LoginScreen.module.scss';
import {LoginState, loginStore} from "../../store/LoginStore";
import Container from "../../../di/container";
import {TutorialState, tutorialStore} from "../../store/TutorialStore";
import TextInput from "../../atom/Input/TextInput";
import {WindowCreator} from "../../service/WindowCreator";
import {ValidateRegister} from "../../../util/ValidateRegister";
import HorizontalGroup from "../../atom/Input/HorizontalGroup";
import Checkbox from "../../atom/Checkbox/Checkbox";
import HudMenuButton from "../../atom/HudMenuButton/HudMenuButton";

const LoginScreen = () => {
    const handleGuest = () => {
        loginStore.state = LoginState.STATE_HIDDEN;
        if (tutorialStore.enabled) {
            tutorialStore.state = TutorialState.STATE_SHORTCUTS_INFO;
        }

        Container.getNetworkSender().sendPlayAsGuest();
    }

    const handleLogin = (event) => {
        event.preventDefault();

        Container.getNetworkSender().sendLoginAccount(
            loginStore.email,
            loginStore.password
        );
    };

    const handleRegister = (event) => {
        event.preventDefault();

        if (!ValidateRegister(loginStore)) {
            return;
        }

        Container.getNetworkSender().sendRegisterAccount(
            loginStore.name,
            loginStore.email,
            loginStore.password,
            loginStore.terms,
            loginStore.newsletter,
            loginStore.promo
        );

    };

    const handleLostPasswordInitialize = (event) => {
        event.preventDefault();

        Container.getNetworkSender().sendPasswordResetInitialize(loginStore.email);
    }

    const handleLostPasswordConfirmation = (event) => {
        event.preventDefault();

        if (loginStore.passwordRepeat !== loginStore.password) {
            WindowCreator('notification', {
                title: 'Passwords does not match',
                text: 'Password and password confirmation are different. Corect them and try again.',
                center: true,
            });

            return;
        }

        Container.getNetworkSender().sendPasswordResetConfirmation(
            loginStore.email,
            loginStore.confirmationCode,
            loginStore.password
        );
    }

    const handleHeroSelection = (hero: string) => {
        Container.getNetworkSender().sendLoginPlayer(hero);
    };

    const handleLoginAnotherAccount = () => {
        Container.getNetworkSender().sendLoginAnotherAccount();
    };

    const updateStore = (name: string, value: string|number|boolean) => {
        if (name === 'promo') {
            loginStore.isPromoValid = true;
        }

        loginStore[name] = value;
    }

    return (
        <div className={styles.container}>
            <span className={styles.logo}/>
            <div style={{position: "fixed", top: '5px', right: '5px'}}>
                <HudMenuButton icon={'<i class="bi bi-arrows-fullscreen"></i>'} onClick={() => {
                    if (document.fullscreenElement) {
                        document.exitFullscreen();
                        return;
                    }

                    document.body.requestFullscreen();
                }}/>
            </div>
            <div className={styles.displayArea}>
                {loginStore.state === LoginState.STATE_LOGIN &&
                <>
                    <div className={styles.loginBox}>
                        <h1 className={styles.title}>Start new journey</h1>
                        <div>
                            <ButtonContainer>
                                <Button text={'Play'} htmlType={'submit'} type={ButtonType.TYPE_YES} size={ButtonSize.TYPE_EXTRA_LARGE} isActive={loginStore.serverOnline} click={() => {
                                    handleGuest();
                                }}/>
                            </ButtonContainer>
                            <p className={styles.small}>Try Herodonia without registration.</p>
                            <hr/>
                            <p onClick={() => {
                                loginStore.state = LoginState.STATE_REGISTRATION;
                            }}>
                                <span>Create Account</span> to save your progress.
                            </p>
                        </div>
                    </div>
                    <div className={styles.loginBox}>
                        <h1 className={styles.title}>Have an account?</h1>
                        <div>
                            <form onSubmit={handleLogin}>
                                <EmailInput name={'email'} value={loginStore.email} onUpdate={updateStore} label={'Email'}
                                            placeholder={'hero@gmail.com'} minLength={3} maxLength={50} required={true}/>
                                <PasswordInput name={'password'} value={loginStore.password} onUpdate={updateStore}
                                               label={'Password'} placeholder={'Password'} minLength={8} maxLength={50}
                                               required={true}/>

                                <ButtonContainer>
                                    <Button text={'Login'} htmlType={'submit'} type={ButtonType.TYPE_ACCEPT} size={ButtonSize.TYPE_LARGE} isActive={loginStore.serverOnline} click={() => {
                                    }}/>
                                </ButtonContainer>

                                <p onClick={() => {
                                    loginStore.state = LoginState.STATE_LOST_PASSWORD_INITIALIZATION;
                                }}>
                                    Having troubles logging in? Reset password <span>here</span>
                                </p>
                                <hr/>
                                <p onClick={() => {
                                    loginStore.state = LoginState.STATE_REGISTRATION;
                                }}>
                                    Don't have an Account? <span>Create Account</span> now.
                                </p>
                            </form>
                        </div>
                    </div>
                </>
                }
                {loginStore.state === LoginState.STATE_LOST_PASSWORD_INITIALIZATION &&
                <div className={styles.loginBox}>
                    <div>
                        <h1 className={styles.title}>Reset Password</h1>
                        <form onSubmit={handleLostPasswordInitialize}>
                            <EmailInput name={'email'} value={loginStore.email} onUpdate={updateStore} label={'Email'}
                                        placeholder={'hero@gmail.com'} minLength={3} maxLength={50} required={true}/>

                            <p>Email with Confirmation Code will be sent to this email address.</p>

                            <p onClick={() => {
                                loginStore.state = LoginState.STATE_LOST_PASSWORD_CONFIRMATION;
                            }}>
                                I already have <span>Confirmation Code</span>
                            </p>

                            <p onClick={() => {
                                loginStore.state = LoginState.STATE_LOGIN;
                            }}>
                                I want to <span>login</span> instead
                            </p>

                            <ButtonContainer>
                                <Button text={'Proceed'} htmlType={'submit'} type={ButtonType.TYPE_ACCEPT} isActive={loginStore.serverOnline} click={() => {
                                }}/>
                            </ButtonContainer>
                        </form>
                    </div>
                </div>
                }
                {loginStore.state === LoginState.STATE_LOST_PASSWORD_CONFIRMATION &&
                <div className={styles.loginBox}>
                    <div>
                        <h1 className={styles.title}>Set New Password</h1>
                        <form onSubmit={handleLostPasswordConfirmation}>
                            <div className={styles.horizontalOnMobile}>
                                <div>
                                    <EmailInput name={'email'} value={loginStore.email} onUpdate={updateStore} label={'Email'}
                                                placeholder={'hero@gmail.com'} minLength={3} maxLength={50} required={true}/>

                                    <TextInput name={'confirmationCode'} value={loginStore.confirmationCode} onUpdate={updateStore} label={'Confirmation Code'}
                                               minLength={10} maxLength={10} required={true}/>
                                </div>
                                <div>
                                    <PasswordInput name={'password'} value={loginStore.password} onUpdate={updateStore}
                                                   label={'Password'} placeholder={'Password'} minLength={8} maxLength={50}
                                                   required={true}/>
                                    <PasswordInput name={'passwordRepeat'} value={loginStore.passwordRepeat} onUpdate={updateStore}
                                                   label={'Repeat Password'} placeholder={'Password'} minLength={8} maxLength={50}
                                                   required={true}/>
                                </div>
                            </div>

                            <p>If you're registered, you should receive email with Confirmation Code in 5 minutes. Check Spam folder also!</p>

                            <p onClick={() => {
                                loginStore.state = LoginState.STATE_LOGIN;
                            }}>
                                I want to <span>login</span> instead
                            </p>

                            <ButtonContainer>
                                <Button text={'Confirm'} htmlType={'submit'} type={ButtonType.TYPE_ACCEPT} isActive={loginStore.serverOnline} click={() => {
                                }}/>
                            </ButtonContainer>
                        </form>
                    </div>
                </div>
                }
                {loginStore.state === LoginState.STATE_HERO_SELECTION &&
                <div className={styles.loginBox}>
                    <h1 className={styles.title}>Hero Selection</h1>
                    <div className={styles.heroWrapper}>
                        {loginStore.players.map((player, index) => {
                            const accentClassName: string = styles[player.archetype];
                            return <div className={`${styles.hero} ${accentClassName}`} key={index} onClick={() => {
                                handleHeroSelection(player.name);
                            }}>
                                <h2>{player.name}</h2>
                                <h3>Level: {player.level}</h3>
                                <h3>Archetype: {player.archetype}</h3>
                            </div>
                        })}
                    </div>
                    <p onClick={() => {
                        handleLoginAnotherAccount();
                        loginStore.state = LoginState.STATE_LOGIN;
                        loginStore.clear();
                    }}>
                        I want to <span>login to another account</span> instead
                    </p>
                </div>
                }
                {loginStore.state === LoginState.STATE_REGISTRATION &&
                <div className={styles.loginBox}>
                    <div>
                        <h1 className={styles.title}>Create Account</h1>
                        <form onSubmit={handleRegister}>
                            <TextInput name={'name'} value={loginStore.name} error={loginStore.invalidFieldsToMessage['name']} onUpdate={updateStore} label={'Hero Name'} placeholder={'Hero'} minLength={3} maxLength={20} required={true}/>

                            <EmailInput name={'email'} value={loginStore.email} error={loginStore.invalidFieldsToMessage['email']} onUpdate={updateStore} label={'Email'} placeholder={'hero@gmail.com'} minLength={3} maxLength={100} required={true}/>

                            <HorizontalGroup>
                                <PasswordInput name={'password'} value={loginStore.password} error={loginStore.invalidFieldsToMessage['password']} onUpdate={updateStore} label={'Password'} placeholder={'Password'} minLength={8} maxLength={50} required={true}/>
                                <PasswordInput name={'passwordRepeat'} value={loginStore.passwordRepeat} error={loginStore.invalidFieldsToMessage['repeatPassword']} onUpdate={updateStore} label={'Repeat Password'} placeholder={'Password'} minLength={8} maxLength={50} required={true}/>
                            </HorizontalGroup>

                            <Checkbox label={'I agree to receive latest Herodonia news. Promise - no spam'} required={false} checked={false} name={'newsletter'} onUpdate={updateStore}/>
                            <Checkbox label={'I accept the Herodonia <a href="https://www.herodonia.com/terms-and-conditions/" target="_blank">Terms & Conditions</a> and <a href="https://www.herodonia.com/privacy-policy/" target="_blank">Privacy Policy</a>'} required={true} checked={false} name={'terms'} onUpdate={updateStore}/>

                            <TextInput name={'promo'} value={loginStore.promo} error={loginStore.isPromoValid ? undefined : 'Promo Code is not valid.'} onUpdate={updateStore} label={'Have a Promo Code? Enter it here to earn additional rewards.'} placeholder={'Your Promo Code'} minLength={3} maxLength={20}/>

                            <ButtonContainer>
                                <Button text={'Create Account'} htmlType={'submit'} type={ButtonType.TYPE_ACCEPT} isActive={loginStore.serverOnline} click={() => {
                                }}/>
                            </ButtonContainer>

                            <p onClick={() => {
                                loginStore.state = LoginState.STATE_LOGIN;
                            }}>
                                I want to <span>login</span> instead
                            </p>
                        </form>
                    </div>
                </div>
                }
            </div>
            <div className={styles.socialsWrapper}>
                <div className={styles.socials}>
                    <a href="https://discord.gg/A4r3rXEEZW" target="_blank"><i className="bi bi-discord"></i></a>
                    <a href="https://twitter.com/herodonia" target="_blank"><i className="bi bi-twitter"></i></a>
                    <a href="https://www.youtube.com/channel/UCGNsv9vmBJM0Z379QSoa4NA" target="_blank"><i className="bi bi-youtube"></i></a>
                    <a href="https://www.facebook.com/herodonia.game/" target="_blank"><i className="bi bi-facebook"></i></a>
                    <a href="https://www.instagram.com/herodonia.game/" target="_blank"><i className="bi bi-instagram"></i></a>
                </div>

                <div className={styles.links}>
                    <a href="https://www.herodonia.com" target="_blank"><i className="bi bi-globe2"></i> Website</a>
                    <a href="mailto:support@herodonia.com" target="_blank"><i className="bi bi-envelope-at-fill"></i> Support</a>
                </div>
            </div>
            <h2 className={`${styles.serverStatus} ${styles[loginStore.serverOnline ? 'online' : 'offline']}`}>{loginStore.serverStatus}</h2>
        </div>
    );
};

export default view(LoginScreen);