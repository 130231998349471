import * as React from "react";
import styles from './EquipmentStatistics.module.scss'
import {view} from "react-easy-state";
import StatisticsBar from "../../atom/StatisticsBar/StatisticsBar";
import statisticsStore from "../../store/StatisticsStore";


type EquipmentStatisticsProps = {};

const EquipmentStatistics = ({}: EquipmentStatisticsProps) => {
    return (
        <div className={styles.wrapper}>
            <StatisticsBar title={'Equipment'} isDisplayNumber={false} value={''}/>
            <StatisticsBar title={'Attack'} barColor={'#F23B00'} isDisplayNumber={false} value={statisticsStore.attack}/>
            <StatisticsBar title={'Defence'} barColor={'#64b900'} isDisplayNumber={false} value={statisticsStore.defence}/>

            <br/>

            <StatisticsBar title={'Totals'} isDisplayNumber={false} value={''}/>
            <StatisticsBar title={'Damage'} barColor={'#F23B00'} isDisplayNumber={false} value={statisticsStore.maxDamage}/>
            <StatisticsBar title={'Reduction'} barColor={'#64b900'} isDisplayNumber={false} value={statisticsStore.damageReduction}/>
            <StatisticsBar title={'Block'} barColor={'#00AAFF'} isDisplayNumber={false} value={statisticsStore.blockPercent + '%'}/>
        </div>
    );
}

export default view(EquipmentStatistics);