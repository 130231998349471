import React, {useEffect, useState} from 'react';
import { store, view } from 'react-easy-state';
import styles from './AutoAimShortcut.module.scss';
import Container from "../../../di/container";
import {PlaySound} from "../../../util/PlaySound";
import {SoundEffect} from "../../../service/soundService";

export type AutoAimShortcutProps = {
    slot: number,
};

const AutoAimShortcut = ({slot}: AutoAimShortcutProps) => {
    const handleClick = () => {
        PlaySound(SoundEffect.CLICK);
        Container.getCreatureService().autoTarget();
    }

    return (
        <div className={styles.wrapper} key={slot} style={{gridArea: 'p' + (slot)}} onClick={() => handleClick()}>
            <i className="bi bi-vinyl"/>
        </div>
    );
};

export default view(AutoAimShortcut);