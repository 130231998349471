import {store} from "react-easy-state";

export type LeaderboardStoreType = {
    leaderboard: {},
    highscores: {},
}

const leaderboardStore: LeaderboardStoreType = store({
    leaderboard: {},
    highscores: {},
});

export default leaderboardStore;