import * as React from "react";
import {view} from "react-easy-state";
import styles from './HudMenuButton.module.scss'
import {PlaySound} from "../../../util/PlaySound";
import {SoundEffect} from "../../../service/soundService";

export type HudMenuButtonProps = {
    icon: string,
    onClick: () => void,
    wide?: boolean,
    alert?: boolean,
};

const HudMenuButton = ({icon, onClick, wide, alert = false}: HudMenuButtonProps) => {
    return (
        <div className={`${styles.button} ${wide ? styles.wide : ''} ${alert ? styles.alert : ''}`} onClick={() => {PlaySound(SoundEffect.CLICK); onClick();}} dangerouslySetInnerHTML={{
            // @ts-ignore
            __html: icon
        }}>
        </div>
    );
};

export default view(HudMenuButton);