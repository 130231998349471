import {MainScene} from "../scene/mainScene";
import {Light} from "../model/light";

export enum LightLevel {
    LIGHT_LEVEL_DAY = 0,
    LIGHT_LEVEL_SUNSET = 1,
    LIGHT_LEVEL_TWILIGHT = 2,
    LIGHT_LEVEL_NIGHT = 3,
    LIGHT_LEVEL_DAWN = 4,
    LIGHT_LEVEL_SUNRISE = 5,
}

export class LightService {
    LIGHT_SCALE: number = 1.0;
    LIGHT_BLINK_TIME: number = 500;
    LIGHT_BLINK_PERCENT: number = 0.05;

    private rectangleMask: Phaser.GameObjects.Rectangle|undefined;
    private rectangleMaskContainer: Phaser.GameObjects.RenderTexture|undefined;
    private renderTexture: Phaser.GameObjects.RenderTexture|undefined;

    private sunbeamImage: Phaser.GameObjects.TileSprite|undefined;
    private sunbeamSmallImage: Phaser.GameObjects.TileSprite|undefined;

    private staticLights: Light[] = [];
    private dynamicLights: Light[] = [];

    private lightLevel: LightLevel = LightLevel.LIGHT_LEVEL_DAY;
    private underRooftop: boolean = false;

    private readonly scene: MainScene;

    constructor(
        mainScene: MainScene,
    ) {
        this.scene = mainScene;
    }

    public preload(): void {
        this.scene.load.image('mask_new', 'assets/images/lightMask.png');
        this.scene.load.image('mask_tint', 'assets/images/lightMaskColor.png');

        this.scene.load.image('sunbeam', 'assets/images/sunbeam.png');
        this.scene.load.image('sunbeam-indoors', 'assets/images/sunbeam-indoors.png');
    }

    public create(): void {
        const width = 2048;//this.scene.game.canvas.width;
        const height = 2048; //this.scene.game.canvas.height;

        this.rectangleMask = this.scene.add.rectangle(0, 0, width, height, 0x000000, 1);
        this.rectangleMask.setVisible(false);
        this.rectangleMask.setDepth(10000000);
        this.rectangleMask.setOrigin(0, 0);
        this.rectangleMask.setAlpha(0.8);

        this.renderTexture = this.scene.add.renderTexture(0, 0, width, height);
        this.renderTexture.setAlpha(1.0);
        this.renderTexture.setOrigin(0, 0);
        this.renderTexture.setDepth(10000025);

        this.rectangleMaskContainer = this.scene.add.renderTexture(0, 0, width, height);
        this.rectangleMaskContainer.setAlpha(1.0);
        this.rectangleMaskContainer.setOrigin(0.5, 0.5);
        this.rectangleMaskContainer.setDepth(10000026);
        this.rectangleMaskContainer.setBlendMode(Phaser.BlendModes.ADD);
        // @ts-ignore
        this.rectangleMaskContainer.draw(this.lightMask, 0, 0);

        this.renderTexture.mask = new Phaser.Display.Masks.BitmapMask(this.scene, this.rectangleMaskContainer);
        this.renderTexture.mask.invertAlpha = true;

        this.sunbeamImage = this.scene.add.tileSprite(0, 0, this.scene.game.canvas.width, this.scene.game.canvas.height, 'sunbeam');
        this.sunbeamImage.setTilePosition(0, 0)
            .setDepth(9999999)
            .setOrigin(0.5, 0.5)
            .setPosition(this.scene.game.canvas.width/2, this.scene.game.canvas.height/2)
            .setScrollFactor(0)
            .setTileScale(1, 1)
            .setRotation(0.3)
            .setAlpha(0.85)
            .setVisible(false);

        this.sunbeamSmallImage = this.scene.add.tileSprite(0, 0, this.scene.game.canvas.width, this.scene.game.canvas.height, 'sunbeam');
        this.sunbeamSmallImage.setTilePosition(0, 0)
            .setDepth(9999999)
            .setOrigin(0.5, 0.5)
            .setPosition(this.scene.game.canvas.width/2, this.scene.game.canvas.height/2)
            .setScrollFactor(0)
            .setTileScale(0.4, 0.2)
            .setRotation(0.3)
            .setAlpha(0.25)
            .setVisible(false);

        this.scene.tweens.add({
            targets: this.sunbeamImage,
            yoyo: true,
            loop: true,
            ease: 'Quart.easeInOut',
            duration: 5000,
            alpha: 0.55,
        });

        this.scene.scale.on('resize', this.onResize, this);
    }

    public update(time: number, delta: number): void {
        const sunbeamScrollX = this.underRooftop ? 0 :  + time/40;
        // @ts-ignore
        this.sunbeamImage.tilePositionX = this.scene.cameras.main.scrollX + this.scene.cameras.main.scrollY*0.25 + sunbeamScrollX;

        const sunbeamSmallScrollX = this.underRooftop ? 0 : time/25;
        // @ts-ignore
        this.sunbeamSmallImage.tilePositionX = this.scene.cameras.main.scrollX * 2.26 + this.scene.cameras.main.scrollY * 0.7 + sunbeamSmallScrollX;

        // @ts-ignore
        this.rectangleMaskContainer.clear();
        // @ts-ignore
        this.renderTexture.clear();

        // @ts-ignore
        const x = this.scene.getMyPlayer()?.getPixelsX() + 16;
        // @ts-ignore
        const y = this.scene.getMyPlayer()?.getPixelsY() + 16;

        this.rectangleMaskContainer?.setPosition(x, y);

        this.staticLights.forEach((light) => {
            // @ts-ignore
            const ax = light.getX()*32 + this.scene.game.canvas.width/2 - this.scene.getMyPlayer()?.getPixelsX();
            // @ts-ignore
            const ay = light.getY()*32 + this.scene.game.canvas.height/2 - this.scene.getMyPlayer()?.getPixelsY();

            //if (this.lightLevel !== LightLevel.LIGHT_LEVEL_DAY) {
                this.rectangleMaskContainer?.draw(light.getMask(), ax, ay);
            //}

            light.getMaskColor()/**.setVisible(this.lightLevel !== LightLevel.LIGHT_LEVEL_DAY)*/.setPosition(
                // @ts-ignore
                light.getX()*32 + 16,
                // @ts-ignore
                light.getY()*32 + 16
            );
        });

        for (let key in this.dynamicLights) {
            const light = this.dynamicLights[key];

            // @ts-ignore
            const ax = light.getMask().x + this.scene.game.canvas.width/2 - this.scene.getMyPlayer()?.getPixelsX();
            // @ts-ignore
            const ay = light.getMask().y + this.scene.game.canvas.height/2 - this.scene.getMyPlayer()?.getPixelsY();

            //if (this.lightLevel !== LightLevel.LIGHT_LEVEL_DAY) {
            //     this.rectangleMaskContainer?.draw(light.getMask(), ax, ay);
            //}
            if (light.getEnabled()) {
                this.rectangleMaskContainer?.draw(light.getMask(), ax, ay);
            }

            light.getMaskColor()/**.setVisible(this.lightLevel !== LightLevel.LIGHT_LEVEL_DAY)*/.setPosition(
                // @ts-ignore
                light.getMask().x + 16,
                // @ts-ignore
                light.getMask().y + 16
            );
        }

        this.renderTexture?.setPosition(
            this.scene.cameras.main.scrollX,
            this.scene.cameras.main.scrollY
        );

        // @ts-ignore
        this.renderTexture.draw(this.rectangleMask, 0, 0);
    }

    public onResize(): void {
        const width = this.scene.game.canvas.width;
        const height = this.scene.game.canvas.height;

        this.rectangleMask?.setSize(width, height);
        this.rectangleMask?.setPosition(0, 0);
        this.rectangleMask?.setOrigin(0, 0);

        this.renderTexture?.setSize(width, height);
        this.renderTexture?.setPosition(0, 0);
        this.renderTexture?.setOrigin(0, 0);

        this.rectangleMaskContainer?.setSize(width, height);
        this.rectangleMaskContainer?.setPosition(0, 0);
        this.rectangleMaskContainer?.setOrigin(0.5, 0.5);

        this.sunbeamImage?.setSize(width*1.5, height*1.5);
        this.sunbeamImage?.setPosition(width/2, height/2);
        this.sunbeamImage?.setOrigin(0.5, 0.5);

        this.sunbeamSmallImage?.setSize(width*1.5, height*1.5);
        this.sunbeamSmallImage?.setPosition(width/2, height/2);
        this.sunbeamSmallImage?.setOrigin(0.5, 0.5);
    }

    public setLightLevel(level: LightLevel, instantChange: boolean = false): void {
        if (this.lightLevel === level) {
            return;
        }

        const previousLightLevel = this.lightLevel;
        this.lightLevel = level;

        const [alpha, color] = this.lightLevelToParameters(level);

        this.scene.tweens.add({
            targets: this.rectangleMask,
            alpha: { value: alpha, duration: instantChange ? 100 : 5000, ease: 'linear' },
            fillStyle: { value: color, duration: instantChange ? 100 : 5000, ease: 'linear' },
        });

        this.staticLights.forEach((light) => {
            this.tweenMasksOnLightChange(light, this.lightLevel, previousLightLevel, instantChange);
        });

        for (let key in this.dynamicLights) {
            this.tweenMasksOnLightChange(this.dynamicLights[key], this.lightLevel, previousLightLevel, instantChange);
        }

        this.tweenSunBeamsOnLightChange(this.lightLevel, instantChange);
    }

    private tweenMasksOnLightChange(light: Light, lightLevel: LightLevel, previousLightLevel: LightLevel, instantChange: boolean): void {
        const [previousAlpha, previousTint] = this.lightLevelToParameters(previousLightLevel);
        const [currentAlpha, currentTint] = this.lightLevelToParameters(lightLevel);

        if (lightLevel !== LightLevel.LIGHT_LEVEL_DAY) {
            light.getMaskColor().setVisible(true).setAlpha(previousAlpha);
        } else {
            light.getMaskColor().setAlpha(previousAlpha);
        }

        this.scene.tweens.add({
            targets: light.getMaskColor(),
            alpha: {value: currentAlpha, duration: instantChange ? 100 : 5000, ease: 'linear'},
            onComplete: (tween) => {
                if (lightLevel === LightLevel.LIGHT_LEVEL_DAY) {
                    light.getMaskColor().setVisible(false)
                }
            },
        });
    }

    private tweenSunBeamsOnLightChange(lightLevel: LightLevel, instantChange: boolean): void {
        const visible = lightLevel === LightLevel.LIGHT_LEVEL_DAY;

        if (visible) {
            this.sunbeamImage?.setVisible(true).setAlpha(0);
            this.sunbeamSmallImage?.setVisible(true).setAlpha(0);
        }

        const sunbeamAlpha = visible ? 0.85 : 0;
        this.scene.tweens.add({
            targets: this.sunbeamImage,
            alpha: {value: sunbeamAlpha, duration: instantChange ? 100 : 5000, ease: 'linear'},
            onComplete: (tween) => {
                this.sunbeamImage?.setVisible(this.lightLevel === LightLevel.LIGHT_LEVEL_DAY)
            },
        });

        const sunbeamSmallAlpha = visible ? 0.35 : 0;
        this.scene.tweens.add({
            targets: this.sunbeamSmallImage,
            alpha: {value: sunbeamSmallAlpha, duration: instantChange ? 100 : 5000, ease: 'linear'},
            onComplete: (tween) => {
                this.sunbeamSmallImage?.setVisible(this.lightLevel === LightLevel.LIGHT_LEVEL_DAY)
            },
        });
    }

    public getLightLevel(): LightLevel {
        return this.lightLevel;
    }

    public removeLight(x: number, y: number): void {
        this.staticLights = this.staticLights.filter((light) => {
            if (light.getX() === x && light.getY() === y) {
                light.getMask().destroy();
                light.getMaskColor().destroy();

                return false;
            }

            return true;
        })
    }

    public addLight(chunkId: number, x: number, y: number, radius: number, tint: number): void {
        let mask = this.scene.add.image(0,0, 'mask_new');
        mask.setVisible(false)
            .setAlpha(0.8)
            .setScale(radius);

        let maskColor = this.scene.add.image(0,0, 'mask_tint');
        maskColor.setVisible(true)
            .setScale(radius)
            .setDepth(10000025)
            .setAlpha(this.getCurrentAlpha())
            .setBlendMode(Phaser.BlendModes.COPY)
            .setTint(tint);

        const light = new Light(tint, mask, maskColor, x, y, chunkId);

        this.staticLights.push(light);
    }

    public addDynamicLight(playerId: number, x: number, y: number, radius: number): void {
        let mask = this.scene.add.image(0,0, 'mask_new');
        mask.setVisible(false)
            .setAlpha(0.8)
            .setScale(radius)
            .setPosition(x*32, y*32);

        let maskColor = this.scene.add.image(0,0, 'mask_tint');
        maskColor.setVisible(true)
            .setScale(radius)
            .setDepth(10000025)
            .setAlpha(this.getCurrentAlpha())
            .setBlendMode(Phaser.BlendModes.COPY)
            .setTint(0xff7200)
            .setPosition(x*32, y*32);

        this.scene.tweens.add({
            targets: mask,
            scale: radius - radius*this.LIGHT_BLINK_PERCENT,
            duration: this.LIGHT_BLINK_TIME,
            ease: 'Sine.easeInOut',
            loop: -1,
            yoyo: true
        });

        this.dynamicLights[playerId] = new Light(0xff7200, mask, maskColor, x, y, 0);
    }

    public moveDynamicLight(playerId: number, x: number, y: number, walkTime: number): void {
        if (this.dynamicLights[playerId] === undefined) {
            return;
        }

        this.dynamicLights[playerId].setX(x);
        this.dynamicLights[playerId].setY(y);

        this.scene.tweens.add({
            targets: this.dynamicLights[playerId].getMask(),
            x: x * 32,
            y: y * 32,
            duration: walkTime,
        });
        this.scene.tweens.add({
            targets: this.dynamicLights[playerId].getMaskColor(),
            x: x * 32,
            y: y * 32,
            duration: walkTime,
        });
    }

    public teleportDynamicLight(playerId: number, x: number, y: number): void {
        if (this.dynamicLights[playerId] === undefined) {
            return;
        }

        this.dynamicLights[playerId].setX(x);
        this.dynamicLights[playerId].setY(y);

        this.dynamicLights[playerId].getMask().setPosition(x * 32, y * 32);
        this.dynamicLights[playerId].getMaskColor().setPosition(x * 32, y * 32);
    }

    public enableDynamicLight(playerId: number): void {
        if (this.dynamicLights[playerId] === undefined) {
            return;
        }

        this.dynamicLights[playerId].setEnabled(true);

        this.dynamicLights[playerId].getMask().setActive(true);
        /** todo: use real tint from player */
        this.dynamicLights[playerId].getMaskColor().setTint(0xff7200).setBlendMode(Phaser.BlendModes.COPY).setDepth(10000025).setVisible(true);
    }

    public disableDynamicLight(playerId: number): void {
        if (this.dynamicLights[playerId] === undefined) {
            return;
        }

        this.dynamicLights[playerId].setEnabled(false);

        this.dynamicLights[playerId].getMask().setActive(false);
        this.dynamicLights[playerId].getMaskColor().setTint(undefined).setBlendMode(Phaser.BlendModes.ADD).setDepth(-100).setVisible(false);
    }

    public removeDynamicLight(playerId: number): void {
        if (this.dynamicLights[playerId] == undefined) {
            return;
        }

        this.dynamicLights[playerId].getMask().destroy();
        this.dynamicLights[playerId].getMaskColor().destroy();

        delete this.dynamicLights[playerId];
    }

    public clearChunk(chunkId: number): void {
        this.staticLights = this.staticLights.filter((light) => {
            if (light.getChunkId() != chunkId) {
                return true;
            }

            light.getMask().destroy();
            light.getMaskColor().destroy();

            return false;
        })
    }

    public getUnderRooftop(): boolean {
        return this.underRooftop;
    }

    public setUnderRooftop(isUnder: boolean): void {
        if (this.underRooftop !== isUnder) {
            this.underRooftop = isUnder;

            this.sunbeamImage?.setTexture(this.underRooftop ? 'sunbeam-indoors' : 'sunbeam');
            this.sunbeamSmallImage?.setTexture(this.underRooftop ? 'sunbeam-indoors' : 'sunbeam');

            this.tweenSunBeamsOnLightChange(this.lightLevel, true);
        }
    }

    /**
     * Returns alpha and color for particular time of day
     */
    private lightLevelToParameters(level: LightLevel): [number, number] {
        switch (level) {
            case LightLevel.LIGHT_LEVEL_DAY:
                return [0, 0xffbb00];
                //return [0.2, 0xffbb00];
                //return [0, 0xffffff];
            case LightLevel.LIGHT_LEVEL_SUNSET:
                return [0.6, 0x130e00];
            case LightLevel.LIGHT_LEVEL_TWILIGHT:
                return [0.8, 0x130e00];
            case LightLevel.LIGHT_LEVEL_NIGHT:
                return [0.85, 0x000000];
            case LightLevel.LIGHT_LEVEL_DAWN:
                return [0.8, 0x000817];
            case LightLevel.LIGHT_LEVEL_SUNRISE:
                return [0.6, 0x000817];
        }
    }

    private getCurrentAlpha(): number {
        return this.lightLevelToParameters(this.lightLevel)[0];
    }

    private getCurrentTint(): number {
        return this.lightLevelToParameters(this.lightLevel)[1];
    }
}