import * as React from "react";
import styles from './TopHud.module.scss';
import {view} from "react-easy-state";
import LargeBar, {LargeBarType} from "../../atom/LargeBar/LargeBar";
import statisticsStore from "../../store/StatisticsStore";

const TopHud = () => {
    const additionalClass = (statisticsStore.health / statisticsStore.maxHealth) <= 0.1 ? styles['alert'] : '';

    return (
        <div className={`${styles.wrapper} ${additionalClass}`}>
            <LargeBar type={LargeBarType.TYPE_HEALTH} text={'Health: '} value={statisticsStore.health} maxValue={statisticsStore.maxHealth} isDisplayNumber={true}/>
            <LargeBar type={LargeBarType.TYPE_MANA} text={'Magic: '} value={statisticsStore.mana} maxValue={statisticsStore.maxMana} isDisplayNumber={true}/>
            <LargeBar type={LargeBarType.TYPE_EXPERIENCE} text={'Exp: '} value={statisticsStore.experience} maxValue={statisticsStore.maxExperience} isDisplayNumber={true}/>
            <div className={styles.details}>
                <div className={styles.left}>
                    Level {statisticsStore.level}
                </div>
                <div className={styles.right}>
                    {statisticsStore.archetype}
                </div>
            </div>
        </div>
    );
}

export default view(TopHud);