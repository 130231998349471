import * as React from "react";
import {view} from "react-easy-state";
import Window, {WindowProps, WindowStyle} from "./Window";
import Accordion from "../component/Accordion/Accordion";
import questLogStore from "../store/QuestLogStore";
import styles from "./QuestLogWindow.module.scss";
import questObjectivesStore from "../store/QuestObjectivesStore";

const QuestLogWindow = ({children, windowState}: WindowProps) => {
    return (
        <Window title={' <i class="bi bi-book-fill"></i> Quest Log'} windowState={windowState} windowStyle={WindowStyle.STYLE_SCROLL_NARROW}>
            {!!Object.keys(questLogStore.quests).length &&
            <>
                {/*<Select value={selectStore.value} options={['All', 'In Progress', 'Finished']}/>*/}
                <Accordion>
                    {
                        Object.keys(questLogStore.quests)
                            .sort((a, b) => {
                                return questLogStore.quests[a]['finished'] - questLogStore.quests[b]['finished'];
                            })
                            .map((quest, index) => {
                            const iconStyle = questLogStore.quests[quest]['finished'] ? styles['questFinished'] : styles['questActive'];
                            const iconText = questLogStore.quests[quest]['finished'] ? 'Finished' : 'In Progress';

                            return <ul className={styles.quest} key={quest} data-title={quest} data-title-icon={<span className={iconStyle}>{iconText}</span>}>
                                {questLogStore.quests[quest]['states'].map((text, index, row) => {
                                    const accentClassName = (index + 1 === row.length && !questLogStore.quests[quest]['finished']) ? styles['active'] : styles['inactive'];
                                    return <li className={`${styles.logEntry} ${accentClassName}`} key={index}>{text}</li>;
                                })}
                                {questObjectivesStore.objectives.get(quest)?.size &&
                                <div className={styles.objectives}>
                                    <h3>Objectives</h3>
                                    <ul>
                                        {// @ts-ignore
                                            [...questObjectivesStore.objectives.get(quest).entries()].map(([objective, progress]) => {
                                                return <li key={objective}>{objective}: {progress}</li>
                                            })
                                        }
                                    </ul>
                                </div>
                                }
                            </ul>
                        })
                    }
                </Accordion>
            </>
            }
            {!Object.keys(questLogStore.quests).length &&
            <h2 style={{textAlign: "center", color: "#222222"}}>You have no quests yet</h2>
            }
        </Window>
    );
};

export default view(QuestLogWindow);