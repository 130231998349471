import * as React from "react";
import {view} from "react-easy-state";
import Window, {WindowProps, WindowStyle} from "./Window";
import MenuContainer from "../component/MenuContainer/MenuContainer";

const MenuWindow = ({children, windowState}: WindowProps) => {
    return (
        <Window title={'<i class="bi bi-justify"></i> Menu'} windowState={windowState} windowStyle={WindowStyle.STYLE_NARROW_FORCE}>
            <MenuContainer/>
        </Window>
    );
};

export default view(MenuWindow);