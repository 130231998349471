import * as React from "react";
import {useState} from "react";
import {view} from "react-easy-state";
import Button, {ButtonType} from "../../atom/Button/Button";
import styles from './StatisticsChooser.module.scss'
import Container from "../../../di/container";
import statisticsStore from "../../store/StatisticsStore";
import Loading from "../../atom/Loading/Loading";
import {Confetti} from "../../../util/Confetti";
import {PlaySound} from "../../../util/PlaySound";
import {SoundEffect} from "../../../service/soundService";
import {TutorialState, tutorialStore} from "../../store/TutorialStore";
import {stat} from "fs";

const StatisticsChooser = () => {
    const [loadingType, setLoadingType] = useState<string>('');
    const loadingMs = 750;

    const addPoint = (type: string) => {
        PlaySound(SoundEffect.CHARGING);
        setLoadingType(type);

        setTimeout(() => {
            Container.getNetworkSender().sendAddStatisticsPoint(type);
            // setLoadingType('');
            PlaySound(SoundEffect.BONUS);
            Confetti();

            if (tutorialStore.enabled) {
                let numOf = statisticsStore.agility + statisticsStore.strength + statisticsStore.magicPower;
                if (numOf === 30) {
                    tutorialStore.state = TutorialState.STATE_SETTING_SHORTCUT_INFO;
                }
            }
        }, loadingMs)

        setTimeout(() => {
            setLoadingType('');
        }, loadingMs*2)
    }

    return (
        <>
            <h2 className={styles.available}>
                Available Statistics Points: <span className={styles.availableAccent}>{statisticsStore.availableStatisticsPoints}</span>
            </h2>
            <div className={styles.wrapper}>
                <>
                    <div className={styles.statistic}>
                        <h2 className={styles.title}>Strength: <span
                            className={styles.strengthAccent}>{statisticsStore.strength} (+{statisticsStore.bonusStrength})</span>
                        </h2>
                        {loadingType === 'strength' &&
                            <Loading color={'#F23B00'} ms={loadingMs} text={'Improving Strength...'} successText={'Success!'}/>
                            }
                        {loadingType !== 'strength' &&
                            <Button text={'Improve'}
                                    isActive={loadingType.length === 0 && statisticsStore.availableStatisticsPoints > 0}
                                    type={ButtonType.TYPE_ACCEPT}
                                    click={() => addPoint('strength')}
                            />
                        }
                        <p>• Increases Max Health (+10)</p>
                        <p>• Improves Sword, Axe and Hammer damage</p>
                    </div>
                    <div className={styles.statistic}>
                        <h2 className={styles.title}>Magic: <span
                            className={styles.magicPowerAccent}>{statisticsStore.magicPower} (+{statisticsStore.bonusMagicPower})</span>
                        </h2>
                        {loadingType === 'magicPower' &&
                            <Loading color={'#158dfa'} ms={loadingMs} text={'Improving Magic...'} successText={'Success!'}/>
                        }
                        {loadingType !== 'magicPower' &&
                            <Button text={'Improve'}
                                    isActive={loadingType.length === 0 && statisticsStore.availableStatisticsPoints > 0}
                                    type={ButtonType.TYPE_ACCEPT}
                                    click={() => addPoint('magicPower')}
                            />
                        }
                        <p>• Increases Max Mana (+10)</p>
                        <p>• Improves damage dealt with Wands and Rods</p>
                    </div>
                    <div className={styles.statistic}>
                        <h2 className={styles.title}>Agility: <span
                            className={styles.agilityAccent}>{statisticsStore.agility} (+{statisticsStore.bonusAgility})</span>
                        </h2>
                        {loadingType === 'agility' &&
                            <Loading color={'#ff8918'} ms={loadingMs} text={'Improving Agility...'} successText={'Success!'}/>
                        }
                        {loadingType !== 'agility' &&
                            <Button text={'Improve'}
                                    isActive={loadingType.length === 0 && statisticsStore.availableStatisticsPoints > 0}
                                    type={ButtonType.TYPE_ACCEPT}
                                    click={() => addPoint('agility')}
                            />
                        }
                        <p>• Increases Max Mana (+5) and Max Health (+5)</p>
                        <p>• Improves Dagger and Distance Weapons damage</p>
                    </div>
                </>
            </div>
        </>
    );
}

export default view(StatisticsChooser);