import * as React from "react";
import styles from './RangeSlider.module.scss';
import {view} from "react-easy-state";

export type RangeSliderProps = {
    name: string,
    min: number,
    max: number,
    value: number,
    onChange: (value: number) => void,
};

const RangeSlider = ({name, min, max, value, onChange}: RangeSliderProps) => {
    return (
        <div className={styles.container}>
            <span className={styles.min}>{min}</span>
            <input type="range" min={min} max={max} value={value} className={styles.slider} onChange={(event) => {
                //value = Number(event.target.value);
                onChange(Number(event.target.value));
            }}/>
            <span className={styles.max}>{max}</span>
            <p className={styles.name}>{name}: <span className={styles.value}>{value}</span></p>
        </div>
    );
};

export default view(RangeSlider);