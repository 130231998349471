import * as React from "react";
import styles from './HorizontalGroup.module.scss'

export type HorizontalGroupProps = {
    children?: React.ReactNode,
};

const HorizontalGroup = (props: HorizontalGroupProps) => {
    return (
        <div className={styles.horizontalGroup}>
            {props.children}
        </div>
    );
};

export default HorizontalGroup;