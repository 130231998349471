import {store} from "react-easy-state";

type QuestLog = {
    title: string,
    states: string[],
    finished: boolean,
}

type QuestLogStoreType = {
    quests: QuestLog[],
}

const questLogStore: QuestLogStoreType = store({
    quests: [],
});

export default questLogStore;