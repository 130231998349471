import {store} from "react-easy-state";

type DailyRewardStore = {
    strike: number,
    claimedAt: Date,
    message: string,
}

const defaultDate = new Date();
defaultDate.setTime(0);

const dailyRewardStore: DailyRewardStore = store({
    strike: 1,
    claimedAt: defaultDate,
    message: '',
});

export default dailyRewardStore;