import * as React from "react";
import styles from './Carousel.module.scss'
import {view} from "react-easy-state";

export type CarouselProps = {
    children: React.ReactNode,
    height: number[]
};

const Carousel = (props: CarouselProps) => {
    return (
        <div className={styles.wrapper}>
            {
                // @ts-ignore
                props.children.map((child: React.ReactNode, index: number) => {
                    return <div className={styles.tab} key={index} style={{height: props.height[index] + '%'}}>{child}</div>
                })
            }
        </div>
    );
}

export default view(Carousel);