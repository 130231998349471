import * as React from "react";
import {view} from "react-easy-state";
import Window, {WindowProps, WindowStyle} from "./Window";
import Button, {ButtonSize, ButtonType} from "../atom/Button/Button";
import ButtonContainer from "../atom/ButtonContainer/ButtonContainer";
import dropPickupStore from "../store/DropPickupStore";
import Container from "../../di/container";
import ItemRow from "../component/ItemRow/ItemRow";

const DropPickupWindow = ({children, windowState}: WindowProps) => {
    return (
        <Window title={'<i class="bi bi-arrow-down-square-fill"></i> Pick up Items'} windowState={windowState} windowStyle={WindowStyle.STYLE_NARROW_FORCE} footer={
            <ButtonContainer>
                <Button text={'Collect'} type={ButtonType.TYPE_ACCEPT} click={() => {
                    Container.getNetworkSender().sendCollectTopContainerAll(
                        dropPickupStore.x,
                        dropPickupStore.y,
                        dropPickupStore.uniqueId
                    );
                    windowState.closeHandler();
                }}/>
            </ButtonContainer>
        }>
            {dropPickupStore.items.map((item: any, index: number) => {
                return <ItemRow
                    // @ts-ignore
                    key={index}
                    // @ts-ignore
                    position={item.id}
                    details={item}
                    // @ts-ignore
                    id={item.id}
                    quantity={item.quantity}
                    isSelectable={false}
                    onClick={() => {
                        Container.getNetworkSender().sendCollectTopContainer(
                            dropPickupStore.x,
                            dropPickupStore.y,
                            dropPickupStore.uniqueId,
                            index
                        );
                    }}
                />
            })}
        </Window>
    );
};

export default view(DropPickupWindow);