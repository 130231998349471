import * as React from "react";
import styles from './StatisticsBar.module.scss';
import {view} from "react-easy-state";

export enum StatisticsBarType {
    TYPE_HEALTH = 'health',
    TYPE_MANA = 'mana',
    TYPE_EXPERIENCE = 'experience',
    TYPE_CAPACITY = 'capacity',
}

type MyProps = {
    title: string,
    barColor?: string;
    type?: StatisticsBarType,
    isDisplayNumber: boolean,
    value: number|string,
    maxValue?: number|null,
};

const StatisticsBar = ({title, barColor, type, isDisplayNumber, value, maxValue = null}: MyProps) => {
    const currentAndMaxType = (typeof maxValue === 'number') && (typeof value === 'number');

    return (
        <div className={styles.statisticsBarWrapper}>
            <div className={styles.titleWrapper}>
                <span className={styles.titleLeft}>
                    {title + ': '}
                </span>
                <span className={styles.titleRight} style={{
                    color: barColor
                }}>
                    {currentAndMaxType &&
                    value + '/' + maxValue
                    }
                    {!currentAndMaxType &&
                    value
                    }
                </span>
            </div>
            {currentAndMaxType &&
            <div className={styles.statisticsBar}>
                <div className={`${styles.statisticsBarFill} ${type ? styles[type] : ''}`} style={{
                    width: value/maxValue*100.0 + '%',
                    //backgroundColor: barColor
                }}>
                    <span className={styles.text}>
                        {isDisplayNumber &&
                        value + '/' + maxValue}
                    </span>
                </div>
            </div>
            }
        </div>
    );
}

export default view(StatisticsBar);