import 'phaser';
import {MainScene} from "../scene/mainScene";
import Container from "../di/container";
import {PlaySound} from "../util/PlaySound";
import {SoundEffect} from "../service/soundService";
import {StopSound} from "../util/StopSound";
import {WindowCreator} from "../ui/service/WindowCreator";

export enum CreatureType {
    TYPE_PLAYER = 0,
    TYPE_NPC = 1,
    TYPE_MONSTER = 2,
    TYPE_UNIQUE_MONSTER = 3,
    TYPE_HIDDEN_PLAYER = 4,
    TYPE_HIDDEN_MONSTER = 5,
}

export class Creature  {
    private phaserScene: Phaser.Scene;
    private id: number;
    private x: number;
    private y: number;
    private name: string;
    private type: CreatureType;
    private health: number;
    private maxHealth: number;
    private walkTime: number = 1000;
    private outfitId: string;
    private idleAnimation: boolean;
    private nextAnimationFrame: any;
    private direction: string = '';
    private lastMoveTween: any;

    private lastClicks: number[] = [];

    public isMyPlayer: boolean = false;

    private readonly sprite: Phaser.GameObjects.Sprite;
    private readonly container: Phaser.GameObjects.Container;
    private readonly overlay: Phaser.GameObjects.Container;
    private readonly below: Phaser.GameObjects.Container;

    constructor(
        phaserScene: MainScene,
        id: number,
        x: number,
        y: number,
        name: string,
        type: CreatureType,
        health: number,
        maxHealth: number,
        walkTime: number,
        outfitId: string,
        direction: number,
        idleAnimation: boolean,
        frameWidth: number,
        frameHeight: number
    ) {
        /** INITIALIZATIONS */
        this.phaserScene = phaserScene;
        this.id = id;
        this.x = x;
        this.y = y;
        this.name = name;
        this.type = type;
        this.health = health;
        this.maxHealth = maxHealth;
        this.walkTime = walkTime;
        this.outfitId = outfitId;
        this.idleAnimation = idleAnimation;

        this.direction = this.directionToString(direction);

        let color = '#00ff00';
        switch (type) {
            case CreatureType.TYPE_PLAYER:
            default:
                color = '#00ff00';
                break;
            case CreatureType.TYPE_NPC:
                color = '#00AAFF';
                break;
            case CreatureType.TYPE_MONSTER:
                color = '#F23B00';
                break;
            case CreatureType.TYPE_UNIQUE_MONSTER:
                color = '#FFC500';
                break;
        }

        const onTop = frameWidth > 32 || frameHeight > 32;
        const biggerCreature = frameHeight > 48;

        /** SPRITES */
        this.sprite = this.phaserScene.add.sprite(32 - frameWidth, 32 - frameHeight, 'creature-' + outfitId, 0).setOrigin(0, 0).setDepth(onTop ? 6 : 5).setInteractive(new Phaser.Geom.Rectangle(frameWidth - 32, frameHeight - 32, 32, 32), Phaser.Geom.Rectangle.Contains);//(this.phaserScene.input.makePixelPerfect());
        let creatureName = this.phaserScene.add.text(16, biggerCreature ? -48 : -32, name).setFontFamily('Khmer-Nettra').setResolution(1.5).setFontSize(12).setColor(color).setOrigin(0.5, 0).setStroke('#000000', 2.5)

        this.container = this.phaserScene.add.container(x*32, y*32);
        this.overlay = this.phaserScene.add.container(x*32, y*32);
        this.below = this.phaserScene.add.container(x*32, y*32);
        this.container.add(this.sprite);

        if (type !== CreatureType.TYPE_HIDDEN_PLAYER && type !== CreatureType.TYPE_HIDDEN_MONSTER) {
            this.overlay.addAt(creatureName, 0);
            if (type !== CreatureType.TYPE_NPC) {
                let healthBackground = this.phaserScene.add.rectangle(0, biggerCreature ? -32 : -16, 32, 4, 0x000000).setAlpha(1.0).setOrigin(0, 0);
                let healthBackgroundDecoration = this.phaserScene.add.rectangle(-1, biggerCreature ? -32 + 1 : -16 + 1, 34, 2, 0x000000).setAlpha(1.0).setOrigin(0, 0);
                let healthFill = this.phaserScene.add.rectangle(1, biggerCreature ? -32 + 1 : -16 + 1, 32 - 2, 2, Creature.hexStringToNumber(color)).setAlpha(1.0).setOrigin(0, 0);
                this.overlay.addAt(healthBackground, 1)
                    .addAt(healthBackgroundDecoration, 2)
                    .addAt(healthFill, 3);
            }

            this.sprite.on('pointerup', (pointer) => {
                if (phaserScene.myPlayerId === this.id) {
                    return;
                }

                this.lastClicks.push(Date.now());
                this.lastClicks = this.lastClicks.filter((timestamp: number) => timestamp + 2000 > Date.now());
                console.log(this.lastClicks.length);
                if (this.lastClicks.length >= 4) {
                    WindowCreator('notification', {
                        title: 'Tip',
                        text: 'Tap on creature you want to attack one time. Your Hero will automatically attack.',
                        center: true,
                    });
                }

                if (phaserScene.target === this) {
                    phaserScene.target.markSelected(false);
                    phaserScene.target = undefined;
                    Container.getNetworkSender().sendTarget(null);
                } else {
                    if (phaserScene.target !== undefined) {
                        phaserScene.target.markSelected(false);
                    }

                    this.markSelected(true);
                    Container.getNetworkSender().sendTarget(this.getId());
                    phaserScene.target = this;
                }
            });
        }

        this.container.setDepth(onTop ? 6 : 5);
        this.overlay.setDepth(10007);
        this.below.setDepth(3);

        this.updateHealth(health, maxHealth, false);

        /** ANIMATIONS */

        if (!this.idleAnimation) {
            this.sprite.anims.play('creature-' + outfitId + this.direction + 'idle');
        } else {
            this.sprite.anims.play('creature-' + outfitId + this.direction);
        }
    }

    public getId(): number {
        return this.id;
    }

    public getPositionX(): number {
        return this.x;
    }

    public getPositionY(): number {
        return this.y;
    }

    public getPixelsX(): number {
        return this.container.x;
    }

    public getPixelsY(): number {
        return this.container.y;
    }

    public getType(): CreatureType {
        return this.type;
    }

    public getWalkTime(): CreatureType {
        return this.walkTime;
    }

    public markSelected(state: boolean): void {
        if (!state && this.below !== undefined && this.below.getAt(0)) {
            this.below.removeAt(0);
            return;
        }

        if (state && this.below !== undefined) {
            this.below.addAt(
                this.phaserScene.add.sprite(0, 0, 'target').setOrigin(0, 0),
                0
            );
        }
    }

    public updateOutfit(outfitId: string): void {
        this.outfitId = outfitId;
        this.sprite.setTexture('creature-' + outfitId);

        if (!this.idleAnimation) {
            this.sprite.anims.play('creature-' + outfitId + this.direction + 'idle');
        } else {
            this.sprite.anims.play('creature-' + outfitId + this.direction);
        }
    }

    public updateWalkTime(walkTime: number): void {
        this.walkTime = walkTime;
    }

    public updateName(name: string): void {
        // @ts-ignore
        this.overlay.getAt(0).setText(name);
    }

    public teleport(x: number, y: number): void {
        this.x = x;
        this.y = y;

        //this.updateDepth();
        this.playMoveAnimation('down');

        this.container.setPosition(this.x * 32, this.y * 32);
        this.overlay.setPosition(this.x * 32, this.y * 32);
        this.below.setPosition(this.x * 32, this.y * 32);
    };

    public directionMove(direction: number): void {
        let directionString: string = this.directionToString(direction);
        switch (direction) {
            case 2:
                this.y += 1;
                break;
            case 8:
                this.y -= 1;
                break;
            case 4:
                this.x -= 1;
                break;
            case 6:
                this.x += 1;
                break;
        }

        this.playMoveAnimation(directionString);
    };

    public directionChange(direction: number): void {
        let directionString: string = this.directionToString(direction);

        // this.playMoveAnimation(directionString);

        if (!this.idleAnimation) {
            this.sprite.anims.play('creature-' + this.outfitId + directionString + 'idle');
        } else {
            this.sprite.anims.play('creature-' + this.outfitId + directionString);
        }
    };

    public directionToString(direction: number): string {
        switch (direction) {
            case 2:
            default:
                return 'down';
            case 8:
                return 'up';
            case 4:
                return 'left';
            case 6:
                return 'right';
        }
    }

    public show(): void {
        this.container.setVisible(true);
        this.overlay.setVisible(true);
        this.below.setVisible(true);
    }

    public hide(): void {
        this.container.setVisible(false);
        this.overlay.setVisible(false);
        this.below.setVisible(false);
    }

    public remove(): void {
        const destroyChildren = (child: Phaser.GameObjects.Sprite) => {
            child.destroy();
        };

        this.overlay.each(destroyChildren);
        this.container.each(destroyChildren);
        this.below.each(destroyChildren);

        // this.sprite.destroy();
        this.container.destroy();
        this.overlay.destroy();
        this.below.destroy();
    }

    public updateHealth(health: number, maxHealth: number, isMyPlayer: boolean = false): void {
        // @ts-ignore
        if (this.type === CreatureType.TYPE_NPC || this.type === CreatureType.TYPE_HIDDEN_PLAYER || this.type === CreatureType.TYPE_HIDDEN_MONSTER ) {
            return;
        }

        let healthFill = this.overlay.getAt(3) as Phaser.GameObjects.Rectangle;

        if (this.type === CreatureType.TYPE_PLAYER) {
            const color: string = Creature.healthToColor(health, maxHealth);
            const colorNumber: number = Creature.hexStringToNumber(color);

            if (isMyPlayer) {
                let nameText = this.overlay.getAt(0) as Phaser.GameObjects.Text;
                nameText.setColor(color);
            }

            healthFill.setFillStyle(colorNumber);
        }

        let fillPercent = health/maxHealth;
        healthFill.setSize(30 * fillPercent, healthFill.height);
    }

    private updateDepth(): void {
        this.container.setDepth(10 + this.y);
        this.below.setDepth(10 + this.y);
    }

    private playMoveAnimation(direction: string): void {
        if (this.isMyPlayer) {
            PlaySound(SoundEffect.FOOTSTEPS, true);
        }

        this.updateDepth();

        let shouldPlay = true;
        if (this.sprite.anims.isPlaying && direction === this.direction && this.nextAnimationFrame !== undefined) {
            this.sprite.anims.setCurrentFrame(this.nextAnimationFrame);
            this.nextAnimationFrame = undefined;
            shouldPlay = false;
        }

        if (shouldPlay || direction !== this.direction) {
            this.sprite.anims.play('creature-' + this.outfitId + direction);
        }

        this.direction = direction;

        this.lastMoveTween = this.phaserScene.tweens.add({
            targets: this.container,
            x: this.x * 32,
            y: this.y * 32,
            duration: this.walkTime,
            onComplete: (tween) => {
                if (!this.idleAnimation && tween === this.lastMoveTween) {
                    if (this.isMyPlayer) {
                        setTimeout(() => {
                            if (tween !== this.lastMoveTween) {
                                return;
                            }

                            StopSound(SoundEffect.FOOTSTEPS);
                        }, 200);
                    }

                    if (this.sprite !== undefined && this.sprite.anims !== undefined) {
                        // @ts-ignore
                        this.sprite.anims.stop();
                        this.playIdleAnimation();
                    }
                }
            },

        });
        this.phaserScene.tweens.add({
            targets: this.overlay,
            x: this.x * 32,
            y: this.y * 32,
            duration: this.walkTime,
        });
        this.phaserScene.tweens.add({
            targets: this.below,
            x: this.x * 32,
            y: this.y * 32,
            duration: this.walkTime,
        });
    }

    private playIdleAnimation(): void {
        this.sprite.anims.play('creature-' + this.outfitId + this.direction + 'idle');
    }

    public static hexStringToNumber(hexString: string): number {
        return parseInt(hexString.replace(/^#/, ''), 16);
    }

    public static rgbToHex(r: number, g: number, b: number): string {
        return "#" + ((1 << 24) + (r << 16) + (g << 8) + b).toString(16).slice(1);
    }

    private static healthToColor(health: number, maxHealth: number): string {
        let r: number, g: number, b: number = 20;
        if (health === 0) {
            r = 200;
            g = 0;
        } else if (health < maxHealth/2) {
            r = 200;
            g = health/(maxHealth/2) * 200.0;
        } else {
            r = 400 - (health/(maxHealth/2) * 200);
            g = 200;
        }

        return this.rgbToHex(r, g, b);
    }
}