import {store} from "react-easy-state";

type EquipmentStoreType = {
    helmet: {},
    necklace: {},
    armor: {},
    weapon: {},
    shield: {},
    legs: {},
    ring_left: {},
    ring_right: {},
    boots: {},
    gloves: {},
    ammunition: {},
}

const equipmentStore: EquipmentStoreType = store({
    helmet: {},
    necklace: {},
    armor: {},
    weapon: {},
    shield: {},
    legs: {},
    ring_left: {},
    ring_right: {},
    boots: {},
    gloves: {},
    ammunition: {},
});

export default equipmentStore;