import React, {useEffect} from 'react';
import Phaser from 'phaser';
import "reflect-metadata";
import { MainScene } from './scene/mainScene';
import './vars.css';
import Hud from './ui/Hud';
import Particles from "react-tsparticles";
import { loadFull } from "tsparticles";

// @ts-ignore
let scenes: Phaser.Scene[] = [MainScene];

const queryString = window.location.search;
const urlParams = new URLSearchParams(queryString);

const config: Phaser.Types.Core.GameConfig = {
  type: Phaser.WEBGL,
  width: window.innerWidth,
  height: window.innerHeight,
  backgroundColor: '#2a2a55',
  parent: 'game-content',
  scale: {
    mode: Phaser.Scale.RESIZE,
    autoCenter: Phaser.Scale.CENTER_BOTH
  },
  input: {
    windowEvents: false
  },
  render: {
    pixelArt: true,
    roundPixels: true
  },
  scene: scenes,
};

function App() {
  const particlesInit = async (main) => {
    await loadFull(main);
  };

  const particlesLoaded = async (container) => {
    // @ts-ignore
    window.particlesContainer = container;
  }

  const game = new Phaser.Game(config);

  return <>
    <div id="game-content" />
    <Hud/>
    <Particles
        id="tsparticles"
        init={particlesInit}
        loaded={particlesLoaded}

        options={{
          fullScreen: {
            enable: true
          },
          particles: {
            number: {
              value: 0
            },
            color: {
              value: ["#ff9224", "#ff8000", "#ff4200", "#c46000"]
            },
            shape: {
              type: "star",
            },
            opacity: {
              value: 1,
              animation: {
                enable: true,
                minimumValue: 0,
                speed: 2,
                startValue: "max",
                destroy: "min"
              }
            },
            size: {
              value: 7,
              random: {
                enable: true,
                minimumValue: 3
              }
            },
            life: {
              duration: {
                sync: true,
                value: 5
              },
              count: 1
            },
            move: {
              enable: true,
              gravity: {
                enable: true,
                acceleration: 15,
              },
              speed: 70,
              decay: {
                min: 0.03,
                max: 0.05
              },
              direction: "none",
              outModes: {
                default: "destroy",
                top: "none"
              }
            }
          },
          interactivity: {
            detectsOn: "window",
            events: {
              resize: true
            }
          },
          detectRetina: true,
          responsive: [
            {
              maxWidth: 700,
              options: {
                particles: {
                  move: {
                    speed: 40,
                    decay: 0.05
                  }
                }
              }
            }
          ],
          emitters: [
            {
              autoPlay: false,
              name: "leftEmitter",
              direction: "top-right",
              rate: {
                delay: 0.1,
                quantity: 20
              },
              position: {
                x: 0,
                y: 25
              },
              size: {
                width: 0,
                height: 0
              }
            },
            {
              autoPlay: false,
              name: "rightEmitter",
              direction: "top-left",
              rate: {
                delay: 0.1,
                quantity: 20
              },
              position: {
                x: 100,
                y: 25
              },
              size: {
                width: 0,
                height: 0
              }
            }
          ]
        }}
    />
  </>
  ;
}

export default App;
