import * as React from "react";
import {view} from "react-easy-state";
import Window, {WindowProps, WindowStyle} from "./Window";
import LevelUpBonusChooser from "../component/LevelUpBonusChooser/LevelUpBonusChooser";
import styles from './Window.module.scss'
import {useEffect} from "react";
import {Confetti} from "../../util/Confetti";
import CountdownStyled from "../atom/CountdownStyled/CountdownStyled";
import {PlaySound} from "../../util/PlaySound";
import {SoundEffect} from "../../service/soundService";

const LevelUpWindow = ({children, windowState}: WindowProps) => {
    useEffect(() => {
        Confetti();
        PlaySound(SoundEffect.BONUS);
    }, []);

    return (
        <Window title={'<i class="bi bi-megaphone-fill"></i> Level Up'} windowState={windowState} windowStyle={WindowStyle.STYLE_NORMAL}>

            <h2 style={{textAlign: 'center'}}>{"You received 3 Statistic Points to distribute. You can do that anytime in Main Menu -> Statistics"}</h2>

            <hr/>

            <LevelUpBonusChooser/>

            <div className={styles.centerHorizontal}>
                <h2><CountdownStyled counterPrefix={'You are safe from being attacked for:'} seconds={10} completedText={'Time\'s up. You\'re vulnerable to attacks again!'}/></h2>
            </div>

        </Window>
    );
};

export default view(LevelUpWindow);