import statisticsStore from "../ui/store/StatisticsStore";
import {ItemTypesToTitle} from "./ItemTypeToTitle";
import * as React from "react";

const abilities = require('../config/clientSpells.json');

type Ability = {
    "id": number,
    "parentId": number,
    "name": string,
    "description": string,
    "parentIds": number[],
    "archetype": string,
    "requiredWeaponTypes": number[],
    "requiredStrength": number,
    "requiredMagic": number,
    "requiredAgility": number,
    "cooldown": number,
    "manaConsumption": number
}

type AbilityNodeData = {
    id: number,
    label: string,
    color: string,
    chosen: boolean,
}

type AbilityNodeEdge = {
    from: number,
    to: number,
    color: string,
    chosen: boolean,
    width: number
}

export const AbilitiesForGraphVizParsed = () => {
    return {
        nodes: getNodesData(),
        edges: getNodesEdges()
    }
}

export const AvailableAbilities = (): number[] => {
    const availableIds: number[] = [];

    Object.keys(abilities).forEach((key, index) => {
        const ability: Ability = abilities[key]
        if (!isAbilityActive(ability)) {
            return
        }

        availableIds.push(ability.id);
    });

    return availableIds;
}

const getAbilityText = (ability: Ability): string => {
    let requiredStats = '';
    let requiredWeapons = '';

    if (ability.requiredWeaponTypes.length) {
        requiredWeapons += "\nReq. Weapon:\n<i>" + ItemTypesToTitle(ability.requiredWeaponTypes) + "</i>";
    } else {
        requiredWeapons += "\n<i>No Weapon Required</i>";
    }

    if (ability.requiredStrength > 10) {
        requiredStats += "\n<i>Req. Strength: " + ability.requiredStrength + "</i>";
    }
    if (ability.requiredAgility > 10) {
        requiredStats += "\n<i>Req. Agility: " + ability.requiredAgility + "</i>";
    }
    if (ability.requiredMagic > 10) {
        requiredStats += "\n<i>Req. Magic: " + ability.requiredMagic + "</i>";
    }

    return "<b>" + ability.name + "</b>" + requiredStats + "\n\n" + ability.description + "\n\n<i>Mana usage: " + ability.manaConsumption + "</i>" + "\n" + requiredWeapons;
}

const getAbilityColor = (ability: Ability): string => {
    if (!isAbilityActive(ability)) {
        return '#888888'
    }

    switch (ability.archetype) {
        case 'warrior':
            return '#F23B00';
        case 'rogue':
            return '#ff8918';
        case 'mage':
            return '#00AAFF';
        default:
            return '#36bd00';
    }
}

const isAbilityActive = (ability: Ability) => {
    if (statisticsStore.strength < ability.requiredStrength) {
        return false;
    }

    if (statisticsStore.magicPower < ability.requiredMagic) {
        return false;
    }

    if (statisticsStore.agility < ability.requiredAgility) {
        return false;
    }

    return true;
}

const getNodesData = (): AbilityNodeData[] => {
    const nodes: AbilityNodeData[] = [];

    Object.keys(abilities).forEach((key, index) => {
        nodes.push(getNodeData(abilities[key]));
    });

    return nodes;
}

const getNodeData = (ability: Ability): AbilityNodeData => {
    return {
        id: ability.id,
        label: getAbilityText(ability),
        color: getAbilityColor(ability),
        chosen: isAbilityActive(ability),
    };
}

const getNodesEdges = (): AbilityNodeEdge[] => {
    const edges: AbilityNodeEdge[] = [];

    Object.keys(abilities).forEach((key, index) => {
        const nodeEdge = getNodeEdge(abilities[key]);

        if (nodeEdge === null) {
            return;
        }

        edges.push(nodeEdge);
    });

    return edges;
}

const getNodeEdge = (ability: Ability): AbilityNodeEdge|null => {
    if (ability.parentId === null) {
        return null;
    }

    return {
        from: ability.parentId,
        to: ability.id,
        color: "#ff0000",
        chosen: isAbilityActive(ability),
        width: isAbilityActive(ability) ? 2 : 1
    };
}