import {Creature, CreatureType} from "../model/creature";
import {MainScene} from "../scene/mainScene";
import {LightService} from "./lightService";
import Container from "../di/container";
import MAX_SAFE_INTEGER = Phaser.Math.MAX_SAFE_INTEGER;

export class CreatureService {
    private readonly creatureMap: Creature[] = [];
    private readonly spriteData = require('../config/spriteData.json');

    private readonly scene: MainScene;
    private readonly lightService: LightService;

    constructor(mainScene: MainScene, lightService: LightService) {
        this.scene = mainScene;
        this.lightService = lightService;
    }

    public addCreature(
        id: number,
        x: number, y: number,
        name: string,
        type: CreatureType,
        health: number, maxHealth: number,
        walkTime: number,
        outfitId: string,
        direction: number = 2
    ): void {
        if (this.creatureMap[id] !== undefined) {
            this.removeCreature(id);
        }

        this.creatureMap[id] = new Creature(
            this.scene,
            id, x, y, name, type,
            health, maxHealth,
            walkTime, outfitId, direction,
            this.spriteData[outfitId]['idleAnimation'],
            this.spriteData[outfitId]['frameWidth'],
            this.spriteData[outfitId]['frameHeight']
        );

        if (type === CreatureType.TYPE_PLAYER || type === CreatureType.TYPE_HIDDEN_PLAYER) {
            this.lightService.addDynamicLight(id, x, y, 1.0);
        }
    }

    public getCreature(id: number): Creature|undefined {
        return this.creatureMap[id] || undefined;
    }

    public getCreatureByPosition(x: number, y: number): Creature|undefined {
        let creatureId: number|undefined = undefined;
        // @ts-ignore
        for (let key in this.creatureMap) {
            // @ts-ignore
            if (this.creatureMap[key].getPositionX() === x && this.creatureMap[key].getPositionY() === y) {
                // @ts-ignore
                creatureId = key;
            }
        }

        if (creatureId !== undefined) {
            return this.creatureMap[creatureId];
        }

        return undefined;
    }

    public updateOutfit(id: number, outfitId: string): void {
        this.creatureMap[id].updateOutfit(outfitId);
    }

    public updateWalkTime(id: number, walkTime: number): void {
        this.creatureMap[id].updateWalkTime(walkTime);
    }

    public teleport(id: number, x: number, y: number, direction: number = 2): void {
        if (this.creatureMap[id] === undefined) {
            return;
        }

        this.creatureMap[id].teleport(x, y);

        if (this.creatureMap[id].getType() == CreatureType.TYPE_PLAYER) {
            this.lightService.teleportDynamicLight(id, x, y);
        }
    }

    public directionMove(id: number, direction: number): void {
        if (this.creatureMap[id] === undefined) {
            console.log('Undefined creature move');
            return;
        }

        this.creatureMap[id].directionMove(direction);

        if (this.creatureMap[id].getType() == CreatureType.TYPE_PLAYER) {
            this.lightService.moveDynamicLight(id, this.creatureMap[id].getPositionX(), this.creatureMap[id].getPositionY(), this.creatureMap[id].getWalkTime());
        }
    }

    public directionChange(id: number, direction: number): void {
        if (this.creatureMap[id] === undefined) {
            console.log('Undefined creature direction');
            return;
        }

        this.creatureMap[id].directionChange(direction);
    }

    public showAll(): void {
        for (let key in this.creatureMap) {
            // @ts-ignore
            if (key < 0) {
                continue;
            }

            this.creatureMap[key].show();

            if (this.creatureMap[key].getType() == CreatureType.TYPE_PLAYER) {
                // @ts-ignore
                this.lightService.enableDynamicLight(key);
            }
        }
    }

    public hideAll(hidePlayer: boolean = false): void {
        for (let key in this.creatureMap) {
            // @ts-ignore
            if (key < 0) {
                continue;
            }

            // @ts-ignore
            if (!hidePlayer && key == this.scene.myPlayerId) {
                continue;
            }

            this.creatureMap[key].hide();

            if (this.creatureMap[key].getType() == CreatureType.TYPE_PLAYER) {
                // @ts-ignore
                this.lightService.disableDynamicLight(key);
            }
        }
    }

    public removeCreature(id: number): void {
        if (!this.creatureMap[id]) {
            return;
        }

        if (this.creatureMap[id].getType() == CreatureType.TYPE_PLAYER || this.creatureMap[id].getType() === CreatureType.TYPE_HIDDEN_PLAYER) {
            this.lightService.removeDynamicLight(id);
        }

        this.creatureMap[id].remove();
        delete this.creatureMap[id];
    };

    public removeAll(includeMyPlayer: boolean): void {
        for (let key in this.creatureMap) {
            // @ts-ignore
            if (key < 0) {
                continue;
            }

            // if (!includeMyPlayer && this.creatureMap[key] !== undefined && this.creatureMap[key].getId() === this.scene.myPlayerId) {
            //     return;
            // }

            // @ts-ignore
            this.removeCreature(key);
        }
    }

    public updateHealth(id: number, health: number, maxHealth: number): void {
        this.creatureMap[id].updateHealth(health, maxHealth, id === this.scene.myPlayerId);
    }

    public autoTarget(): void {
        const player = this.scene.getMyPlayer();
        if (!player) {
            return
        }

        const x: number = player.getPositionX();
        const y: number = player.getPositionY();

        const creatureToDistance = new Map<string, number>();
        for (let key in this.creatureMap) {
            // @ts-ignore
            if (key < 0) {
                continue;
            }

            // @ts-ignore
            if (key == this.scene.myPlayerId) {
                continue;
            }

            const creature = this.creatureMap[key];

            if (creature.getType() !== CreatureType.TYPE_MONSTER) {
                continue;
            }

            const a = x - creature.getPositionX();
            const b = y - creature.getPositionY();
            const distance = Math.sqrt( a*a + b*b );

            creatureToDistance.set(key, distance);
        }

        let closestDistance: number = MAX_SAFE_INTEGER;
        let closestKey: string|null = null;

        // @ts-ignore
        for (const [creatureId, distance] of creatureToDistance) {
            if (distance < closestDistance) {
                closestKey = creatureId;
                closestDistance = distance;
            }
        }

        if (closestKey === null) {
            return;
        }

        if (this.scene.target !== undefined) {
            this.scene.target.markSelected(false);
        }

        this.creatureMap[closestKey].markSelected(true);
        Container.getNetworkSender().sendTarget(this.creatureMap[closestKey].getId());
        this.scene.target = this.creatureMap[closestKey];
    }

    public preloadSpritesheets(): void {
        Object.keys(this.spriteData).forEach((outfitId) => {
            this.preloadSpritesheet(outfitId)
        });
    }

    public preloadAnimations(): void {
        Object.keys(this.spriteData).forEach((outfitId) => {
            this.createAnimation('creature-' + outfitId, outfitId)
        });
    }

    public preloadMisc(): void {
        this.scene.load.image('target', 'assets/images/target.png');
    }

    private preloadSpritesheet(outfitId: string): void {
        const spriteName = 'creature-' + outfitId;
        const frameWidth = this.spriteData[outfitId]['frameWidth'];
        const frameHeight = this.spriteData[outfitId]['frameHeight'];

        this.scene.load.spritesheet(
            spriteName, 'assets/sprites/' + outfitId + '.png',
            { frameWidth: frameWidth, frameHeight: frameHeight }
        );
    }

    private createAnimation(spriteName: string, outfitId: string): void {
        const directions = this.spriteData[outfitId]['directions'];
        const idleFrames = this.spriteData[outfitId]['idleFrames'] ? this.spriteData[outfitId]['idleFrames'] : [];
        const frameRate = this.spriteData[outfitId]['frameRate'] ? this.spriteData[outfitId]['frameRate'] : 10;

        Object.entries(directions).forEach(([direction, frames]) => {
            this.scene.anims.create({
                key: spriteName + direction,
                frames: this.scene.anims.generateFrameNumbers(spriteName, { frames: frames as number[] }),
                frameRate: frameRate,
                repeat: -1,
                skipMissedFrames: false,
            });
        });

        Object.entries(idleFrames).forEach(([direction, frame]) => {
            this.scene.anims.create({
                key: spriteName + direction + 'idle',
                frames: this.scene.anims.generateFrameNumbers(spriteName, { frames: [frame as number] }),
                frameRate: 1,
                repeat: -1,
                skipMissedFrames: false,
            });
        });
    }
}