import * as React from "react";
import styles from './CutsceneText.module.scss'
import {view} from "react-easy-state";
import cutsceneStore from "../../store/CutsceneStore";

const CutsceneText = () => {
    return (
        <>
            {cutsceneStore.text.length > 0 &&
            <div className={styles.wrapper}>
                <span className={styles.text}>{cutsceneStore.text}</span>
            </div>
            }
        </>
    );
};

export default view(CutsceneText);