import * as React from "react";
import {view} from "react-easy-state";
import Window, {WindowProps, WindowStyle} from "./Window";
import Button, {ButtonType} from "../atom/Button/Button";
import ButtonContainer from "../atom/ButtonContainer/ButtonContainer";
import TextContainer from "../atom/TextContainer/TextContainer";
import RangeSlider, {RangeSliderProps} from "../atom/RangeSlider/RangeSlider";
import {ReactElement} from "react";

export type YesNoWindowProps = WindowProps & {
    title: string,
    text: string,
    yesCallback: () => void,
    noCallback: () => void,
    rangeSlider?: ReactElement<RangeSliderProps>|null
}

const YesNoWindow = ({title, text, yesCallback, noCallback, rangeSlider = null, children, windowState}: YesNoWindowProps) => {
    return (
        <Window title={'<i class="bi bi-question-circle-fill"></i> ' + title} windowState={windowState} windowStyle={WindowStyle.STYLE_NARROW_FOCUS}>
            <TextContainer text={text}/>
            {rangeSlider !== null && rangeSlider}
            <ButtonContainer>
                <Button text={'Yes'} type={ButtonType.TYPE_YES} click={() => {windowState.closeHandler(); yesCallback()}}/>
                <Button text={'No'} type={ButtonType.TYPE_NO} click={() => {windowState.closeHandler(); noCallback()}}/>
            </ButtonContainer>
        </Window>
    );
};

export default view(YesNoWindow);