import * as React from "react";
import {view} from "react-easy-state";
import Window, {WindowProps, WindowStyle} from "./Window";
import npcLogStore from "../store/NpcLogStore";
import Accordion from "../component/Accordion/Accordion";

const NpcLogWindow = ({children, windowState}: WindowProps) => {
    return (
        <Window title={'<i class="bi bi-book-half"></i> Npc Log'} windowState={windowState} windowStyle={WindowStyle.STYLE_SCROLL_NARROW}>
            {!!Object.keys(npcLogStore.npcs).length &&
            <Accordion>
                {
                    Object.keys(npcLogStore.npcs).map((npc) => {
                        return <ul key={npc} data-title={npc}>
                            {npcLogStore.npcs[npc].map((text, index) => {
                                return <li key={index}>{text}</li>
                            })}
                        </ul>
                    })
                }
            </Accordion>
            }
            {!Object.keys(npcLogStore.npcs).length &&
            <h2 style={{textAlign: "center", color: "#222222"}}>You do not know anyone yet</h2>
            }
        </Window>
    );
};

export default view(NpcLogWindow);