import * as React from "react";
import styles from './VerticalBanner.module.scss'
import {view} from "react-easy-state";
import Sprite from "../Sprite/Sprite";
import Button, {ButtonType} from "../Button/Button";

export type VerticalBannerProps = {
    title: string,
    active: boolean,
    text: string,
    buttonText: string,
    buttonAction?: () => void,
    shadow?: boolean,
    backgroundClass: string,
    imageClass?: string,
    itemCaption?: string,
};

const VerticalBanner = ({title, active, text, buttonText, buttonAction, shadow, backgroundClass, imageClass, itemCaption}: VerticalBannerProps) => {
    return (
        <div className={`${styles.wrapper} ${active ? styles['active'] : styles['inactive']} ${styles[backgroundClass]} ${shadow ? styles['shadow'] : ''}`}>
            <div className={styles.title}>{title}</div>
            {!!imageClass &&
            <>
                <div className={styles.image}>
                    <span className={`${styles.prize} ${styles[imageClass]}`}/>
                </div>
                <div className={styles.caption}>
                    {itemCaption}
                </div>
            </>
            }
            <Button text={buttonText} click={buttonAction !== undefined ? buttonAction() : null} type={ButtonType.TYPE_ACCEPT} isActive={active}/>
            <div className={styles.text}>{text}</div>
        </div>
    );
}

export default view(VerticalBanner);