import {ColorByMessageType} from "../util/ColorByType";
import {MainScene} from "../scene/mainScene";
import {Creature} from "../model/creature";
const clientItems = require('../config/clientItems.json');

export class EffectService {
    private readonly KARMA_EFFECT_IDS = [
        21, 22, 23, 24, 25, 26
    ];

    private readonly spritePrefix: string = 'effect-';

    private readonly scene: Phaser.Scene;
    private readonly effectData =  require('../config/effectData.json');

    constructor(mainScene: MainScene) {
        this.scene = mainScene;
    }

    public createEffect(effectId: number, x: number, y: number): void {
        const sprite = this.scene.add.sprite(x * 32, y * 32, 'effects', 0).setOrigin(0, 0).setDepth(7);

        sprite.setDepth(10 + y);
        sprite.anims.play(this.spritePrefix + effectId, false);
        sprite.once('animationcomplete', () => {
            sprite.destroy()
        });
    }


    public createDirectedEffect(effectId: number, fromX: number, fromY: number, toX: number, toY: number): void {
        const rotation = Math.atan2(toY - fromY, toX - fromX) - 1.570796;

        const destX = fromX + (0.5 * Math.cos(rotation + 1.57079633));
        const destY = fromY + (0.5 * Math.sin(rotation + 1.57079633));

        const sprite = this.scene.add.sprite(destX * 32 + 16, destY * 32 + 16, 'effects', 0).setOrigin(0.5, 0.5).setDepth(7);

        sprite.setRotation(rotation);
        sprite.setDepth(10 + toY);
        sprite.anims.play(this.spritePrefix + effectId, false);
        sprite.once('animationcomplete', () => {
            sprite.destroy()
        });
    }

    public createShootEffect(effectId: number, fromX: number, fromY: number, toX: number, toY: number): void {
        const sprite = this.scene.add.sprite(fromX * 32 + 16, fromY * 32 + 16, 'effects', 0).setOrigin(0.5, 0.5).setDepth(7);

        if (this.effectData[effectId]['rotate']) {
            EffectService.applyRotation(sprite, fromX, fromY, toX, toY);
        }

        sprite.setDepth(10 + toY);
        sprite.anims.play(this.spritePrefix + effectId, false);

        let duration = Math.sqrt( (fromX - toX)*(fromX - toX) + (fromY - toY)*(fromY - toY) ) * 75.0;

        if (this.KARMA_EFFECT_IDS.includes(effectId)) {
            this.scene.tweens.add({
                targets: sprite,
                props: {
                    x: {
                        duration: duration * 2,
                        value: toX * 32 + 16,
                        ease: 'Back.easeOut'
                    },
                    y: {
                        duration: duration * 2,
                        value: toY * 32 + 16,
                        ease: 'Quad.easeOut'
                    }
                },
                onComplete: function () {
                    sprite.destroy()
                }
            });
        } else {
            this.scene.tweens.add({
                targets: sprite,
                x: toX * 32 + 16,
                y: toY * 32 + 16,
                duration: Math.sqrt( (fromX - toX)*(fromX - toX) + (fromY - toY)*(fromY - toY) ) * 75.0,
                onComplete: function () {
                    sprite.destroy()
                }
            });
        }
    }

    public createItemEffect(itemIds: number[], x: number, y: number): void {
        let xPos = x * 32 + 24 - itemIds.length * 8;
        itemIds.forEach((itemId) => {
            const sprite = this.scene.add.sprite(xPos, y * 32 + 16, 'sprites-all', clientItems[itemId]['spriteId'])
                .setOrigin(0.5, 0.5)
                .setDepth(10 + y);

            this.scene.tweens.add({
                targets: sprite,
                x: xPos,
                y: y * 32 - 32,
                alpha: 0.1,
                duration: 4000,
                ease: 'Sine.easeOut',
                onComplete: function () {
                    sprite.destroy()
                }
            });

            xPos += 16;
        });
    }

    public createTextEffect(text: string, color: number, x: number, y: number, duration: number = 750, large: boolean = false): void {
        const animText = this.scene.add.text(x * 32 + 16, y * 32, text)
            .setFontFamily('Khmer-Nettra')
            .setResolution(1.5)
            .setFontStyle(large ? 'bold' : '')
            .setFontSize(large ? 22 : 13)
            .setColor(ColorByMessageType(color))
            .setStroke('#000000', 2.5)
            .setOrigin(0.5, 0)
            .setAlign('center')
            .setDepth(10008);

        this.scene.tweens.add({
            targets: animText,
            y: animText.y - 30,
            alpha: 0.66,
            duration: duration,
            onComplete: function () {
                animText.destroy();
            }
        });
    }

    public createBarEffect(from: number, to: number, max: number, time: number, color: number, x: number, y: number): void {
        let barInnerWidth = 30;
        let fromFillWidth = from/max * barInnerWidth;
        let toFillWidth = to/max * barInnerWidth;

        let background = this.scene.add.rectangle(x*32, y*32, 32, 4, 0x000000).setAlpha(1.0).setOrigin(0, 0).setDepth(10007);
        let backgroundDecoration = this.scene.add.rectangle(x*32-1, y*32+1, 34, 2, 0x000000).setAlpha(1.0).setOrigin(0, 0).setDepth(10007);
        let fill = this.scene.add.rectangle(x*32+1, y*32+1, fromFillWidth, 2, Creature.hexStringToNumber(ColorByMessageType(color))).setAlpha(1.0).setOrigin(0, 0).setDepth(10007);

        this.scene.tweens.add({
            targets: fill,
            width: toFillWidth,
            duration: time,
            ease: 'Sine.easeOut',
            onComplete: function () {
                background.destroy();
                backgroundDecoration.destroy();
                fill.destroy();
            }
        });
    }

    public createStaticText(text: string, color: number, x: number, y: number, duration: number = 750): void {
        const animText = this.scene.add.text(x * 32 + 16, y * 32, text)
            .setFontFamily('Khmer-Nettra')
            .setFontStyle('italic')
            .setResolution(3)
            .setFontSize(18)
            .setColor(ColorByMessageType(color))
            .setStroke('#000000', 2.5)
            .setBackgroundColor('#543402')
            .setPadding(20, 10, 20, 10)
            .setOrigin(0.5, 0)
            .setAlign('center')
            .setDepth(10008);

        window.setTimeout(() => animText.destroy(), duration)
    }

    public preloadAnimations(): void {
        Object.keys(this.effectData).forEach((effectId) => {
            this.createAnimation(Number(effectId))
        });
    }

    private createAnimation(effectId: number): void {
        this.scene.anims.create({
            key: this.spritePrefix + effectId,
            frames: this.scene.anims.generateFrameNumbers('effects', { start: this.effectData[effectId]['startFrame'], end: this.effectData[effectId]['endFrame'] }),
            frameRate: this.effectData[effectId]['frameRate'],
            repeat: this.effectData[effectId]['repeat'],
            skipMissedFrames: false,
        });
    }

    private static applyRotation(sprite: Phaser.GameObjects.Sprite, fromX: number, fromY: number, toX: number, toY: number): void {
        sprite.setRotation(Math.atan2(toY - fromY, toX - fromX) - 1.570796);
    }
}