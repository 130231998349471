import Container from "../di/container";
import {SoundEffect, SoundType} from "../service/soundService";

export const PlaySound = (soundEffect: SoundType, loop: boolean = false, initialVolume: number = 1.0): void => {
    Container.getSoundService().play(soundEffect, loop, initialVolume);
    console.log('Sound: ' + soundEffect);
}

export const PlayMapSound = (soundEffect: SoundType, soundX: number, soundY: number): void => {
    const scene = Container.getMainScene();
    const minX = (scene.myPlayer?.getPositionX() ?? 0) - (scene.cameras.main.displayWidth * 0.6 / 32.0);
    const maxX = (scene.myPlayer?.getPositionX() ?? 0) + (scene.cameras.main.displayWidth * 0.6 / 32.0);
    const minY = (scene.myPlayer?.getPositionY() ?? 0) - (scene.cameras.main.displayHeight * 0.6 / 32.0);
    const maxY = (scene.myPlayer?.getPositionY() ?? 0) + (scene.cameras.main.displayHeight * 0.6 / 32.0);

    if (soundX >= minX && soundX <= maxX && soundY >= minY && soundY <= maxY) {
        const a = (scene.myPlayer?.getPositionX() ?? 0) - soundX;
        const b = (scene.myPlayer?.getPositionY() ?? 0) - soundY;
        const distance = Math.sqrt(a*a + b*b);

        const volume = 1 / (distance < 1 ? 1 : distance);
        console.log('map volume: ', volume, a, b);

        PlaySound(soundEffect, false, volume);
    }
}
