import * as React from "react";
import styles from './TabbedWindows.module.scss'
import {view} from "react-easy-state";
import {useState} from "react";

export type TabbedWindowsProps = {
    children: React.ReactNode,
    activeTab: string
};

const TabbedWindows = (props: TabbedWindowsProps) => {
    const [activeTab, setActiveTab] = useState<string>(props.activeTab);

    const click = (label: string) => {
        setActiveTab(label);
    }

    return (
        <div className={styles.wrapper}>
            <div className={styles.tabs}>
                {
                    // @ts-ignore
                    !!props.children.length && props.children.map((child: ReactNode, index: number) => {
                        return <div className={`${styles.tab} ${child.props.label === activeTab ? styles.selected : ''} ${child.props.isActive ? '' : styles.inactive}`}
                                    key={child.props.label}
                                    style={{color: child.props.color}}
                                    onClick={() => {if (child.props.isActive) click(child.props.label);}}
                        >
                            {child.props.icon &&
                            <div className={styles.iconWrapper}>
                                {child.props.icon}
                            </div>
                            }

                            {child.props.label}
                        </div>
                    })
                }
            </div>
            <div className={styles.content}>
                {
                    // @ts-ignore
                    !!props.children.length && props.children.map((child: ReactNode, index: number) => {
                        if (child.props.label === activeTab) {
                            return child.props.children;
                        }

                        return undefined;
                    })
                }
            </div>
        </div>
    );
}

export default view(TabbedWindows);