import {LoginStoreType} from "../ui/store/LoginStore";
import {RegisterStoreType} from "../ui/store/RegisterStore";

export const ValidateRegister = (store: RegisterStoreType| LoginStoreType): boolean => {
    let result = true;
    store.invalidFieldsToMessage = {};

    if (store.name.length < 3) {
        store.invalidFieldsToMessage['name'] = 'Hero Name is too short.';
        result = false;
    }

    if (store.name.length > 20) {
        store.invalidFieldsToMessage['name'] = 'Hero Name is too long.';
        result = false;
    }

    if (store.password !== store.passwordRepeat) {
        store.invalidFieldsToMessage['password'] = 'Passwords does not match.';
        result = false;
    }

    if (store.password.length < 8) {
        store.invalidFieldsToMessage['password'] = 'Password is too short.';
        result = false;
    }

    if (store.password.length > 30) {
        store.invalidFieldsToMessage['password'] = 'Password is too long.';
        result = false;
    }

    return result;
}