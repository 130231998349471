import * as React from "react";
import {view} from "react-easy-state";
import Window, {WindowProps, WindowStyle} from "./Window";
import Button, {ButtonType} from "../atom/Button/Button";
import ButtonContainer from "../atom/ButtonContainer/ButtonContainer";
import ShortcutContainer from "../component/ShortcutContainer/ShortcutContainer";
import shortcutsStore, {isShortcutsHolding} from "../store/ShortcutsStore";
import ItemContainer from "../component/ItemContainer/ItemContainer";
import inventoryStore from "../store/InventoryStore";
import {ServerMessageCreator} from "../service/ServerMessageCreator";
import {ShortcutsQuantityRefresher} from "../service/ShortcutsQuantityRefresher";
import {ResetSelectedItems} from "../service/ResetSelectedItems";
import SkillContainer from "../component/SkillContainer/SkillContainer";
import Container from "../../di/container";
import styles from "./ChooseShortcutWindow.module.scss";
import {AvailableAbilities} from "../../util/AbilitiesForGraphVizParser";

const ChooseShortcutWindow = ({children, windowState}: WindowProps) => {
    return (
        <Window title={'<i class="bi bi-pin-angle-fill"></i> Choose Shortcut'} windowState={windowState} windowStyle={WindowStyle.STYLE_WIDE}>
            <div className={styles.wrapper}>
                <div className={styles.itemsShortcutWrapper}>
                    <div className={styles.shortcutWrapper}>
                        <ShortcutContainer
                            shortcut={shortcutsStore.shortcuts[shortcutsStore.currentlyChoosing]}
                            slot={shortcutsStore.currentlyChoosing}
                            interactive={false}
                        />
                        <Button text={'Clear'} type={ButtonType.TYPE_CANCEL} click={() => {
                            Container.getNetworkSender().sendRemoveShortcut(shortcutsStore.currentlyChoosing);
                            shortcutsStore.shortcuts[shortcutsStore.currentlyChoosing] = null;
                        }}/>
                    </div>
                    <ItemContainer
                        title={'Inventory Items that may have a Shortcut'}
                        items={inventoryStore.items}
                        canRender={(item) => {
                            return item['usable'] !== undefined && item['usable'] === true;
                        }}
                        isSelectable={true}
                        isSelectableMultiple={false}
                        onSelect={(selectedPositions, selectedIds, item) => {
                            // @ts-ignore
                            if (!item.usable) {
                                ServerMessageCreator(1, 'Item is not usable.');
                                ResetSelectedItems();
                                return;
                            }

                            if (isShortcutsHolding(selectedIds[0], false)) {
                                ServerMessageCreator(1, 'There is existing shortcut for this Item.');
                                ResetSelectedItems();
                                return;
                            }

                            Container.getNetworkSender().sendAddShortcut(shortcutsStore.currentlyChoosing, selectedIds[0]);

                            shortcutsStore.shortcuts[shortcutsStore.currentlyChoosing] = {
                                id: selectedIds[0],
                                quantity: 0,
                                cooldown: null,
                                cooldownEndTimestamp: null,
                            };
                            ShortcutsQuantityRefresher();
                        }}
                    />
                </div>
                <SkillContainer
                    title={'Abilities available for this Shortcut'}
                    skills={AvailableAbilities()}
                    onSelect={(selectedPosition, selectedId) => {
                        if (isShortcutsHolding(selectedId, true)) {
                            ServerMessageCreator(1, 'There is existing shortcut for this Skill.');
                            ResetSelectedItems();
                            return;
                        }

                        Container.getNetworkSender().sendAddShortcut(shortcutsStore.currentlyChoosing, selectedId);

                        shortcutsStore.shortcuts[shortcutsStore.currentlyChoosing] = {
                            id: selectedId,
                            cooldown: null,
                            cooldownEndTimestamp: null,
                        };
                        ShortcutsQuantityRefresher();
                    }}
                />
            </div>
        </Window>
    );
};

export default view(ChooseShortcutWindow);