import * as React from "react";
import styles from './Table.module.scss'
import {view} from "react-easy-state";

export type TableProps = {
    columns: {name: string, selector: string}[],
    data: {}[],
};

const Table = (props: TableProps) => {
    return (
        <table style={{width: '100%', textAlign: 'center'}}>
            <thead>
                <tr>
                    {props.columns.map((column, index) => {
                        return <th key={index}>{column.name}</th>
                    })}
                </tr>
            </thead>
            <tbody>
                {props.data.map((data, index) => {
                    return <tr key={index}>
                        {props.columns.map((column, indexCol) => {
                            if (column.selector === 'index') {
                                return <td key={indexCol}>{index + 1}</td>
                            }

                            return <td key={indexCol}>{data[column.selector]}</td>
                        })}
                    </tr>
                })}
            </tbody>
        </table>
    );
}

export default view(Table);