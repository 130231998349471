import React, {useEffect, useState} from 'react';
import Notification, {NotificationType, NotificationData} from "../../atom/Notification/Notification";
import { store, view } from 'react-easy-state';
import {UniqueId} from "../../../util/UniqueId";
import styles from "./NotificationManager.module.scss";
import {PlaySound} from "../../../util/PlaySound";
import {SoundEffect} from "../../../service/soundService";

type notificationsStateType = {
    notifications: NotificationData[],
    topNotifications: NotificationData[],
}

const notificationsState: notificationsStateType = store({
    notifications: [],
    topNotifications: [],
});

const NotificationManager = view((props: any) => {
    useEffect(() => {
        window.addEventListener('notification-create', (event) => {
            // @ts-ignore
            createNotification(event.detail.isTop, event.detail.type, event.detail.title, event.detail.text, event.detail.expiration, event.detail.items);
            event.stopImmediatePropagation();
        });
        return () => (undefined);
    }, []);

    useEffect(() => {
    }, [props]);

    const createNotification = (isTop: boolean, type: NotificationType, title: string, text: string, expiration: number = 5000, items: object[]) => {
        let index = UniqueId();
        let notificationData: NotificationData = {
            type: type,
            title: title,
            text: text,
            expiration: expiration,
            items: items,
            index: index,
            notificationsLeft: 0,
        };

        PlaySound(SoundEffect.NOTIFICATION);

        if (isTop) {
            notificationsState.topNotifications.push(notificationData);
            if (notificationsState.topNotifications.length === 1) {
                setTimeout(() => {
                    removeFirstTopNotification();
                }, 15000);
            }
        } else {
            notificationsState.notifications.push(notificationData);
            if (notificationsState.notifications.length === 1) {
                setTimeout(() => {
                    removeFirstNotification();
                }, 5000);
            }
        }
    }

    const removeFirstNotification = () => {
        notificationsState.notifications.shift();

        if (notificationsState.notifications.length >= 1) {
            setTimeout(() => {
                removeFirstNotification();
            }, 5000);
        }
    }

    const removeFirstTopNotification = () => {
        notificationsState.topNotifications.shift();

        if (notificationsState.topNotifications.length >= 1) {
            setTimeout(() => {
                removeFirstTopNotification();
            }, 15000);
        }
    }

    return (
        <>
            <div className={styles.notificationsContainer} key={'nm1'}>
                {
                    !!notificationsState.notifications.length &&
                    <Notification
                        key={notificationsState.notifications[0].index}
                        {...notificationsState.notifications[0]}
                        notificationsLeft={notificationsState.notifications.length - 1}
                    />
                }
            </div>
            <div className={styles.topNotificationsContainer} key={'nm2'}>
                {
                    !!notificationsState.topNotifications.length &&
                    <div className={styles.topNotification}
                        key={notificationsState.topNotifications[0].index}
                    >
                        <div className={styles.title} dangerouslySetInnerHTML={{__html: notificationsState.topNotifications[0].title}}/>
                        <div className={styles.text} dangerouslySetInnerHTML={{__html: notificationsState.topNotifications[0].text}}/>
                    </div>
                }
            </div>
        </>
    );
});

export default NotificationManager;