import * as React from "react";
import Window, {WindowProps, WindowStyle} from "./Window";
import {view} from "react-easy-state";
import styles from './Window.module.scss'
import TabbedWindows from "../component/TabbedWindows/TabbedWindows";
import Tab from "../component/TabbedWindows/Tab";
import leaderboardStore from "../store/LeaderboardStore";
import Table from "../component/Table/Table";

const LeaderboardWindow = ({children, windowState}: WindowProps) => {

    const getFirstTabLabel = (): string => {
        return '';
    }

    const getColumns = () => {
        return [
            {name: 'Rank', selector: 'index'},
            {name: 'Hero', selector: 'name'},
            {name: 'Value', selector: 'value'},
        ];
    }

    const drawTable = (type: string, values: []) => {
        return <Tab isActive={true} label={type} key={type}>
            <h2 className={styles.centerHorizontal}>{type}</h2>

            <Table columns={getColumns()} data={values}/>

            {/*<table style={{width: '100%', textAlign: 'center'}}>*/}
            {/*    <thead>*/}
            {/*        <tr>*/}
            {/*            <th>Rank</th>*/}
            {/*            <th>Hero</th>*/}
            {/*            <th>Value</th>*/}
            {/*        </tr>*/}
            {/*    </thead>*/}
            {/*    <tbody>*/}
            {/*    {values.map((value: {name: string, value: string}, index) => {*/}
            {/*        return <tr key={index}>*/}
            {/*            <td>{index + 1}</td>*/}
            {/*            <td>{value.name}</td>*/}
            {/*            <td>{value.value}</td>*/}
            {/*        </tr>*/}
            {/*    })}*/}
            {/*    </tbody>*/}
            {/*</table>*/}
        </Tab>
    }

    const getTabs = () => {
        let tabs: any[] = [];
        tabs = tabs.concat(Object.entries(leaderboardStore.leaderboard).map(([type, values], index) => {
            return drawTable(type, values as []);
        }));
        tabs = tabs.concat(Object.entries(leaderboardStore.highscores).map(([type, values], index) => {
            return drawTable(type, values as []);
        }));

        return tabs;
    }

    return (
        <Window title={'<i class="bi bi-list-ol"></i> Leaderboard'} windowState={windowState} windowStyle={WindowStyle.STYLE_SCROLL}>
            <TabbedWindows activeTab={getFirstTabLabel()}>
                {getTabs()}
            </TabbedWindows>
        </Window>
    );
};

export default view(LeaderboardWindow);