import * as React from "react";
import styles from './Sprite.module.scss'

const clientItems = require('../../../config/clientItems.json');

export type SpriteProps = {
    id: number;
};

const Sprite = ({id}: SpriteProps) => {
    let spriteId: number = clientItems[id]['spriteId'];

    const getOffsetX = (): number => {
        return (spriteId) % 32;
    }

    const getOffsetY = (): number => {
        return Math.floor(spriteId / 32);
    }

    const px = getOffsetX() / 31*100;
    const py = getOffsetY() / 21*100;

    return (
        <span className={styles.sprite}
             style={{
                 backgroundPosition: px + "% " + py + '%'
             }}
        />
    );
}

export default Sprite;