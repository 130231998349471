import * as React from "react";
import styles from './Accordion.module.scss'
import {view} from "react-easy-state";
import {HorizontalGroupProps} from "../../atom/Input/HorizontalGroup";

export type AccordionProps = {
    children: React.ReactNode,
};

const Accordion = (props: AccordionProps) => {
    return (
        <div className={styles.wrapper}>
            {
                // @ts-ignore
                props.children.map((child: React.ReactNode, index: number) => {
                    const tabName = 'tab-' + index + 'r';
                    // @ts-ignore
                    const title = child.props['data-title'];
                    // @ts-ignore
                    const titleIcon = child.props['data-title-icon'] ?? null;
                    // @ts-ignore
                    const color = child.props['data-color'] ?? 'white';
                    return <div className={styles.tab} key={index}>
                        <input id={tabName} type="radio" name="tabr"/>
                        <label htmlFor={tabName} style={{color: color}}>
                            <div className={styles.iconWrapper}>
                                {titleIcon}
                            </div>
                            {title}
                        </label>
                        <div className={styles.content}>
                            {child}
                        </div>
                    </div>
                })
            }
        </div>
    );
}

export default view(Accordion);