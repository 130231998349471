import {store} from "react-easy-state";

type DropPickupStoreType = {
    items: any[],
    x: number,
    y: number,
    uniqueId: number|null,
    selectedPositions: number[],
}

const dropPickupStore: DropPickupStoreType = store({
    items: [],
    x: 0,
    y: 0,
    uniqueId: 0,
    selectedPositions: [],
});

export default dropPickupStore;