import * as React from "react";
import styles from './EquipmentContainer.module.scss'
import Item from "../../atom/Item/Item";
import equipmentStore from "../../store/EquipmentStore";
import {view} from "react-easy-state";
import {useEffect, useState} from "react";
import {ResetSelectedItems} from "../../service/ResetSelectedItems";

export enum EQUIPMENT_SLOT {
    HELMET= 0,
    NECKLACE= 1,
    ARMOR= 2,
    WEAPON= 3,
    SHIELD= 4,
    LEGS= 5,
    RING_LEFT= 6,
    RING_RIGHT= 7,
    BOOTS= 8,
    GLOVES= 9,
}

type EquipmentContainerProps = {
    onSelect?: (selectedPositionId: number|null, selectedPositionName: string|null, item: {}) => void,
};

const EquipmentContainer = ({onSelect = () => {}}: EquipmentContainerProps) => {
    const [selectedPosition, setSelectedPosition] = useState<number|null>(null);

    const slotNamesToPlaceholder = {
        'necklace': 35,
        'helmet': 5,
        'top_right': -1,
        'weapon': 14,
        'armor': 2,
        'shield': 28,
        'ring_left': 31,
        'legs': 26,
        'ring_right': 31,
        'gloves': 38,
        'boots': 23,
    };

    useEffect(() => {
        window.addEventListener('reset-selected-items', (event) => {
            setSelectedPosition(null);
        });
    }, []);

    const onClickOnItem = (selected: boolean, position: number, item: {}) => {
        const realPosition = selected ? position : null;

        ResetSelectedItems();

        setSelectedPosition(realPosition);
        onSelect(realPosition, realPosition ? EQUIPMENT_SLOT[position].toLowerCase() : null, item);
    }

    return (
        <div className={styles.equipmentWrapper}>
            <div className={styles.equipmentContainer}>
                {Object.keys(slotNamesToPlaceholder).map((slotName, index) => {
                    const item = equipmentStore[slotName] ?? {};

                    if (slotNamesToPlaceholder[slotName] === -1) {
                        return <div key={index}></div>
                    }

                    if (Object.keys(item).length < 1) {
                        return <Item
                            key={index}
                            position={EQUIPMENT_SLOT[slotName.toUpperCase()]}
                            details={{}}
                            // @ts-ignore
                            id={slotNamesToPlaceholder[slotName]}
                            // @ts-ignore
                            quantity={0}
                            isSelectable={false}
                            isSelected={false}
                            onClick={ () => {} }
                        />
                    }

                    return <Item
                        key={index}
                        position={EQUIPMENT_SLOT[slotName.toUpperCase()]}
                        details={item}
                        // @ts-ignore
                        id={item.id}
                        // @ts-ignore
                        quantity={item.quantity}
                        isSelectable={true}
                        isSelected={selectedPosition === EQUIPMENT_SLOT[slotName.toUpperCase()]}
                        onClick={onClickOnItem}
                    />
                })}
            </div>
        </div>
    );
}

export default view(EquipmentContainer);