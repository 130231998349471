import {store} from "react-easy-state";

export type Boost = {
    type: string,
    amount: number,
    endTimestamp: number,
}

type BoostStoreType = {
    boosts: Boost[]
}

const boostStore: BoostStoreType = store({
    boosts: [],
});

export const BoostAdd = (type: string, amount: number, endTimestamp: number) => {
    boostStore.boosts.push({
        type: type,
        amount: amount,
        endTimestamp: endTimestamp,
    });

    window.setTimeout(() => {
        boostStore.boosts = boostStore.boosts.filter((boost) => {
            return boost.type != type || boost.amount != amount || boost.endTimestamp != endTimestamp
        })
    }, RemainingSeconds(endTimestamp) * 1000);
}

export const RemainingSeconds = (endTimestamp: number) => {
    return endTimestamp - Math.floor(Date.now() / 1000);
}

export const BoostTypeToTitle = (type: string): string => {
    switch (type) {
        case 'experience': return 'Experience Gain Modifier';
        case 'damage': return 'Damage Dealt Modifier';
        case 'defence': return 'Defence Effectiveness Modifier';
        case 'loot': return 'Loot Chance Modifier';
        case 'crafting': return 'Successful Crafting Modifier';
        case 'mana': return 'Mana Regeneration Modifier';
        case 'critical': return 'Critical Hit Chance Modifier';
        case 'regeneration': return 'Regeneration Speed Modifier';
        case 'boost_time': return 'Effects Time Modifier';
        case 'walking_speed': return 'Walking Speed Modifier';
    }

    return '';
}

export default boostStore;