import React, {useEffect} from 'react';
import { store, view } from 'react-easy-state';
import npcStore from "../../store/NpcStore";
import InfoAction from "../../component/InfoActionContainer/InfoAction";
import styles from "./NpcDialogueManager.module.scss";
import TypedP from "../../atom/TypedP/TypedP";
import Container from "../../../di/container";
import {SoundEffect} from "../../../service/soundService";
import {PlaySound} from "../../../util/PlaySound";

const NpcDialogueManager = (props: any) => {
    if (npcStore.visible) {
        // @ts-ignore
        window.isUiOpen = true;
    } else {
        // @ts-ignore
        window.isUiOpen = false;
    }

    const dialogues = npcStore.dialogues;
    const responses = npcStore.responses;

    useEffect(() => {
        window.addEventListener('window-creator', (event) => {
            // @ts-ignore
            switch (event.detail.name) {
                case 'npc-dialogue':
                    npcStore.visible = true;
                    npcStore.state = 0;
                    break;
            }
        });
        window.addEventListener('window-closer', (event) => {
            // @ts-ignore
            switch (event.detail.name) {
                case 'npc-dialogue':
                    npcStore.visible = false;
                    npcStore.state = 0;
                    break;
            }
        });
        return () => (undefined);
    }, []);

    useEffect(() => {
    }, [props]);

    const onClickOnResponse = (responseId: number) => {
        PlaySound(SoundEffect.CLICK);
        Container.getNetworkSender().sendNpcDialogueChoice(responseId);
    }

    return (
        <>
            {npcStore.visible &&
            <>
                {// @ts-ignore
                    dialogues.length && npcStore.state < 1 &&
                <InfoAction
                    title={npcStore.title}
                    image={npcStore.image}
                    hideNext={true}
                    clickToNext={true}
                    stepCallback={() => {
                        if (!npcStore.forceDisplay) {
                            npcStore.forceDisplay = true;
                            return false;
                        }

                        npcStore.forceDisplay = false;
                        return true;
                    }}
                    callback={() => {
                        npcStore.state = 1;

                        if (responses === undefined || (responses && Object.keys(responses).length === 0)) {
                            npcStore.clear();
                            onClickOnResponse(0);
                        }
                    }}
                >
                    {
                        dialogues.map((dialogue, index) => {
                            return <TypedP
                                key={index}
                                text={dialogue}
                                forceDisplay={npcStore.forceDisplay}
                                onComplete={() => {
                                    npcStore.forceDisplay = true;
                                }}
                            />;
                        })
                    }
                </InfoAction>
                }
                {(!dialogues.length || npcStore.state >= 1) &&
                <InfoAction
                    hideNext={true}
                    clickToNext={false}
                    callback={() => {}}
                >
                    <div className={styles.responsesWrapper}>
                        {responses && Object.keys(responses).map(
                            (key, index) => {
                                // @ts-ignore
                                return <p className={styles.response} style={{textCenter: true}} key={key} onClick={() => {onClickOnResponse(key)}} dangerouslySetInnerHTML={{
                                    // @ts-ignore
                                    __html: '→ ' + responses[key]
                                }}></p>
                            })
                        }
                    </div>
                </InfoAction>
                }
            </>
            }
        </>
    );
};

export default view(NpcDialogueManager);