import statisticsStore from "../ui/store/StatisticsStore";

const spells = require('../config/clientSpells.json');

export const CanUseSKill = (id: number): boolean => {
    if (statisticsStore.mana - (spells[id]['manaConsumption'] ?? 0) >= 0) {
        return true;
    }

    return false;
}