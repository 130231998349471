import * as React from "react";
import styles from './TextContainer.module.scss'

export type TextContainerProps = {
    text: string,
    centered?: boolean,
    background?: boolean,
    overflowHidden?: boolean,
    large?: boolean,
    children?: React.ReactNode,
};

const TextContainer = ({ text, centered = false, background = false, overflowHidden = false, large = false, children = [] }: TextContainerProps) => {
    return (
        <div className={`${styles.textContainer} ${centered ? styles.centered : ''} ${overflowHidden ? styles.overflowHidden : ''}`}>
            <span className={`${styles.text} ${large ? styles.large : ''} ${background ? styles.background : ''}`} dangerouslySetInnerHTML={{__html: text}}>
                {/*{text}*/}
            </span>
            {children}
        </div>
    );
};

export default TextContainer;