import * as React from "react";
import {TextInputProps} from "./TextInput";
import AbstractInput from "./AbstractInput";
import {view} from "react-easy-state";

const PasswordInput = ({ type = 'password', name, onUpdate, label = undefined, value = '', error = undefined, placeholder = '', minLength = 0, maxLength = 999999, required = false }: TextInputProps) => {
    return (
        <AbstractInput onUpdate={onUpdate} name={name} label={label} value={value} error={error} properties={{
            type: type,
            label: label,
            placeholder: placeholder,
            minLength: minLength,
            maxLength: maxLength,
            required: required
        }}/>
    );
};

export default view(PasswordInput);