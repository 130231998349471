import shortcutsStore from "../store/ShortcutsStore";
import inventoryStore from "../store/InventoryStore";

export const ShortcutsQuantityRefresher = (): void => {
    Object.keys(shortcutsStore.shortcuts).forEach((slot) => {
        if (shortcutsStore.shortcuts[slot] === null) {
            return;
        }

        if (!('quantity' in shortcutsStore.shortcuts[slot])) {
            return;
        }

        const itemId = shortcutsStore.shortcuts[slot].id;
        let quantity = 0;
        inventoryStore.items.forEach((item) => {
            if (item.id === itemId) {
                quantity += item.quantity;
            }
        });

        shortcutsStore.shortcuts[slot].quantity = quantity;
    });
}