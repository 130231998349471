import {store} from "react-easy-state";

type InventoryStoreType = {
    items: any[],
    gold: number,
}

const inventoryStore: InventoryStoreType = store({
    items: [],
    gold: 0,
});

export default inventoryStore;

export const getQuantityOfItemId = (itemId: number): number => {
    let quantity = 0;
    inventoryStore.items.forEach((item) => {
        if (item.id !== itemId) {
            return;
        }

        quantity += item.quantity;
    });

    return quantity;
}