import * as React from "react";
import styles from './CraftingRow.module.scss'
import ButtonContainer from "../../atom/ButtonContainer/ButtonContainer";
import Button, {ButtonType} from "../../atom/Button/Button";
import Container from "../../../di/container";
import craftingStore from "../../store/CraftingStore";
import {getQuantityOfItemId} from "../../store/InventoryStore";
import ItemRow from "../ItemRow/ItemRow";
import {ItemPriceType} from "../../atom/Item/Item";
import {view} from "react-easy-state";

type CraftingRowProps = {
    requiredItems: any[],
    resultItems: any[],
    canFailCreation: boolean,
    craftKey: number,
    itemId?: number|null,
}

const CraftingRow = ({requiredItems, resultItems, canFailCreation, craftKey, itemId = null}: CraftingRowProps) => {
    const canCraft = (): boolean => {
        let craftableNumber = 0;

        requiredItems.map((item) => {
            // @ts-ignore
            if (getQuantityOfItemId(item.id) >= item.quantity) {
                craftableNumber++;
            }
        });

        return craftableNumber >= requiredItems.length;
    }

    const requiredItemRow = (item, indexBase: string = '') => {
        const inventoryQty = getQuantityOfItemId(item.id);

        return <ItemRow
            // @ts-ignore
            key={indexBase + item.id}
            // @ts-ignore
            position={item.id}
            details={item}
            // @ts-ignore
            id={item.id}
            // @ts-ignore
            price={item.quantity}
            // @ts-ignore
            priceType={inventoryQty >= item.quantity ? ItemPriceType.TYPE_GREEN : ItemPriceType.TYPE_RED}
            quantity={inventoryQty}
            forceShowQuantity={true}
            isSelectable={false}
        />
    }

    const resultItemRow = (item, indexBase: string = '') => {
        return <ItemRow
            // @ts-ignore
            key={indexBase + item.id}
            // @ts-ignore
            position={item.id}
            details={item}
            // @ts-ignore
            id={item.id}
            // @ts-ignore
            quantity={1}
            isSelectable={false}
        />
    }

    return (
        <>
            <div key={craftKey} className={styles.displayHorizontal}>
                <div className={styles.centerHorizontal}>
                    <h4>Required</h4>
                    <div>
                        {
                            requiredItems.map((item, indexNested) => {
                                return requiredItemRow(item);
                            })
                        }
                    </div>
                </div>
                <div className={styles.centerHorizontal}>
                    <h4>Results</h4>
                    {resultItems.map((item, indexNested) => {
                        // @ts-ignore
                        return <div key={indexNested + '-res-s-' + item.id}>
                            {indexNested > 0 &&
                            // @ts-ignore
                            <small>or</small>
                            }
                            {resultItemRow(item)}
                        </div>
                    })}
                    {canFailCreation &&
                    <>
                        <small>or</small>
                        <h3>Fail and destroy Items</h3>
                    </>
                    }
                </div>
            </div>
            <ButtonContainer key={'d' + craftKey}>
                <Button text={'Craft'} type={ButtonType.TYPE_ACCEPT} isActive={canCraft()} click={() => {
                    Container.getNetworkSender().sendCraftProceed(craftingStore.x, craftingStore.y, craftKey, itemId);
                }}/>
            </ButtonContainer>
        </>
    )
}

export default view(CraftingRow);