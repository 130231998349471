import * as React from "react";
import {view} from "react-easy-state";
import Window, {WindowProps, WindowStyle} from "./Window";
import Button, {ButtonType} from "../atom/Button/Button";
import ButtonContainer from "../atom/ButtonContainer/ButtonContainer";
import npcStore from "../store/NpcStore";
import TextContainer from "../atom/TextContainer/TextContainer";
import DialogueResponseContainer from "../component/DialogueResponseContainer/DialogueResponseContainer";

const NpcDialogueWindow = ({children, windowState}: WindowProps) => {
    const dialogues = npcStore.dialogues;
    const responses = npcStore.responses;

    return (
        <Window title={'🧍 ' + npcStore.title} windowState={windowState} windowStyle={WindowStyle.STYLE_NARROW}>
            <TextContainer text={dialogues.join("\n")} centered={false}/>
            <hr/>
            <DialogueResponseContainer responses={responses} centered={true}/>
        </Window>
    );
};

export default view(NpcDialogueWindow);