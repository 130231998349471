import * as React from "react";
import {view} from "react-easy-state";
import Window, {WindowProps, WindowStyle} from "./Window";
import Carousel from "../component/Carousel/Carousel";
import VerticalBanner from "../atom/VerticalBanner/VerticalBanner";
import dailyRewardStore from "../store/DailyRewardStore";
import Container from "../../di/container";
import styles from './Window.module.scss'
import {Confetti} from "../../util/Confetti";
import {PlaySound} from "../../util/PlaySound";
import {SoundEffect} from "../../service/soundService";

const DailyRewardWindow = ({children, windowState}: WindowProps) => {
    const generateHeights = (): number[] => {
        const days: number[] = [];
        for (let i = 1; i <= 5; i++) {
            days.push(isActiveDay(i) ? 100 : 90);
        }
        return days;
    }

    const isActiveDay = (day: number): boolean => {
        return dailyRewardStore.strike === day && canClaim();
    }

    const canClaim = (): boolean => {
        const now = new Date();
        const nextClaim = dailyRewardStore.claimedAt.getTime() + 1000 * 60 * 60 * 24;
        return nextClaim < now.getTime();
    }

    const generateTimeLeft = (timestamp: number): string => {
        const dateFuture = new Date();
        dateFuture.setTime(timestamp);
        const dateNow = new Date();

        let seconds = Math.floor((dateFuture.getTime() - (dateNow.getTime()))/1000);
        let minutes = Math.floor(seconds/60);
        let hours = Math.floor(minutes/60);
        let days = Math.floor(hours/24);

        hours = hours-(days*24);
        minutes = minutes-(days*24*60)-(hours*60);
        seconds = seconds-(days*24*60*60)-(hours*60*60)-(minutes*60);

        if (days >= 1) {
            return days + 'd ' + hours + 'h';
        }

        return hours + 'h ' + minutes + 'm';
    }

    const generateBackgroundClass = (day: number): string => {
        if (dailyRewardStore.strike > day) {
            return 'taken';
        }

        if (dailyRewardStore.strike === day) {
            if (!canClaim()) {
                return 'future'
            } else {
                return 'active'
            }
        }

        return 'future';
    }

    const generateButtonText = (day: number): string => {
        if (dailyRewardStore.strike > day) {
            return 'Claimed';
        }

        if (dailyRewardStore.strike === day) {
            if (canClaim()) {
                return 'Claim now!'
            }
        }

        if (canClaim()) {
            return (day - dailyRewardStore.strike) + ' Day(s)';
        }

        return generateTimeLeft(dailyRewardStore.claimedAt.getTime() + 24*60*60*1000*(day - dailyRewardStore.strike + 1));
    }

    const claimClicked = (): object => {
        return () => {
            Container.getNetworkSender().sendDailyRewardClaim();
            Confetti();
            PlaySound(SoundEffect.BONUS);
        };
    }

    return (
        <Window title={'<i class="bi bi-award-fill"></i> Daily Reward'} windowState={windowState} windowStyle={WindowStyle.STYLE_DAILY_REWARD}>

            <Carousel height={generateHeights()}>
                <VerticalBanner active={isActiveDay(1)} title={'Day 1'} buttonText={generateButtonText(1)} buttonAction={claimClicked} backgroundClass={generateBackgroundClass(1)} imageClass={'exp'} itemCaption={'25%\nExperience Boost'} text={'Active for 30 minutes'} shadow={isActiveDay(1)}/>
                <VerticalBanner active={isActiveDay(2)} title={'Day 2'} buttonText={generateButtonText(2)} buttonAction={claimClicked} backgroundClass={generateBackgroundClass(2)} imageClass={'damage'} itemCaption={'15%\nDamage Boost'} text={'Active for 30 minutes'} shadow={isActiveDay(2)}/>
                <VerticalBanner active={isActiveDay(3)} title={'Day 3'} buttonText={generateButtonText(3)} buttonAction={claimClicked} backgroundClass={generateBackgroundClass(3)} imageClass={'loot'} itemCaption={'20%\nLoot Boost'} text={'Active for 30 minutes'} shadow={isActiveDay(3)}/>
                <VerticalBanner active={isActiveDay(4)} title={'Day 4'} buttonText={generateButtonText(4)} buttonAction={claimClicked} backgroundClass={generateBackgroundClass(4)} imageClass={'defence'} itemCaption={'20%\nDefence Boost'} text={'Active for 30 minutes'} shadow={isActiveDay(4)}/>
                <VerticalBanner active={isActiveDay(5)} title={'Day 5'} buttonText={generateButtonText(5)} buttonAction={claimClicked} backgroundClass={generateBackgroundClass(5)} imageClass={'crafting'} itemCaption={'20%\nCrafting Boost'} text={'Active for 30 minutes'} shadow={isActiveDay(5)}/>
            </Carousel>

            {dailyRewardStore.message.length > 0 &&
            <h1 className={styles.centerHorizontal}>{dailyRewardStore.message}</h1>
            }

            {!canClaim() &&
            <h1 className={styles.centerHorizontal}>Next Daily Reward will be available in {generateTimeLeft(dailyRewardStore.claimedAt.getTime())} </h1>
            }

        </Window>
    );
};

export default view(DailyRewardWindow);