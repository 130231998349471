import {store} from "react-easy-state";

export type RegisterStoreType = {
    name: string,
    email: string,
    password: string,
    passwordRepeat: string,
    promo: string,
    isPromoValid: boolean,
    terms: boolean,
    newsletter: boolean,
    invalidFieldsToMessage: {},
}

const registerStore: RegisterStoreType = store({
    name: '',
    email: '',
    password: '',
    passwordRepeat: '',
    promo: '',
    isPromoValid: true,
    terms: false,
    newsletter: false,
    invalidFieldsToMessage: {},
});

export default registerStore;