import {MapContainer} from "../model/mapContainer";
import {MainScene} from "../scene/mainScene";
const clientItems = require('../config/clientItems.json');

export class ContainerService {
    private readonly spritesSpritesheetName: string = 'sprites-all';

    private readonly scene: MainScene;
    private containers: MapContainer[] = [];

    constructor(mainScene: MainScene) {
        this.scene = mainScene;
    }

    public addContainer(ownerId: number|null, x: number, y: number, id: number, chunkId: number): void {
        const sprite = this.scene.add.sprite(x * 32, y * 32, this.spritesSpritesheetName, clientItems[44]['spriteId'])
            .setOrigin(0, 0)
            .setAlpha(0.0)
            .setDepth(2);

        this.scene.tweens.add({
            targets: sprite,
            duration: 500,
            alpha: this.isFullyVisibleContainer(ownerId) ? 1.0 : 0.25
        });

        this.containers.push(new MapContainer(sprite, 44, 1, x, y, chunkId, id));

        return;
    }

    public removeContainerOwner(x: number, y: number, id: number): void {
        this.containers.forEach((container: MapContainer) => {
            if (container.x !== x || container.y !== y || container.id !== id) {
                return;
            }

            container.sprite.setAlpha(1.0);
        });
    }

    public removeContainer(x: number, y: number, id: number): void {
        this.containers = this.containers.filter((container: MapContainer) => {
            if (container.x !== x || container.y !== y || container.id !== id) {
                return true;
            }

            this.scene.tweens.add({
                targets: container.sprite,
                ease: 'Quart.easeIn',
                duration: 250,
                alpha: 0.0,
                onComplete: () => {
                    container.sprite.destroy();
                }
            });

            return false;
        });
    }

    public clearChunk(chunkId: number): void {
        this.containers = this.containers.filter((container: MapContainer) => {
            if (container.chunkId !== chunkId) {
                return true;
            }

            container.sprite.destroy();
            return false;
        });
    }

    private isFullyVisibleContainer(ownerId: number|null): boolean {
        if (ownerId === null) {
            return true;
        }

        return ownerId === this.scene.myPlayerId;
    }
}