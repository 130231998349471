import * as React from "react";
import {view} from "react-easy-state";
import Window, {WindowProps, WindowStyle} from "./Window";
import NpcStorage from "../component/NpcStorage/NpcStorage";

const NpcStorageWindow = ({children, windowState}: WindowProps) => {
    return (
        <Window title={'<i class="bi bi-briefcase-fill"></i> Storage'} windowState={windowState} windowStyle={WindowStyle.STYLE_NORMAL}>
            <NpcStorage/>
        </Window>
    );
};

export default view(NpcStorageWindow);