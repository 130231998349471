import * as React from "react";
import {view} from "react-easy-state";
import styles from './FocusOverlay.module.scss';

type FocusOverlayProps = {
    left?: string|null|undefined,
    top?: string|null|undefined,
    width?: string|null|undefined,
    height?: string|null|undefined,
    callback?: () => void,
}

const FocusOverlay = (props: FocusOverlayProps) => {
    return (
        <div className={`${styles[props.left ? 'hint' : 'overlay']}`} style={{
            left: props.left ?? 0,
            top: props.top ?? 0,
            width: props.width ?? 0,
            height: props.height ?? 0,
        }} onClick={() => {
            if (props.callback) props.callback();
        }}/>
    );
};

export default view(FocusOverlay);