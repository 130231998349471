import {store} from "react-easy-state";

type ReminderStore = {
    actions: [key: string, action: () => void][],
}

const reminderStore: ReminderStore = store({
    actions: [],
});

export const addReminder = (key:string, action: () => void) => {
    if (reminderStore.actions.some( ([sKey, sAction]) => sKey === key)) {
        return;
    }

    reminderStore.actions.push([key, action]);
}

export default reminderStore;