import * as React from "react";
import {useEffect, useState} from "react";
import ItemStatistics from "./manager/ItemStatistics/ItemStatistics";
import NotificationManager from "./manager/NotificationManager/NotificationManager";
import WindowManager from "./manager/WindowManager";
import {WindowCreator} from "./service/WindowCreator";
import {view} from "react-easy-state";
import ServerMessageManager from "./manager/ServerMessageManager/ServerMessageManager";
import ShortcutsManager from "./manager/Shortcuts/ShortcutsManager";
import TopHud from "./component/TopHud/TopHud";
import ActionsPopup from "./manager/ActionsPopup/ActionsPopup";
import LoginScreen from "./overlay/LoginScreen/LoginScreen";
import {LoginState, loginStore} from "./store/LoginStore";
import {TutorialState, tutorialStore} from "./store/TutorialStore";
import TutorialScreen from "./overlay/TutorialScreen/TutorialScreen";
import NpcDialogueManager from "./manager/NpcDialogueManager/NpcDialogueManager";
import HudMenu from "./manager/HudMenu/HudMenu";
import MobileControls from "./component/MobileControls/MobileControls";
import CutsceneText from "./component/CutsceneText/CutsceneText";
import TopBoosts from "./component/TopBoosts/TopBoosts";

const Hud = () => {
    const [visible, setVisible] = useState<boolean>(true);
    const [hudVisible, setHudVisible] = useState<boolean>(true);

    useEffect(() => {
        window.addEventListener('ui-hide', (event) => {
            // @ts-ignore
            setVisible(!event.detail.hidden);
        });
        return () => (undefined);
    }, []);

    useEffect(() => {
        window.addEventListener('hud-hide', (event) => {
            // @ts-ignore
            setHudVisible(!event.detail.hidden);
        });
        return () => (undefined);
    }, []);

    return (
        <>
            {loginStore.state !== LoginState.STATE_HIDDEN &&
                <>
                    <LoginScreen/>
                    <WindowManager/>
                </>
            }
        {visible && loginStore.state === LoginState.STATE_HIDDEN &&
        <div className={"hud"} style={{
            position: 'absolute',
            width: '100%',
            height: '100%',
        }}>
            {hudVisible &&
                <>
                    <TopHud/>
                    <TopBoosts/>
                    <NotificationManager/>
                    <HudMenu/>
                    <MobileControls/>
                    <ShortcutsManager/>
                </>
            }
            <NpcDialogueManager/>
            <WindowManager/>
            <ItemStatistics/>
            <ActionsPopup/>
            <ServerMessageManager/>
            {tutorialStore.state !== TutorialState.STATE_HIDDEN &&
                <TutorialScreen/>
            }
            {/*<NotificationManager/>*/}
        </div>
        }
            <CutsceneText/>
        </>
    );
};

export default view(Hud);