import * as React from "react";
import styles from './InfoAction.module.scss'
import {view} from "react-easy-state";
import {Children, useState} from "react";
import ButtonContainer from "../../atom/ButtonContainer/ButtonContainer";
import Button from "../../atom/Button/Button";
import {StopSound} from "../../../util/StopSound";
import {SoundEffect} from "../../../service/soundService";

export type InfoActionProps = {
    title?: string,
    image?: string,
    isTop?: boolean,
    hideNext?: boolean,
    clickToNext?: boolean,
    stepCallback?: null|(() => boolean),
    callback: () => void,
    children: React.ReactNode,
};

const InfoAction = (props: InfoActionProps) => {
    const [currentIndex, setCurrentIndex] = useState<number>(0);
    const childrenCount = Children.count(props.children);

    const nextInfo = () => {
        if (props.stepCallback !== null && props.stepCallback !== undefined) {
            if (props.stepCallback()) {
                if (childrenCount <= currentIndex + 1) {
                    props.callback();
                    return;
                }

                setCurrentIndex(currentIndex + 1);
            }
        } else {
            if (childrenCount <= currentIndex + 1) {
                props.callback();
                return;
            }

            setCurrentIndex(currentIndex + 1);
        }
    }

    const top = props.isTop === true ? styles['top'] : '';

    return (
        <>
            {childrenCount > currentIndex &&
            <div className={styles.container}>
                <div className={`${styles.wrapperWhole} ${top}`} onClick={() => {
                    StopSound(SoundEffect.DIALOGUE_TYPING);
                    if (props.clickToNext) {
                        nextInfo();
                    }
                }}>
                    <div className={styles.wrapper}>
                        {
                            React.Children.map(props.children, (child, index) => {
                                return index === currentIndex ? child : null;
                            })
                        }
                        {props.hideNext !== true &&
                        <ButtonContainer>
                            <Button text={'Continue'} click={() => {
                                nextInfo()
                            }}/>
                        </ButtonContainer>
                        }
                    </div>
                    {props.image &&
                    <div className={styles.avatar}>
                        <div className={styles.imageWrapper}>
                            <img className={styles.image}
                                 src={"assets/ui/avatar/" + props.image + ".png"}
                            />
                        </div>
                        <p className={styles.name}>{props.title}</p>
                    </div>
                    }
                </div>
            </div>
            }
        </>
    );
}

export default view(InfoAction);