import * as React from "react";
import styles from './MobileControls.module.scss';
import {view} from "react-easy-state";
import Container from "../../../di/container";
import { isMobile } from "react-device-detect";
import {useEffect, useRef} from "react";

const MobileControls = () => {
    const isMobileDevice = isMobile;
    let moveDelay = 20;

    const timer = useRef<number|null>(null);
    const touchStarted = (direction: number) => {
        touchHandler(direction);
    }

    const touchEnded = () => {
        if (timer.current === null) {
            return;
        }

        window.clearTimeout(timer.current);
    }

    const touchHandler = (direction: number) => {
        timer.current = window.setTimeout(touchHandler, moveDelay, direction);

        Container.getNetworkSender().sendPlayerMove(direction);
    }

    return (
        <>
            {isMobileDevice &&
            <div className={styles.wrapper}>
                <div className={`${styles.joyButton} ${styles.left}`} onTouchStart={() => touchStarted(4)} onTouchEnd={() => touchEnded()}>
                    <i className="bi bi-caret-left-fill"/>
                </div>
                <div className={`${styles.joyButton} ${styles.right}`} onTouchStart={() => touchStarted(6)} onTouchEnd={() => touchEnded()}>
                    <i className="bi bi-caret-right-fill"/>
                </div>
                <div className={`${styles.joyButton} ${styles.up}`} onTouchStart={() => touchStarted(8)} onTouchEnd={() => touchEnded()}>
                    <i className="bi bi-caret-up-fill"/>
                </div>
                <div className={`${styles.joyButton} ${styles.down}`} onTouchStart={() => touchStarted(2)} onTouchEnd={() => touchEnded()}>
                    <i className="bi bi-caret-down-fill"/>
                </div>
            </div>
            }
        </>
    );
}

export default view(MobileControls);