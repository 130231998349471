import * as React from "react";
import styles from './DialogueResponseContainer.module.scss';
import {view} from "react-easy-state";
import Container from "../../../di/container";

type DialogueResponseContainerProps = {
    responses: {},
    centered: boolean
};

const DialogueResponseContainer = ({responses = {}, centered}: DialogueResponseContainerProps) => {
    const onClickOnResponse = (responseId: number) => {
        Container.getNetworkSender().sendNpcDialogueChoice(responseId);
    }

    return (
        <React.Fragment>
            <h2 className={styles.centered}>Your Response:</h2>
            <div className={`${styles.responseWrapper} ${centered ? styles.centered : ''}`}>
                {responses && Object.keys(responses).map(
                    key => {
                        // @ts-ignore
                        return <p className={styles.response} key={key} onClick={() => {onClickOnResponse(key)}}>
                            {'• ' + responses[key] + ' •'}
                        </p>
                    })
                }
            </div>
        </React.Fragment>
    );
}

export default view(DialogueResponseContainer);