import * as React from "react";
import styles from './ItemRow.module.scss'
import Item, {ItemPriceType, ItemProps} from "../../atom/Item/Item";

const ItemRow = ({position, details = {}, id, quantity = 1, forceShowQuantity = false, price = null, priceType = ItemPriceType.TYPE_DEFAULT, isSelected = false, isSelectable = false, onClick = (selected: boolean) => {}, onLongClick = null}: ItemProps) => {
    // @ts-ignore
    const name = details.name;
    // @ts-ignore
    const description = details.description;
    // @ts-ignore
    const sellValue = details.sellValue;

    return (
        <div className={styles.wrapper}>
            <Item
                key={position}
                position={position}
                details={details}
                id={id}
                quantity={quantity}
                forceShowQuantity={forceShowQuantity}
                price={price}
                priceType={priceType}
                isSelectable={isSelectable}
                isSelected={isSelected}
                onClick={onClick}
                onLongClick={onLongClick}
            />
            <div className={styles.details}>
                <h3>{name}</h3>
                <p>{description}</p>
                <p>Value: {sellValue} Gold Coin(s)</p>
            </div>
        </div>
    );
}

export default ItemRow;