import {store} from "react-easy-state";

export type ShortcutTypeItem = {
    id: number,
    quantity: number,
    cooldown: null|number,
    cooldownEndTimestamp: null|number,
}

export type ShortcutTypeSkill = {
    id: number,
    cooldown: null|number,
    cooldownEndTimestamp: null|number,
}

export type ShortcutsStoreType = {
    shortcuts: {
        1: ShortcutTypeItem|ShortcutTypeSkill|null,
        2: ShortcutTypeItem|ShortcutTypeSkill|null,
        3: ShortcutTypeItem|ShortcutTypeSkill|null,
        4: ShortcutTypeItem|ShortcutTypeSkill|null,
        5: ShortcutTypeItem|ShortcutTypeSkill|null,
        6: ShortcutTypeItem|ShortcutTypeSkill|null,
    },
    currentlyChoosing: number,
}

const shortcutsStore: ShortcutsStoreType = store({
    shortcuts: {
        1: null,
        2: null,
        3: null,
        4: null,
        5: null,
        6: null,
    },
    currentlyChoosing: 1,
});

export function isShortcutsHolding(id: number, isSkill: boolean = false): boolean {
    let isHolding = false;
    Object.keys(shortcutsStore.shortcuts).forEach((slot) => {
        if (isHolding || shortcutsStore.shortcuts[slot] === null) {
            return;
        }

        if (shortcutsStore.shortcuts[slot].id === id) {
            if (!isSkill && 'quantity' in shortcutsStore.shortcuts[slot]) {
                isHolding = true;
            } else if (isSkill && !('quantity' in shortcutsStore.shortcuts[slot])) {
                isHolding = true;
            }
        }
    });

    return isHolding;
}

export default shortcutsStore;