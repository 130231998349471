import * as React from "react";
import {view} from "react-easy-state";
import styles from './LevelUpBonusChooser.module.scss'
import TapTab from "../TapTab/TapTab";
import levelUpStore from "../../store/LevelUpStore";
import Container from "../../../di/container";
import {levelUpWindowClose} from "../../manager/WindowManager";
import {PlaySound} from "../../../util/PlaySound";
import {SoundEffect} from "../../../service/soundService";

const LevelUpBonusChooser = () => {
    return (
        <>
            <h1 className={styles.title}>
                Pick One Bonus:
            </h1>
            <div className={styles.column}>
                {levelUpStore.boosts.map((boost, index) => {
                    return <TapTab
                        key={index}
                        backgroundColor={boost.color}
                        image={"assets/ui/icon/" + boost.icon + ".png"}
                        title={boost.title}
                        subtitle={boost.subtitle}
                        content={boost.description}
                        footer={'Lasting time: ' + Math.trunc(boost.duration/60) + ' minutes'}
                        onClick={() => {
                            PlaySound(SoundEffect.SPELL_BUFF_END);
                            levelUpStore.active = false;
                            Container.getNetworkSender().sendLevelUpBoostClaim(boost.type);
                            levelUpWindowClose();
                        }}
                        active={levelUpStore.active}
                    />
                })}
            </div>
        </>
    );
}

export default view(LevelUpBonusChooser);