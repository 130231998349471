import * as React from "react";
import styles from './Tabs.module.scss'
import {view} from "react-easy-state";

export type Tab = {
    title: string,
    active: boolean,
    selected: boolean,
    callback: () => void,
}

export type TabsProps = {
    tabs: Tab[]
};

const Tabs = (props: TabsProps) => {
    return (
        <div className={styles.wrapper}>
            {
                props.tabs.map((tab: Tab, index: number) => {
                    return <div className={`${styles.tab} ${tab.active ? styles.active : styles.inactive} ${tab.selected ? styles.selected : ''}`}
                               key={index}
                               onClick={() => {
                                   if (tab.active && !tab.selected) tab.callback();
                               }}
                    >
                        {tab.title}
                    </div>
                })
            }
        </div>
    );
}

export default view(Tabs);