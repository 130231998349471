import * as React from "react";
import styles from './SkillContainer.module.scss'
import {useEffect, useState} from "react";
import {view} from "react-easy-state";
import SkillDescription from "../../atom/SkillDescription/SkillDescription";
import {TutorialState, tutorialStore} from "../../store/TutorialStore";

type SkillContainerProps = {
    title?: string,
    skills?: number[],
    isSelectable?: boolean,
    onSelect?: (position: number, id: number) => void,
};

const SkillContainer = ({title = '', skills = [], isSelectable = true, onSelect = () => {}}: SkillContainerProps) => {
    const [selectedSkillPosition, setSelectedSkillPosition] = useState<number|null>(null);
    const [selectedSkillId, setSelectedSkillId] = useState<number|null>(null);

    useEffect(() => {
        window.addEventListener('reset-selected-items', (event) => {
            setSelectedSkillPosition(null);
        });
    }, []);

    const onClickOnSkill = (selected: boolean, position: number, id: number) => {
        if (!isSelectable) {
            return;
        }

        setSelectedSkillPosition(position);
        setSelectedSkillId(id);

        if (tutorialStore.state === TutorialState.STATE_SETTING_SHORTCUT_SELECT_SKILL_CLICK) {
            tutorialStore.state = TutorialState.STATE_HIDDEN;
        }

        onSelect(position, id);
    }

    return (
        <div className={styles.wrapper}>
            {title &&
            <span className={`${styles.title} ${styles.centered}`}>
                {title}
            </span>
            }
            <div className={styles.container} data-area={'skill-container'}>
                {skills.map((skill: any, index: number) => {
                    return <SkillDescription key={index}
                                 position={index}
                                 id={skill}
                                 isSelectable={isSelectable}
                                 isSelected={selectedSkillPosition === index}
                                 onClick={onClickOnSkill}
                    />;
                })}
            </div>
        </div>
    );
}

export default view(SkillContainer);