import * as React from "react";
import {view} from "react-easy-state";
import Window, {WindowProps, WindowStyle} from "./Window";
import Accordion from "../component/Accordion/Accordion";
import achievementsStore from "../store/AchievementsStore";

const AchievementsWindow = ({children, windowState}: WindowProps) => {
    return (
        <Window title={'<i class="bi bi-trophy-fill"></i> Achievements'} windowState={windowState} windowStyle={WindowStyle.STYLE_SCROLL_NARROW}>
            {!!Object.keys(achievementsStore.achievements).length &&
            <Accordion>
                {
                    Object.keys(achievementsStore.achievements).map((title) => {
                        return <ul key={title} data-title={title}>
                            {achievementsStore.achievements[title]}
                        </ul>
                    })
                }
            </Accordion>
            }
            {!Object.keys(achievementsStore.achievements).length &&
            <h2 style={{textAlign: "center", color: "#222222"}}>You do not have any achievements yet</h2>
            }
        </Window>
    );
};

export default view(AchievementsWindow);