const itemStatisticsFormat = require('../../../config/itemStatisticsFormat.json');

export const FormatItemStatistics = (statistics: object): string[] => {
    const formattedStatistics: string[] = [];
    itemStatisticsFormat.forEach((format: object) => {
        // @ts-ignore
        const {formatted: formatted, key: key, transform: transform, isBoolean: isBoolean, displayMin: displayMin, depends: depends, hidden: hidden, separator: separator, suffix: suffix} = format;

        if (true === separator) {
            formattedStatistics.push('separator');
            return;
        }

        if (null === formatted) {
            return;
        }

        if (true === hidden) {
            return;
        }

        if (depends !== undefined) {
            if (false == statistics[depends]) {
                return;
            }
        }

        let value = statistics[key];

        if (value === undefined || value === 0 || value === false || (value.length === 0 && (value instanceof String || typeof value === 'string'))) {
            return;
        }

        if (transform !== undefined) {
            if (transform[value] == undefined) {
                return;
            }

            value = transform[value];
        }

        if (isBoolean === true) {
            value = '';
        }

        if (displayMin !== undefined) {
            if (value < displayMin) {
                return;
            }
        }

        formattedStatistics.push(formatted + value + (suffix ? suffix : ''));
    });

    return formattedStatistics;
}