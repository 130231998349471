import * as React from "react";
import {view} from "react-easy-state";
import Window, {WindowProps, WindowStyle} from "./Window";
import StatisticsBar, {StatisticsBarType} from "../atom/StatisticsBar/StatisticsBar";
import statisticsStore from "../store/StatisticsStore";
import StatisticsChooser from "../component/StatisticsChooser/StatisticsChooser";

const StatisticsWindow = ({children, windowState}: WindowProps) => {
    return (
        <Window title={'<i class="bi bi-mortarboard-fill"></i> Statistics'} windowState={windowState} windowStyle={WindowStyle.STYLE_NORMAL}>
            <StatisticsBar title={'Level'} barColor={'#FFFFFF'} isDisplayNumber={false} value={statisticsStore.level}/>
            <StatisticsBar title={'Archetype'} barColor={'#FFFFFF'} isDisplayNumber={false} value={statisticsStore.archetype}/>
            <StatisticsBar title={'Health'} barColor={'#F23B00'} type={StatisticsBarType.TYPE_HEALTH} isDisplayNumber={false} value={statisticsStore.health} maxValue={statisticsStore.maxHealth}/>
            <StatisticsBar title={'Mana'} barColor={'#00AAFF'} type={StatisticsBarType.TYPE_MANA} isDisplayNumber={false} value={statisticsStore.mana} maxValue={statisticsStore.maxMana}/>
            <StatisticsBar title={'Experience'} barColor={'#FFC500'} type={StatisticsBarType.TYPE_EXPERIENCE} isDisplayNumber={false} value={statisticsStore.experience} maxValue={statisticsStore.maxExperience}/>
            <StatisticsBar title={'Capacity'} barColor={'#BBBBBB'} type={StatisticsBarType.TYPE_CAPACITY} isDisplayNumber={false} value={statisticsStore.capacity} maxValue={statisticsStore.maxCapacity}/>
            <StatisticsBar title={'Max Damage'} barColor={'#F23B00'} isDisplayNumber={false} value={statisticsStore.maxDamage}/>
            <StatisticsBar title={'Damage Reduction'} barColor={'#64b900'} isDisplayNumber={false} value={statisticsStore.damageReduction}/>
            <StatisticsBar title={'Block Chance'} barColor={'#00AAFF'} isDisplayNumber={false} value={statisticsStore.blockPercent + '%'}/>

            <br/>

            <StatisticsBar title={'Equipment'} isDisplayNumber={false} value={''}/>
            <StatisticsBar title={'Attack'} barColor={'#F23B00'} isDisplayNumber={false} value={statisticsStore.attack}/>
            <StatisticsBar title={'Defence'} barColor={'#64b900'} isDisplayNumber={false} value={statisticsStore.defence}/>

            <StatisticsChooser/>
        </Window>
    );
};

export default view(StatisticsWindow);