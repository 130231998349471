import {store} from "react-easy-state";

type BoostType = {
    amount: number
    color: string,
    description: string,
    duration: number
    icon: string,
    subtitle: string,
    tier: number
    title: string,
    type: string,
}

type LevelUpStoreType = {
    boosts: BoostType[],
    active: boolean,
}

const levelUpStore: LevelUpStoreType = store({
    boosts: [],
    active: true,
});

export default levelUpStore;