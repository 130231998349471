import * as React from "react";
import {view} from "react-easy-state";
import Window, {WindowProps, WindowStyle} from "./Window";
import Button, {ButtonType} from "../atom/Button/Button";
import ButtonContainer from "../atom/ButtonContainer/ButtonContainer";
import TextContainer from "../atom/TextContainer/TextContainer";

export type NotificationWindowProps = WindowProps & {
    title: string,
    text: string,
    center?: boolean,
    closeCallback?: () => void
}

const NotificationWindow = ({title, text, center = true, closeCallback = () => {}, children, windowState}: NotificationWindowProps) => {
    return (
        <Window title={'<i class="bi bi-card-text"></i> ' + title} windowState={windowState} windowStyle={WindowStyle.STYLE_NARROW_FORCE}>
            <TextContainer text={text} centered={center}/>
            <ButtonContainer>
                <Button text={'Close'} type={ButtonType.TYPE_ACCEPT} click={() => {windowState.closeHandler(); closeCallback()}}/>
            </ButtonContainer>
        </Window>
    );
};

export default view(NotificationWindow);