import React, {ReactElement, useEffect} from 'react';
import { store, view } from 'react-easy-state';
import InventoryWindow from "../window/InventoryWindow";
import YesNoWindow from "../window/YesNoWindow";
import MenuWindow from "../window/MenuWindow";
import RegisterWindow from "../window/RegisterWindow";
import EquipmentWindow from "../window/EquipmentWindow";
import DropPickupWindow from "../window/DropPickupWindow";
import NotificationWindow from '../window/NotificationWindow';
import NpcDialogueWindow from "../window/NpcDialogueWindow";
import NpcShopWindow from "../window/NpcShopWindow";
import StatisticsWindow from "../window/StatisticsWindow";
import AbilitiesTreeWindow from "../window/AbilitiesTreeWindow";
import ChatWindow from "../window/ChatWindow";
import ChooseShortcutWindow from "../window/ChooseShortcutWindow";
import CraftingWindow from "../window/CraftingWindow";
import NpcLogWindow from "../window/NpcLogWindow";
import {RangeSliderProps} from "../atom/RangeSlider/RangeSlider";
import NpcStorageWindow from "../window/NpcStorageWindow";
import QuestLogWindow from "../window/QuestLogWindow";
import DailyRewardWindow from "../window/DailyRewardWindow";
import AchievementsWindow from "../window/AchievementsWindow";
import LevelUpWindow from "../window/LevelUpWindow";
import Container from "../../di/container";
import SettingsWindow from "../window/SettingsWindow";
import {CameraZoom} from "../../util/CameraZoom";
import {HudHide} from "../service/HudHide";
import {WindowCreator} from "../service/WindowCreator";
import {addReminder} from "../store/ReminderStore";
import LeaderboardWindow from "../window/LeaderboardWindow";
import CollectionsWindow from "../window/CollectionsWindow";

export type WindowStateType = {
    visible: boolean,
    closeHandler: () => void,
}

export type YesNoWindowStateType = WindowStateType & {
    title: string,
    text: string,
    yesCallback: () => void,
    noCallback: () => void,
    rangeSlider: ReactElement<RangeSliderProps>|null,
}

export type NotificationWindowStateType = WindowStateType & {
    title: string,
    text: string,
    center: boolean,
    closeCallback: () => void,
}

const closeWindow = (windowState: WindowStateType) => {
    windowState.visible = false;
}

export const dropPickupWindowState: WindowStateType = store({
    visible: false,
    closeHandler: () => {closeWindow(dropPickupWindowState)},
});

export const registerWindowState: WindowStateType = store({
    visible: false,
    closeHandler: () => {closeWindow(registerWindowState)},
});

export const yesNoWindowState: YesNoWindowStateType = store({
    visible: false,
    closeHandler: () => {
        closeWindow(yesNoWindowState);
    },
    title: '',
    text: '',
    yesCallback: () => {},
    noCallback: () => {},
    rangeSlider: null,
});

export const notificationWindowState: NotificationWindowStateType = store({
    visible: false,
    closeHandler: () => {closeWindow(notificationWindowState)},
    title: '',
    text: '',
    center: true,
    closeCallback: () => {},
});

export const npcDialogueState: WindowStateType = store({
    visible: false,
    closeHandler: () => {
        closeWindow(npcDialogueState);
        CameraZoom(1.0);
        HudHide(false);
    },
});

export const npcShopState: WindowStateType = store({
    visible: false,
    closeHandler: () => {
        closeWindow(npcShopState);
        CameraZoom(1.0);
        HudHide(false);
    },
});

export const menuWindowState: WindowStateType = store({
    visible: false,
    closeHandler: () => {closeWindow(menuWindowState)},
});

export const inventoryWindowState: WindowStateType = store({
    visible: false,
    closeHandler: () => {closeWindow(inventoryWindowState)},
});

export const equipmentWindowState: WindowStateType = store({
    visible: false,
    closeHandler: () => {closeWindow(equipmentWindowState)},
});

export const statisticsWindowState: WindowStateType = store({
    visible: false,
    closeHandler: () => {closeWindow(statisticsWindowState)},
});

export const abilitiesTreeWindowState: WindowStateType = store({
    visible: false,
    closeHandler: () => {closeWindow(abilitiesTreeWindowState)},
});

export const chatWindowState: WindowStateType = store({
    visible: false,
    closeHandler: () => {closeWindow(chatWindowState)},
});

export const chooseShortcutWindowState: WindowStateType = store({
    visible: false,
    closeHandler: () => {closeWindow(chooseShortcutWindowState)},
});

export const craftingWindowState: WindowStateType = store({
    visible: false,
    closeHandler: () => {closeWindow(craftingWindowState)},
});

export const npcLogWindowState: WindowStateType = store({
    visible: false,
    closeHandler: () => {closeWindow(npcLogWindowState)},
});

export const questLogWindowState: WindowStateType = store({
    visible: false,
    closeHandler: () => {closeWindow(questLogWindowState)},
});

export const npcStorageWindowState: WindowStateType = store({
    visible: false,
    closeHandler: () => {
        closeWindow(npcStorageWindowState);
        CameraZoom(1.0);
        HudHide(false);
    },
});

export const dailyRewardWindowState: WindowStateType = store({
    visible: false,
    closeHandler: () => {closeWindow(dailyRewardWindowState)},
});

export const achievementsWindowState: WindowStateType = store({
    visible: false,
    closeHandler: () => {closeWindow(achievementsWindowState)},
});

export const collectionsWindowState: WindowStateType = store({
    visible: false,
    closeHandler: () => {closeWindow(collectionsWindowState)},
});

export const levelUpWindowClose = () => {
    addReminder('statistics', () => WindowCreator('statistics'));
    closeWindow(levelUpWindowState);
    Container.getNetworkSender().sendLevelUpWindowClosed();
}

export const levelUpWindowState: WindowStateType = store({
    visible: false,
    closeHandler: () => {
        WindowCreator(
            'yes-no',
            {
                title: 'Are you sure?',
                text: 'Level Up Reward will be lost.',
                yesCallback: () => {
                    levelUpWindowClose();
                },
                noCallback: () => {},
            }
        );
    }
});

export const settingsWindowState: WindowStateType = store({
    visible: false,
    closeHandler: () => {closeWindow(settingsWindowState)},
});

export const leaderboardWindowState: WindowStateType = store({
    visible: false,
    closeHandler: () => {closeWindow(leaderboardWindowState)},
});

const WindowManager = view((props: any) => {
    if (
        npcDialogueState.visible ||
        npcShopState.visible ||
        registerWindowState.visible ||
        yesNoWindowState.visible ||
        notificationWindowState.visible ||
        dropPickupWindowState.visible ||
        menuWindowState.visible ||
        inventoryWindowState.visible ||
        equipmentWindowState.visible ||
        statisticsWindowState.visible ||
        abilitiesTreeWindowState.visible ||
        chatWindowState.visible ||
        chooseShortcutWindowState.visible ||
        craftingWindowState.visible ||
        npcLogWindowState.visible ||
        questLogWindowState.visible ||
        npcStorageWindowState.visible ||
        dailyRewardWindowState.visible ||
        achievementsWindowState.visible ||
        collectionsWindowState.visible ||
        levelUpWindowState.visible ||
        settingsWindowState.visible ||
        leaderboardWindowState.visible
    ) {
        // @ts-ignore
        window.isUiOpen = true;
    } else {
        // @ts-ignore
        window.isUiOpen = false;
    }

    useEffect(() => {
        window.addEventListener('window-creator', (event) => {
            // @ts-ignore
            switch (event.detail.name) {
                // case 'npc-dialogue':
                //     // @ts-ignore
                //     npcDialogueState.visible = true;
                //     break;
                case 'npc-shop':
                    // @ts-ignore
                    npcShopState.visible = true;
                    break;
                case 'register':
                    // @ts-ignore
                    registerWindowState.visible = true;
                    break;
                case 'yes-no':
                    // @ts-ignore
                    yesNoWindowState.title = event.detail.title;
                    // @ts-ignore
                    yesNoWindowState.text = event.detail.text;
                    // @ts-ignore
                    yesNoWindowState.yesCallback = event.detail.yesCallback ?? (() => {});
                    // @ts-ignore
                    yesNoWindowState.noCallback = event.detail.noCallback ?? (() => {});
                    // @ts-ignore
                    yesNoWindowState.rangeSlider = event.detail.rangeSlider ?? null;
                    // @ts-ignore
                    yesNoWindowState.visible = true;
                    break;
                case 'notification':
                    // @ts-ignore
                    notificationWindowState.title = event.detail.title;
                    // @ts-ignore
                    notificationWindowState.text = event.detail.text;
                    // @ts-ignore
                    notificationWindowState.center = event.detail.center;
                    // @ts-ignore
                    notificationWindowState.closeCallback = event.detail.closeCallback;
                    // @ts-ignore
                    notificationWindowState.visible = true;
                    break;
                case 'dropPickup':
                    dropPickupWindowState.visible = true;
                    break;
                case 'menu':
                    menuWindowState.visible = true;
                    break;
                case 'inventory':
                    inventoryWindowState.visible = true;
                    break;
                case 'equipment':
                    equipmentWindowState.visible = true;
                    break;
                case 'statistics':
                    statisticsWindowState.visible = true;
                    break;
                case 'abilitiesTree':
                    abilitiesTreeWindowState.visible = true;
                    break;
                case 'chat':
                    chatWindowState.visible = true;
                    break;
                case 'chooseShortcut':
                    chooseShortcutWindowState.visible = true;
                    break;
                case 'crafting':
                    craftingWindowState.visible = true;
                    break;
                case 'npcLog':
                    npcLogWindowState.visible = true;
                    break;
                case 'questLog':
                    questLogWindowState.visible = true;
                    break;
                case 'npcStorage':
                    npcStorageWindowState.visible = true;
                    break;
                case 'dailyReward':
                    dailyRewardWindowState.visible = true;
                    break;
                case 'achievementsWindow':
                    achievementsWindowState.visible = true;
                    break;
                case 'collectionsWindow':
                    collectionsWindowState.visible = true;
                    break;
                case 'levelUpWindow':
                    levelUpWindowState.visible = true;
                    break;
                case 'settingsWindow':
                    settingsWindowState.visible = true;
                    break;
                case 'leaderboardWindow':
                    leaderboardWindowState.visible = true;
                    break;
            }
        });
        window.addEventListener('window-closer', (event) => {
            // @ts-ignore
            switch (event.detail.name) {
                case 'register':
                    // @ts-ignore
                    registerWindowState.closeHandler();
                    break;
                case 'npc-dialogue':
                    // @ts-ignore
                    npcDialogueState.closeHandler();
                    break;
                case 'npc-shop':
                    // @ts-ignore
                    npcShopState.closeHandler();
                    break;
                case 'yes-no':
                    // @ts-ignore
                    yesNoWindowState.closeHandler();
                    break;
                case 'notification':
                    // @ts-ignore
                    notificationWindowState.closeHandler();
                    break;
                case 'dropPickup':
                    // @ts-ignore
                    dropPickupWindowState.closeHandler();
                    break;
                case 'menu':
                    menuWindowState.closeHandler();
                    break;
                case 'inventory':
                    inventoryWindowState.closeHandler();
                    break;
                case 'equipment':
                    equipmentWindowState.closeHandler();
                    break;
                case 'statistics':
                    statisticsWindowState.closeHandler();
                    break;
                case 'abilitiesTree':
                    abilitiesTreeWindowState.closeHandler();
                    break;
                case 'chat':
                    chatWindowState.closeHandler();
                    break;
                case 'chooseShortcut':
                    chooseShortcutWindowState.closeHandler();
                    break;
                case 'crafting':
                    craftingWindowState.closeHandler();
                    break;
                case 'npcLog':
                    npcLogWindowState.closeHandler();
                    break;
                case 'questLog':
                    questLogWindowState.closeHandler();
                    break;
                case 'npcStorage':
                    npcStorageWindowState.closeHandler();
                    break;
                case 'dailyReward':
                    dailyRewardWindowState.closeHandler();
                    break;
                case 'achievementsWindow':
                    achievementsWindowState.closeHandler();
                    break;
                case 'collectionsWindow':
                    collectionsWindowState.closeHandler();
                    break;
                case 'levelUpWindow':
                    levelUpWindowState.closeHandler();
                    break;
                case 'settingsWindow':
                    settingsWindowState.closeHandler();
                    break;
                case 'leaderboardWindow':
                    leaderboardWindowState.closeHandler();
                    break;
            }
        });
        return () => (undefined);
    }, []);

    useEffect(() => {
    }, [props]);

    return (
        <>
            {registerWindowState.visible &&
            <RegisterWindow windowState={registerWindowState}/>
            }
            {npcDialogueState.visible &&
            <NpcDialogueWindow windowState={npcDialogueState}/>
            }
            {npcShopState.visible &&
            <NpcShopWindow windowState={npcShopState}/>
            }
            {menuWindowState.visible &&
            <MenuWindow windowState={menuWindowState}/>
            }
            {inventoryWindowState.visible &&
            <InventoryWindow windowState={inventoryWindowState}/>
            }
            {equipmentWindowState.visible &&
            <EquipmentWindow windowState={equipmentWindowState}/>
            }
            {statisticsWindowState.visible &&
            <StatisticsWindow windowState={statisticsWindowState}/>
            }
            {abilitiesTreeWindowState.visible &&
            <AbilitiesTreeWindow windowState={abilitiesTreeWindowState}/>
            }
            {chatWindowState.visible &&
            <ChatWindow windowState={chatWindowState}/>
            }
            {craftingWindowState.visible &&
            <CraftingWindow windowState={craftingWindowState}/>
            }
            {npcLogWindowState.visible &&
            <NpcLogWindow windowState={npcLogWindowState}/>
            }
            {questLogWindowState.visible &&
            <QuestLogWindow windowState={questLogWindowState}/>
            }
            {dropPickupWindowState.visible &&
            <DropPickupWindow windowState={dropPickupWindowState}/>
            }
            {chooseShortcutWindowState.visible &&
            <ChooseShortcutWindow windowState={chooseShortcutWindowState}/>
            }
            {npcStorageWindowState.visible &&
            <NpcStorageWindow windowState={npcStorageWindowState}/>
            }
            {achievementsWindowState.visible &&
            <AchievementsWindow windowState={achievementsWindowState}/>
            }
            {collectionsWindowState.visible &&
            <CollectionsWindow windowState={collectionsWindowState}/>
            }
            {settingsWindowState.visible &&
            <SettingsWindow windowState={settingsWindowState}/>
            }
            {leaderboardWindowState.visible &&
            <LeaderboardWindow windowState={leaderboardWindowState}/>
            }
            {dailyRewardWindowState.visible &&
            <DailyRewardWindow windowState={dailyRewardWindowState}/>
            }
            {levelUpWindowState.visible &&
            <LevelUpWindow windowState={levelUpWindowState}/>
            }
            {yesNoWindowState.visible &&
            <YesNoWindow
                title={yesNoWindowState.title}
                text={yesNoWindowState.text}
                yesCallback={yesNoWindowState.yesCallback}
                noCallback={yesNoWindowState.noCallback}
                rangeSlider={yesNoWindowState.rangeSlider}
                windowState={yesNoWindowState}
            />
            }
            {notificationWindowState.visible &&
                <NotificationWindow
                title={notificationWindowState.title}
                text={notificationWindowState.text}
                center={notificationWindowState.center}
                closeCallback={notificationWindowState.closeCallback}
                windowState={notificationWindowState}
                />
            }
        </>
    );
});

export default WindowManager;