import * as React from "react";
import styles from './TopBoosts.module.scss';
import {view} from "react-easy-state";
import boostStore, {Boost, BoostTypeToTitle} from "../../store/BoostStore";
import CountdownStyled from "../../atom/CountdownStyled/CountdownStyled";
import {useLongPress} from "use-long-press";
import {WindowCreator} from "../../service/WindowCreator";
import {renderToStaticMarkup} from "react-dom/server";

const TopBoosts = () => {
    const longPressBind = useLongPress((longPressEvent: React.MouseEvent<Element> | React.TouchEvent<Element> | undefined) => {
        // @ts-ignore
        let boost: Boost = JSON.parse(longPressEvent.target.dataset.boost);

        WindowCreator('notification', {
            title: 'Status',
            text:
                BoostTypeToTitle(boost.type) + '\n' +
                'Amount: ' + formatAmount(boost.amount) + '\n' +
                'Expires In: ' + renderToStaticMarkup(<CountdownStyled completedText={''} seconds={getRemainingSeconds(boost.endTimestamp)}/>),
            center: true,
        });
    }, {
        onFinish: () => {
            setTimeout(() => {
                // @ts-ignore
                window.justLongClicked = false
            }, 5);
        },
        captureEvent: true
    });

    const getRemainingSeconds = (endTimestamp: number): number => {
        return endTimestamp - Math.floor(Date.now() / 1000);
    }

    const formatAmount = (amount: number): string => {
        return (amount * 100) + '%';
    }

    return (
        <div className={styles.wrapper}>
            {boostStore.boosts.map((boost, index) => {
                return <div className={styles.boost} key={'boost-display-' + index} {...longPressBind} data-boost={JSON.stringify(boost)}>
                    <div className={styles.amount}>
                        {formatAmount(boost.amount)}
                    </div>
                    <div className={`${styles.image} ${styles[boost.type]}`}/>
                    <div className={styles.counter}>
                        <CountdownStyled completedText={''} seconds={getRemainingSeconds(boost.endTimestamp)}/>
                    </div>
                </div>
            })}
        </div>
    );
}

export default view(TopBoosts);