export const ItemTypeToTitle = (itemType: number): string => {
    switch (itemType) {
        case 0:
        default:
            return 'Item';
        case 1:
            return 'Helmet';
        case 2:
            return 'Necklace';
        case 3:
            return 'Armor';
        case 4:
            return 'Melee (1 Hand)';
        case 5:
            return 'Melee (2 Hand)';
        case 6:
            return 'Distance';
        case 7:
            return 'Dagger';
        case 8:
            return 'Magic Staff';
        case 9:
            return 'Shield';
        case 10:
            return 'Ring';
        case 11:
            return 'Boots';
        case 12:
            return 'Legs';
        case 13:
            return 'Gloves';
        case 14:
            return 'Ammunition';
        case 15:
            return 'Gold Coins';
        case 16:
            return 'Elemental Karma';
    }
}

export const ItemTypesToTitle = (itemTypes: number[]): string => {
    let types: string[] = [];
    itemTypes.forEach((itemType) => {
        types.push(ItemTypeToTitle(itemType));
    })

    return types.join(', ');
}