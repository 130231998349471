import shortcutsStore from "../store/ShortcutsStore";

export const ShortcutsCooldownRefresher = (itemId: number, cooldown: number|null): void => {
    const endTimestamp = Date.now() + (cooldown ?? 0);
    const endDefaultTimestamp = Date.now() + 2000;

    Object.keys(shortcutsStore.shortcuts).forEach((slot) => {
        if (shortcutsStore.shortcuts[slot] === null) {
            return;
        }

        if (shortcutsStore.shortcuts[slot].id !== itemId) {
            if (shortcutsStore.shortcuts[slot].endTimestamp !== null &&
                shortcutsStore.shortcuts[slot].endTimestamp > endDefaultTimestamp
            ) {
                return;
            }

            shortcutsStore.shortcuts[slot].cooldown = 2000;
            shortcutsStore.shortcuts[slot].endTimestamp = endDefaultTimestamp;

            setTimeout(() => {
                if (shortcutsStore.shortcuts[slot].endTimestamp > endDefaultTimestamp) {
                    return;
                }

                shortcutsStore.shortcuts[slot].cooldown = null;
                shortcutsStore.shortcuts[slot].endTimestamp = null;
            }, 2000);

            return;
        }

        shortcutsStore.shortcuts[slot].cooldown = cooldown;
        shortcutsStore.shortcuts[slot].endTimestamp = endTimestamp;

        if (cooldown !== null) {
            setTimeout(() => {
                if (shortcutsStore.shortcuts[slot].endTimestamp > endTimestamp) {
                    return;
                }

                shortcutsStore.shortcuts[slot].cooldown = null;
                shortcutsStore.shortcuts[slot].endTimestamp = null;
            }, cooldown - 1);
        }
    });
}