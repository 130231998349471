import * as React from "react";
import {view} from "react-easy-state";
import Button, {ButtonSize, ButtonType} from "../../atom/Button/Button";
import TextInput from "../../atom/Input/TextInput";
import chatStore from "../../store/ChatStore";
import Container from "../../../di/container";
import {ServerMessageCreator} from "../../service/ServerMessageCreator";
import styles from './Chat.module.scss'
import {ColorByMessageType} from "../../../util/ColorByType";
import Tabs from "../Tabs/Tabs";

const Chat = () => {
    const sendMessage = (event) => {
        event.preventDefault();

        if (!validateMessage()) {
            return;
        }

        Container.getNetworkSender().sendChatMessage(chatStore.inputMessage);
        chatStore.inputMessage = '';
    }

    const validateMessage = (): boolean => {
        if (chatStore.inputMessage.length === 0) {
            ServerMessageCreator(1, 'Message cannot be empty');
            return false;
        }

        if (chatStore.inputMessage.length > 100) {
            ServerMessageCreator(1, 'Message is too long');
            return false;
        }

        return true;
    }

    return (
        <div className={styles.wrapper}>
            <div className={styles.channels}>
                <Tabs tabs={[
                    {
                        title: 'General',
                        active: true,
                        selected: true,
                        callback: () => {},
                    },
                    {
                        title: 'Market',
                        active: true,
                        selected: false,
                        callback: () => {},
                    },
                    {
                        title: 'Help',
                        active: true,
                        selected: false,
                        callback: () => {},
                    },
                    {
                        title: '+',
                        active: true,
                        selected: false,
                        callback: () => {},
                    },
                ]}
                />
            </div>
            <div className={styles.chat}>
                <form className={styles.inputForm} onSubmit={sendMessage}>
                    <TextInput name={'inputMessage'} value={chatStore.inputMessage} placeholder={'Type message here...'} maxLength={100} onUpdate={(inputName: string, value: string) => {
                        chatStore.inputMessage = value;
                    }}/>
                    <Button htmlType={'submit'} text={'Send'} type={ButtonType.TYPE_ACCEPT} size={ButtonSize.TYPE_SMALL} click={() => {}}/>
                </form>
                <div className={styles.messages}>
                    {chatStore.messagesNew.map((message, index) => {
                        return <div style={{color: ColorByMessageType(message[1], true)}} className={styles.message} key={index}>{message[0]}</div>
                    })}
                </div>
            </div>
        </div>
    );
};

export default view(Chat);