import * as React from "react";
import {view} from "react-easy-state";
import Window, {WindowProps, WindowStyle} from "./Window";
import ButtonContainer from "../atom/ButtonContainer/ButtonContainer";
import npcStore from "../store/NpcStore";
import ItemContainer from "../component/ItemContainer/ItemContainer";
import inventoryStore, {getQuantityOfItemId} from "../store/InventoryStore";
import TextContainer from "../atom/TextContainer/TextContainer";
import {ItemPriceType} from "../atom/Item/Item";
import {WindowCreator} from "../service/WindowCreator";
import Container from "../../di/container";
import {useRef, useState} from "react";
import RangeSliderUncontrolled from "../atom/RangeSlider/RangeSliderUncontrolled";

const NpcShopWindow = ({children, windowState}: WindowProps) => {
    const [purchaseQuantity, setPurchaseQuantity] = useState<number>(1);
    const [sellQuantity, setSellQuantity] = useState<number>(1);

    const purchaseRef = useRef<HTMLInputElement>(null);
    const sellRef = useRef<HTMLInputElement>(null);

    return (
        <Window title={'<i class="bi bi-cart-fill"></i> ' + npcStore.title + ' - Shop'} windowState={windowState} windowStyle={WindowStyle.STYLE_NORMAL}>
            <ButtonContainer wrap={false} largeGap={true}>
                <div style={{width: '50%'}}>
                    <ItemContainer title={'Items that you can Buy'}
                                   centered={true}
                                   isSelectableMultiple={false}
                                   isSelectable={true}
                                   noSelectOnlyClick={true}
                        // @ts-ignore
                                   items={npcStore.shop?.sell.map((item) => {
                                       const sellable = { ...item.item };
                                       sellable.price = item.price;

                                       /** We don't want to show quantity when dealing with items that lies on NPC */
                                       sellable.quantity = 1;

                                       return sellable;
                                   })}
                                   priceType={ItemPriceType.TYPE_RED}
                                   onSelect={(selectedPositions, selectedIds, item) => {
                                       npcStore.selectedIdPurchase = selectedIds[0] ?? null;
                                       // @ts-ignore
                                       npcStore.purchaseItemName = selectedPositions.length ? item.name + ' - ' + item.price + ' Gold Coins' : '';
                                       // @ts-ignore
                                       npcStore.purchaseItemValue = selectedPositions.length ? item.price : 0;

                                       setPurchaseQuantity(1);

                                       if (npcStore.purchaseItemValue > inventoryStore.gold) {
                                           WindowCreator(
                                               'notification',
                                               {
                                                   title: 'Info',
                                                   text: 'You are not wealthy enough to purchase this item yet.'
                                               }
                                           );
                                       } else {
                                           WindowCreator(
                                               'yes-no',
                                               {
                                                   title: 'Are you sure?',
                                                   text: 'You are about to purchase:\n\n' + npcStore.purchaseItemName + '\n\n Gold Coins will be taken from your Inventory for each piece.',
                                                   yesCallback: () => {
                                                       if (npcStore.selectedIdPurchase === null) {
                                                           return;
                                                       }

                                                       // @ts-ignore
                                                       Container.getNetworkSender().sendBuyFromNpc(npcStore.selectedIdPurchase, purchaseRef.current.value);
                                                   },
                                                   noCallback: () => {},
                                                   rangeSlider:
                                                       <RangeSliderUncontrolled
                                                           name={'Quantity'}
                                                           min={1}
                                                           max={Math.floor(inventoryStore.gold / npcStore.purchaseItemValue)}
                                                           defaultValue={purchaseQuantity}
                                                           ref={purchaseRef}
                                                       />
                                               }
                                           );
                                       }
                                   }}
                    />
                </div>
                <div style={{width: '50%'}}>
                    <ItemContainer title={'Items that you can Sell'}
                                   centered={true}
                                   isSelectableMultiple={false}
                                   isSelectable={true}
                                   noSelectOnlyClick={true}
                                    // @ts-ignore
                                   items={npcStore.shop.buy.map((item) => {
                                       const buyable = { ... item.item };
                                       buyable.price = item.price;

                                       buyable.quantity = getQuantityOfItemId(buyable.id);

                                       return buyable;
                                   })}
                                   priceType={ItemPriceType.TYPE_GREEN}
                                   onSelect={(selectedPositions, selectedIds, item) => {
                                       // @ts-ignore
                                       if (item.quantity < 1) {
                                           return;
                                       }

                                       npcStore.selectedIdSell = selectedIds[0] ?? null;
                                       // @ts-ignore
                                       npcStore.sellItemName = selectedPositions.length ? item.name + ' - ' + item.price + ' Gold Coins' : '';
                                       // @ts-ignore
                                       npcStore.sellItemQuantity = selectedPositions.length ? item.quantity : 0;

                                       WindowCreator(
                                           'yes-no',
                                           {
                                               title: 'Are you sure?',
                                               text: 'You are about to sell item from your Inventory:\n\n' + npcStore.sellItemName + '\n\n Item will be taken from your Inventory.',
                                               yesCallback: () => {
                                                   if (npcStore.selectedIdSell === null) {
                                                       return;
                                                   }

                                                   // @ts-ignore
                                                   Container.getNetworkSender().sendSellToNpc(npcStore.selectedIdSell, sellRef.current.value);
                                               },
                                               noCallback: () => {},
                                               rangeSlider:
                                                   <RangeSliderUncontrolled
                                                       name={'Quantity'}
                                                       min={1}
                                                       max={npcStore.sellItemQuantity}
                                                       defaultValue={npcStore.sellItemQuantity}
                                                       ref={sellRef}
                                                   />
                                           }
                                       );
                                   }}
                    />
                </div>
            </ButtonContainer>
            <TextContainer text={'You have ' + inventoryStore.gold + ' Gold Coins.'} centered={true}/>
        </Window>
    );
};

export default view(NpcShopWindow);