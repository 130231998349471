import {store} from "react-easy-state";

type AchievementsStoreType = {
    achievements: {[index: string]: string},
}

const achievementsStore: AchievementsStoreType = store({
    achievements: {},
});

export default achievementsStore;