import * as React from "react";
import styles from './CountdownStyled.module.scss'
import Countdown, {zeroPad} from "react-countdown";

export type CountdownStyledProps = {
    seconds: number
    counterPrefix?: string,
    completedText: string,
};

const CountdownStyled = ({seconds, completedText, counterPrefix = ''}: CountdownStyledProps) => {
    const renderer = ({ hours, minutes, seconds, completed }) => {
        if (completed) {
            return <span>{completedText}</span>
        } else {
            return <span>{counterPrefix} {zeroPad(minutes)}:{zeroPad(seconds)}</span>;
        }
    };

    return (
        <Countdown
            date={Date.now() + seconds*1000}
            renderer={renderer}
        />
    );
};

export default CountdownStyled;