import {MainScene} from "../scene/mainScene";
import {CreatureService} from "./creatureService";
import {CreatureType} from "../model/creature";
import {EffectService} from "./effectService";
import {UiHide} from "../ui/service/UiHide";
import {MapService} from "./mapService";
import {LightLevel, LightService} from "./lightService";
import cutsceneStore from "../ui/store/CutsceneStore";

export class CutsceneService {
    CAMERA_FADE_TIME: number = 500;

    public readonly scene: MainScene;
    private readonly creatureService: CreatureService;
    private readonly effectService: EffectService;
    private readonly mapService: MapService;
    private lightService: LightService;

    private creatureNamesToIds: Map<string, number> = new Map<string, number>();

    private originalPlayerX: number = 0;
    private originalPlayerY: number = 0;
    private originalPlayerMapId: number = 0;
    private originalLightLevel: LightLevel = LightLevel.LIGHT_LEVEL_DAY;

    private active: boolean = false;

    constructor(
        mainScene: MainScene,
        creatureService: CreatureService,
        effectService: EffectService,
        mapService: MapService,
        lightService: LightService
    ) {
        this.scene = mainScene;
        this.creatureService = creatureService;
        this.effectService = effectService;
        this.mapService = mapService;
        this.lightService = lightService;
    }

    public play(name: string): void {
        import('../cutscenes/' + name + '.ts').then((cutscene) => {
            cutscene.scene(this);
        });
    }

    public start(ms: number): void {
        this.active = true;
        this.mapService.canRemoveChunks = false;

        this.originalPlayerX = this.scene.getMyPlayer()?.getPositionX() ?? 0;
        this.originalPlayerY = this.scene.getMyPlayer()?.getPositionY() ?? 0;
        this.originalPlayerMapId = this.mapService.currentMapId;
        this.originalLightLevel = this.lightService.getLightLevel();

        this.schedule(ms - this.CAMERA_FADE_TIME, () => {
            this.cameraBlip();
        });

        this.schedule(ms, () => {
            UiHide(true);
            this.creatureService.hideAll(true);
        });
    }

    public finish(ms: number): void {
        this.schedule(ms - this.CAMERA_FADE_TIME, () => {
            this.cameraBlip();
        });

        this.schedule(ms, () => {
            this.creatureNamesToIds.forEach((id: number, alias: string) => {
                this.removeCreature(0, alias);
            });

            this.creatureService.showAll();

            if (this.originalPlayerX !== this.scene.getMyPlayer()?.getPositionX() ||
                this.originalPlayerY !== this.scene.getMyPlayer()?.getPositionY()
            ) {
                // @ts-ignore
                this.creatureService.teleport(this.scene.myPlayerId, this.originalPlayerX, this.originalPlayerY);
                this.mapService.updateEnvironment(this.originalPlayerMapId);
            }

            this.lightService.setLightLevel(this.originalLightLevel, true);
            this.scene.setSmoothFactor();

            UiHide(false);

            this.active = false;
            this.mapService.canRemoveChunks = true;
        });
    }

    public isActive(): boolean {
        return this.active;
    }

    public setCameraPosition(ms: number, x: number, y: number, mapId: number|null = null): void {
        this.schedule(ms, () => {
            // @ts-ignore
            this.creatureService.teleport(this.scene.myPlayerId, x, y);
            this.mapService.updateEnvironment(mapId);
        });
    }

    public setCameraPositionWhileFreeze(ms: number, x: number, y: number, mapId: number|null = null): void {
        this.schedule(ms, () => {
            // @ts-ignore
            this.creatureService.teleport(this.scene.myPlayerId, x, y);
            this.mapService.updateEnvironment(mapId);
            this.scene.cameras.main.setScroll(x*32 - this.scene.cameras.main.width * 0.5, y*32 - this.scene.cameras.main.height * 0.5);
        });
    }

    public setFarCameraPosition(fromMs: number, toMs: number, fromX: number, fromY: number, toX: number, toY: number, ease: string = 'Sine.easeInOut'): void {
        const diffMs = toMs - fromMs;
        const diffX = toX - fromX;
        const diffY = toY - fromY;

        const dist = Math.sqrt(diffX * diffX + diffY * diffY);
        const breaks = Math.ceil(dist / 5);

        for (let i = 1; i <= breaks; i++) {
            const fraction = i/breaks;

            const ms = fromMs + diffMs * fraction;
            const px = fromX + diffX * fraction;
            const py = fromY + diffY * fraction;

            this.setCameraPosition(ms, px, py);
        }

        this.schedule(fromMs, () => {
            // @ts-ignore
            this.scene.cameras.main.pan(toX * 32, toY * 32, diffMs, ease);
        });
    }

    public setCameraFreeze(ms: number, freeze: boolean): void {
        this.schedule(ms, () => {
            // @ts-ignore
            this.scene.isCameraFreeze = freeze;
        });
    }

    public setSmoothFactor(ms: number, factor: number): void {
        this.schedule(ms, () => {
            // @ts-ignore
            this.scene.setSmoothFactor(factor);
        });
    }

    public setLightLevel(ms: number, level: LightLevel): void {
        this.schedule(ms, () => {
            this.lightService.setLightLevel(level, true);
        });
    }

    public setOriginalLightLevel(level: LightLevel): void {
        this.originalLightLevel = level;
    }

    public addCreature(ms: number, alias: string, x: number, y: number, name: string, type: CreatureType, health: number, maxHealth: number, walkTime: number, outfitId: string, direction: number = 2): void {
        this.schedule(ms, () => {
            const id = this.getRandomInt(-1000000, -100);
            this.creatureNamesToIds.set(alias, id);

            this.creatureService.addCreature(id, x, y, name, type, health, maxHealth, walkTime, outfitId, direction);
        });
    }

    public removeCreature(ms: number, alias: string): void {
        this.schedule(ms, () => {
            this.creatureService.removeCreature(this.getIdByAlias(alias));
        });
    }

    public directionMoveCreature(ms: number, alias: string, direction: number): void {
        this.schedule(ms, () => {
            this.creatureService.directionMove(this.getIdByAlias(alias), direction);
        });
    }

    public updateHealthCreature(ms: number, alias: string, health: number, maxHealth: number): void {
        this.schedule(ms, () => {
            this.creatureService.updateHealth(this.getIdByAlias(alias), health, maxHealth);
        });
    }

    public effectToAlias(ms: number, effectId: number, alias: string): void {
        this.schedule(ms, () => {
            this.effectService.createEffect(effectId, ...this.getPositionByAlias(alias));
        });
    }

    public effect(ms: number, effectId: number, x: number, y: number): void {
        this.schedule(ms, () => {
            this.effectService.createEffect(effectId, x, y);
        });
    }

    public effectArea(ms: number, effectId: number, fromX: number, toX: number, fromY: number, toY: number): void {
        this.schedule(ms, () => {
            for (let x = fromX; x < toX; x++) {
                for (let y = fromY; y < toY; y++) {
                    this.effectService.createEffect(effectId, x, y);
                }
            }
        });
    }

    public shootEffectFromAliasToAlias(ms: number, effectId: number, fromAlias: string, toAlias: string): void {
        this.schedule(ms, () => {
            this.effectService.createShootEffect(effectId, ...this.getPositionByAlias(fromAlias), ...this.getPositionByAlias(toAlias))
        });
    }

    public shootEffect(ms: number, effectId: number, fromX: number, fromY: number, toX: number, toY: number): void {
        this.schedule(ms, () => {
            this.effectService.createShootEffect(effectId, fromX, fromY, toX, toY)
        });
    }

    public textEffectAlias(ms: number, text: string, color: number, alias: string, duration: number = 750): void {
        this.schedule(ms, () => {
            this.effectService.createTextEffect(text, color, ...this.getPositionByAlias(alias), duration);
        });
    }

    public textEffect(ms: number, text: string, color: number, x: number, y: number, duration: number = 750): void {
        this.schedule(ms, () => {
            this.effectService.createTextEffect(text, color, x, y, duration);
        });
    }

    public staticText(ms: number, text: string, color: number, x: number, y: number, duration: number = 750): void {
        this.schedule(ms, () => {
            this.effectService.createStaticText(text, color, x, y, duration);
        });
    }

    public topText(ms: number, text: string, duration: number = 5000): void {
        this.schedule(ms, () => {
            cutsceneStore.text = text;

            this.schedule(duration, () => {
                cutsceneStore.text = '';
            });
        });
    }

    public cameraBlipWhite(ms: number, duration: number): void {
        this.schedule(ms, () => {
            this.scene.cameras.main.fadeIn(duration, 255, 255, 255);
        });
    }

    public cameraBlip(): void {
        this.scene.cameras.main.fadeOut(this.CAMERA_FADE_TIME, 73, 42, 12);
        this.schedule(this.CAMERA_FADE_TIME, () => {
            this.scene.cameras.main.fadeIn(this.CAMERA_FADE_TIME, 73, 42, 12);
        });
    }

    public schedule(ms: number, fn: () => void): void {
        if (ms === 0) {
            fn();
            return;
        }

        setTimeout(fn, ms);
    }

    public getIdByAlias(alias: string): number {
        const id = this.creatureNamesToIds.get(alias);

        if (id === undefined) {
            return 0;
        }

        return id;
    }

    public getPositionByAlias(alias: string): [number, number] {
        const id = this.getIdByAlias(alias);

        const creature = this.creatureService.getCreature(id);

        if (creature === undefined) {
            return [0, 0];
        }

        return [
            creature.getPositionX(),
            creature.getPositionY()
        ];
    }

    public getRandomInt(min: number, max: number): number {
        min = Math.ceil(min);
        max = Math.floor(max);
        return Math.floor(Math.random() * (max - min + 1)) + min;
    }
}