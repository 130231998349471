import {store} from "react-easy-state";

export type CraftingType = {
    key: number,
    title: string,
    description: string,
    requiredRelation: string,
    canFailCreation: boolean,
    required: [],
    result: [],
}

export type CraftingStoreType = {
    title: string,
    x: number,
    y: number,
    types: CraftingType[],
}

const craftingStore: CraftingStoreType = store({
    title: '',
    x: 0,
    y: 0,
    types: [],
});

export default craftingStore;