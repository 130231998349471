export const ServerMessageCreator = (
    type: number,
    message: string
) => {
    let event = new CustomEvent('server-message-create', {
        detail: {
            type: type,
            text: message,
        },
    });
    window.dispatchEvent(event);
}