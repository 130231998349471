import React, {useEffect, useState} from 'react';
import { store, view } from 'react-easy-state';
import styles from './HudMenu.module.scss'
import HudMenuButton from "../../atom/HudMenuButton/HudMenuButton";
import {TutorialState, tutorialStore} from "../../store/TutorialStore";
import {WindowCreator} from "../../service/WindowCreator";
import globalStore from "../../store/GlobalStore";
import Container from "../../../di/container";
import reminderStore from "../../store/ReminderStore";

const HudMenu = () => {
    return (
        <div className={styles.wrapper}>
            {reminderStore.actions.length > 0 &&
            <HudMenuButton icon={'<i class="bi bi-exclamation-lg"></i>'} alert={true} onClick={() => {
                const [key, action] = reminderStore.actions.shift() as [key: string, action: () => void];
                action();
            }}/>
            }
            {globalStore.demo &&
            <HudMenuButton icon={'REGISTER NOW,</br>SAVE PROGRESS!'} wide={true} onClick={() => {
                WindowCreator('register');
            }}/>
            }
            <HudMenuButton icon={'<i class="bi bi-arrows-fullscreen"></i>'} onClick={() => {
                if (document.fullscreenElement) {
                    document.exitFullscreen();
                    return;
                }

                document.body.requestFullscreen();
            }}/>
            <HudMenuButton icon={'<i class="bi bi-chat-left-text-fill"></i>'} onClick={() => {
                WindowCreator('chat');
            }}/>
            <HudMenuButton icon={'<i class="bi bi-justify" id="main-menu-open"></i>'} onClick={() => {
                if (tutorialStore.state === TutorialState.STATE_INVENTORY_CLICK) {
                    tutorialStore.state = TutorialState.STATE_MENU_INFO;
                }

                WindowCreator('menu');
            }}/>
        </div>
    );
};

export default view(HudMenu);