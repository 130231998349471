import * as React from "react";
import {view} from "react-easy-state";
import Window, {WindowProps, WindowStyle} from "./Window";
import Chat from "../component/Chat/Chat";

const ChatWindow = ({children, windowState}: WindowProps) => {
    return (
        <Window title={'<i class="bi bi-chat-left-text-fill"></i> Chat'} windowState={windowState} windowStyle={WindowStyle.STYLE_NORMAL}>
            <Chat/>
        </Window>
    );
};

export default view(ChatWindow);