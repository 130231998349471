import * as React from "react";
import styles from './Window.module.scss'
import {view} from "react-easy-state";
import {WindowStateType} from "../manager/WindowManager";
import {TutorialState, tutorialStore} from "../store/TutorialStore";
import {PlaySound} from "../../util/PlaySound";
import {SoundEffect} from "../../service/soundService";

export enum WindowStyle {
    STYLE_NARROW = 'window-narrow',
    STYLE_NARROW_FORCE = 'window-narrow-force',
    STYLE_NARROW_FOCUS = 'window-narrow-focus',
    STYLE_NORMAL = 'window-normal',
    STYLE_WIDE = 'window-wide',
    STYLE_SCROLL = 'window-scroll',
    STYLE_SCROLL_NARROW = 'window-scroll-narrow',
    STYLE_DAILY_REWARD = 'window-daily-reward',
}

export type WindowProps = {
    windowState: WindowStateType,
    title?: string,
    windowStyle?: WindowStyle,
    children?: React.ReactNode,
    footer?: React.ReactNode,
};

const Window = ({title = '', windowStyle = WindowStyle.STYLE_NORMAL, windowState = {visible: true, closeHandler: () => {}}, children = [], footer = []}: WindowProps) => {
    return (
        <div className={`${styles.windowOverlay} ${styles[windowStyle]}`} style={{
            visibility: windowState.visible ? "visible" : "hidden"
        }} onClick={() => {
            if (tutorialStore.state === TutorialState.STATE_MENU_CLICK) {
                return;
            }

            windowState.closeHandler();
        }}
        >
            <div className={`${styles.window} ${styles[windowStyle]}`} style={{
                visibility: windowState.visible ? "visible" : "hidden"
            }} onClick={e => {
                e.stopPropagation();
            }}>
                <button className={styles.close} onClick={() => {
                    PlaySound(SoundEffect.CLICK);
                    windowState.closeHandler();
                }}><i className="bi bi-x-lg"/></button>
                <h2 className={styles.title} dangerouslySetInnerHTML={{
                    // @ts-ignore
                    __html: title
                }}>
                </h2>
                <div className={styles.contents}>
                    {children}
                </div>
                {footer}
            </div>
        </div>
    );
};

export default view(Window);