export const Confetti = () => {
    // @ts-ignore
    window.particlesContainer.playEmitter('leftEmitter');
    // @ts-ignore
    window.particlesContainer.playEmitter('rightEmitter');

    setTimeout(() => {
        // @ts-ignore
        window.particlesContainer.pauseEmitter('leftEmitter');
        // @ts-ignore
        window.particlesContainer.pauseEmitter('rightEmitter');
    }, 1000);
}