import * as React from "react";
import styles from './Register.module.scss';
import {view} from "react-easy-state";
import TextInput from "../../atom/Input/TextInput";
import registerStore from "../../store/RegisterStore";
import EmailInput from "../../atom/Input/EmailInput";
import HorizontalGroup from "../../atom/Input/HorizontalGroup";
import PasswordInput from "../../atom/Input/PasswordInput";
import ButtonContainer from "../../atom/ButtonContainer/ButtonContainer";
import Button, {ButtonType} from "../../atom/Button/Button";
import Container from "../../../di/container";
import Checkbox from "../../atom/Checkbox/Checkbox";
import {ValidateRegister} from "../../../util/ValidateRegister";
import {loginStore} from "../../store/LoginStore";

const Register = () => {
    const handleRegister = (event) => {
        event.preventDefault();

        if (!ValidateRegister(registerStore)) {
            return;
        }

        Container.getNetworkSender().sendCreateAccountAndPlayer(
            registerStore.name,
            registerStore.email,
            registerStore.password,
            registerStore.terms,
            registerStore.newsletter,
            registerStore.promo
        );
    };

    const updateStore = (name: string, value: string|number|boolean) => {
        if (name === 'promo') {
            loginStore.isPromoValid = true;
        }

        registerStore[name] = value;
    }

    return (
        <div className={styles.wrapper}>
            <div>
                <h3>You will retain your current Hero, under your favourite name</h3>
                <span>Registration benefits:</span>
                <ul>
                    <li>progress will be saved and you'll start playing exactly where you've left</li>
                    <li>trading, chat and special abilities will be unlocked</li>
                    <li>access to the whole map</li>
                    <li>and many, many more...</li>
                </ul>
            </div>
            <div>
                <form onSubmit={handleRegister}>
                    <TextInput name={'name'} value={registerStore.name} error={registerStore.invalidFieldsToMessage['name']} onUpdate={updateStore} label={'Hero Name'} placeholder={'Hero'} minLength={3} maxLength={20} required={true}/>

                    <EmailInput name={'email'} value={registerStore.email} error={registerStore.invalidFieldsToMessage['email']} onUpdate={updateStore} label={'Email'} placeholder={'hero@gmail.com'} minLength={3} maxLength={100} required={true}/>

                    <HorizontalGroup>
                        <PasswordInput name={'password'} value={registerStore.password} error={registerStore.invalidFieldsToMessage['password']} onUpdate={updateStore} label={'Password'} placeholder={'Password'} minLength={8} maxLength={50} required={true}/>
                        <PasswordInput name={'passwordRepeat'} value={registerStore.passwordRepeat} error={registerStore.invalidFieldsToMessage['repeatPassword']} onUpdate={updateStore} label={'Repeat Password'} placeholder={'Password'} minLength={8} maxLength={50} required={true}/>
                    </HorizontalGroup>

                    <Checkbox label={'I agree to receive latest Herodonia news. Promise - no spam'} required={false} checked={false} name={'newsletter'} onUpdate={updateStore}/>
                    <Checkbox label={'I accept the Herodonia <a href="https://www.herodonia.com/terms-and-conditions/" target="_blank">Terms & Conditions</a> and <a href="https://www.herodonia.com/privacy-policy/" target="_blank">Privacy Policy</a>'} required={true} checked={false} name={'terms'} onUpdate={updateStore}/>

                    <TextInput name={'promo'} value={registerStore.promo} error={loginStore.isPromoValid ? undefined : 'Promo Code is not valid.'} onUpdate={updateStore} label={'Have a Promo Code? Enter it here to earn additional rewards.'} placeholder={'Your Promo Code'} minLength={3} maxLength={20}/>

                    <ButtonContainer>
                        <Button text={'Register'} htmlType={'submit'} type={ButtonType.TYPE_ACCEPT} click={() => {}}/>
                    </ButtonContainer>
                </form>
            </div>
        </div>
    );
}

export default view(Register);