import * as React from "react";
import styles from './ItemEmpty.module.scss'

const ItemEmpty = () => {
    return (
        <div className={styles.wrapper}>
            {/*<div className={styles.item}/>*/}
        </div>
    );
}

export default ItemEmpty;