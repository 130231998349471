import * as React from "react";
import {view} from "react-easy-state";
import styles from './TapTab.module.scss';

type TapTabProps = {
    backgroundColor: string,
    image: string,
    title: string,
    subtitle: string,
    content: string,
    footer: string,
    onClick: () => void,
    active: boolean,
}

const TapTab = ({backgroundColor, image, title, subtitle, content, footer, onClick, active}: TapTabProps) => {
    return (
        <div className={`${styles.wrapper} ${active ? '' : styles['inactive']}`} style={{backgroundColor: backgroundColor}} onClick={() => {active && onClick()}}>
            <div className={styles.header}>
                <div className={styles.headerLeft}>
                    <img className={styles.image} src={image}/>
                </div>
                <div className={styles.headerRight}>
                    <div className={styles.title}>
                        {title}
                    </div>
                    <div className={styles.subtitle}>
                        {subtitle}
                    </div>
                </div>
            </div>
            <p className={styles.content}>{content}</p>
            <p className={styles.footer}>{footer}</p>
        </div>
    );
}

export default view(TapTab);