import * as React from "react";
import {view} from "react-easy-state";
import Window, {WindowProps, WindowStyle} from "./Window";
import Graph from "react-graph-vis";
import {AbilitiesForGraphVizParsed} from "../../util/AbilitiesForGraphVizParser";

const AbilitiesTreeWindow = ({children, windowState}: WindowProps) => {
    const options = {
        layout: {
            hierarchical: {
                enabled: true,
                sortMethod: 'directed',
                direction: 'UD',
                levelSeparation: 275,
            }
        },
        physics: {
            hierarchicalRepulsion: {
                avoidOverlap: 1,
            }
        },
        edges: {
            color: "#ffffff"
        },
        nodes: {
            labelHighlightBold: false,
            font: {
                multi: true,
                bold: '16px arial black',
            },
            shape: 'box',
            heightConstraint: {
                minimum: 30
            },
            widthConstraint: {
                minimum: 140,
                maximum: 140
            },
        },
        interaction: {
            dragNodes: false,
            dragView: true,
        },
        height: window.innerHeight * 0.75 + 'px'
    };

    const events = {
        click: function(event) {
            var { nodes, edges } = event;
            console.log('Clicked on node id: ' + nodes[0]);
        },
        doubleClick: function(event) {
            var { nodes, edges } = event;
            console.log('Double Click - OPEN id: ' + nodes[0]);

            // if (!activeNodes[nodes[0]]) {
            //     return;
            // }
            //
            // WindowCreator('notification', {
            //     title: 'Details of an Ability.',
            //     text: 'Lorem Ipsum Details of ability, it is super duper hiper ability.',
            //     center: true,
            // });
        },
        hold: function(event) {
            var { nodes, edges } = event;
            console.log('Hold - OPEN id: ' + nodes[0]);

            // if (!activeNodes[nodes[0]]) {
            //     return;
            // }
            //
            // WindowCreator('notification', {
            //     title: 'Details of an Ability.',
            //     text: 'Lorem Ipsum Details of ability, it is super duper hiper ability.',
            //     center: true,
            // });
        },
    };

    return (
        <Window title={'<i class="bi bi-fire"></i> Abilities Tree'} windowState={windowState} windowStyle={WindowStyle.STYLE_SCROLL}>
            <Graph
                graph={AbilitiesForGraphVizParsed()}
                options={options}
                events={events}
                getNetwork={(network) => {}}
            />
        </Window>
    );
};

export default view(AbilitiesTreeWindow);