import * as React from "react";
import styles from './ButtonContainer.module.scss'
import {view} from "react-easy-state";

type MyProps = {
    vertical?: boolean
    wrap?: boolean,
    largeGap?: boolean,
    children?: React.ReactNode,
};

const ButtonContainer = (props: MyProps) => {
    const visible = true;
    const vertical = props.vertical ?? false;
    const wrap = props.wrap ?? true;
    const largeGap = props.largeGap ?? false;

    return (
        <div className={`${styles.buttonContainer} ${vertical ? styles.vertical : styles.horizontal} ${wrap ? styles.wrap : styles.nowrap} ${largeGap ? styles.largeGap : ''}`}>
            {props.children}
        </div>
    );
};

export default view(ButtonContainer);