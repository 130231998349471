import React, {useEffect, useState} from 'react';
import { store, view } from 'react-easy-state';
import shortcutsStore, {ShortcutTypeItem, ShortcutTypeSkill} from "../../store/ShortcutsStore";
import Item from "../../atom/Item/Item";
import Container from "../../../di/container";
import styles from './ShortcutContainer.module.scss'
import {useLongPress} from "use-long-press";
import {WindowCreator} from "../../service/WindowCreator";
import SkillDescription from "../../atom/SkillDescription/SkillDescription";
import {CanUseSKill} from "../../../util/CanUseSkill";
import {TutorialState, tutorialStore} from "../../store/TutorialStore";

export type ShortcutContainerProps = {
    slot: number,
    shortcut: ShortcutTypeItem|ShortcutTypeSkill|null,
    interactive?: boolean,
};

const ShortcutContainer = ({slot, shortcut, interactive = true}: ShortcutContainerProps) => {
    const longPressBind = useLongPress((element) => {
        handleLongClick(slot, shortcut);
        element?.stopPropagation();
    });

    const handleLongClick = (slot: number, shortcut: ShortcutTypeItem|ShortcutTypeSkill|null) => {
        if (!interactive) {
            return;
        }

        if (tutorialStore.state === TutorialState.STATE_SETTING_SHORTCUT_CLICK) {
            tutorialStore.state = TutorialState.STATE_SETTING_SHORTCUT_SELECT_SKILL_INFO;
        }

        shortcutsStore.currentlyChoosing = slot;
        WindowCreator('chooseShortcut');
    }

    return (
        <div className={styles.shortcutOverlay}
             key={slot}
             style={{gridArea: 'p' + (slot)}}
        >
            {shortcut === null &&
            <div className={styles.shortcutEmpty}
                {...longPressBind}
            >
                <div className={styles.emptyWrapper}>
                    <img className={styles.image}
                         src={"assets/ui/icon/none.png"}
                    />
                </div>
            </div>
            }
            {shortcut !== null && 'quantity' in shortcut &&
            <>
                <Item key={slot}
                      position={slot}
                      details={{}}
                      id={shortcut.id}
                      quantity={shortcut.quantity}
                      isSelectable={shortcut.quantity > 0}
                      onClick={(selected: boolean, position: number, item: {}) => {
                          if (!interactive) {
                              return;
                          }

                          Container.getNetworkSender().sendUseInventoryItemById(shortcut.id);
                      }}
                      onLongClick={(position: number, item: {}) => {
                          handleLongClick(slot, shortcut);
                      }}
                />
                {shortcut.cooldown &&
                <div className={styles.cooldown} style={{animationDuration: shortcut.cooldown + 'ms'}}/>
                }
                </>
            }
            {shortcut !== null && !('quantity' in shortcut) &&
            <>
                <SkillDescription
                    key={slot}
                    position={slot}
                    id={shortcut.id}
                    isIcon={true}
                    isDisabled={!CanUseSKill(shortcut.id)}
                    isSelectable={true}
                    onClick={(selected: boolean, position: number, id: number) => {
                        if (!interactive) {
                            return;
                        }

                        Container.getNetworkSender().sendUseSpell(shortcut.id);
                    }}
                    onLongClick={(id: number) => {
                        handleLongClick(slot, shortcut);
                    }}
                >
                    {shortcut.cooldown &&
                    <div className={styles.cooldown} style={{animationDuration: shortcut.cooldown + 'ms'}}/>
                    }
                </SkillDescription>
            </>
            }
        </div>
    );
};

export default view(ShortcutContainer);