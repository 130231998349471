import {store} from "react-easy-state";

type GlobalStoreType = {
    clickPosition: [number, number],
    demo: boolean,
}

const globalStore: GlobalStoreType = store({
    clickPosition: [0, 0],
    demo: true,
});

export default globalStore;