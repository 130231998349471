import {MainScene} from "../scene/mainScene";
import {CreatureService} from "../service/creatureService";
import {EffectService} from "../service/effectService";
import WebSocketGetter from "./webSocketGetter";
import {MapService} from "../service/mapService";
import Container from "../di/container";
import inventoryStore from "../ui/store/InventoryStore";
import dropPickupStore from "../ui/store/DropPickupStore";
import {WindowCreator} from "../ui/service/WindowCreator";
import statisticsStore from "../ui/store/StatisticsStore";
import npcStore from "../ui/store/NpcStore";
import {WindowCloser} from "../ui/service/WindowCloser";
import equipmentStore from "../ui/store/EquipmentStore";
import {EQUIPMENT_SLOT} from "../ui/component/EquipmentContainer/EquipmentContainer";
import chatStore from "../ui/store/ChatStore";
import {ServerMessageCreator} from "../ui/service/ServerMessageCreator";
import shortcutsStore from "../ui/store/ShortcutsStore";
import {ShortcutsQuantityRefresher} from "../ui/service/ShortcutsQuantityRefresher";
import {ShortcutsCooldownRefresher} from "../ui/service/ShortcutsCooldownRefresher";
import {NotificationCreator} from "../ui/service/NotificationCreator";
import craftingStore from "../ui/store/CraftingStore";
import {CutsceneService} from "../service/cutsceneService";
import npcLogStore from "../ui/store/NpcLogStore";
import {LightService} from "../service/lightService";
import {ResetSelectedItems} from "../ui/service/ResetSelectedItems";
import storageStore from "../ui/store/StorageStore";
import {LoginState, loginStore} from "../ui/store/LoginStore";
import {TutorialState, tutorialStore} from "../ui/store/TutorialStore";
import questLogStore from "../ui/store/QuestLogStore";
import {NotificationType} from "../ui/atom/Notification/Notification";
import dailyRewardStore from "../ui/store/DailyRewardStore";
import globalStore from "../ui/store/GlobalStore";
import achievementsStore from "../ui/store/AchievementsStore";
import questObjectivesStore from "../ui/store/QuestObjectivesStore";
import levelUpStore from "../ui/store/LevelUpStore";
import {PlayMapSound, PlaySound} from "../util/PlaySound";
import {SoundEffect, SoundService} from "../service/soundService";
import {ClientItem} from "../util/ClientItems";
import {CameraZoom} from "../util/CameraZoom";
import {HudHide} from "../ui/service/HudHide";
import registerStore from "../ui/store/RegisterStore";
import {Confetti} from "../util/Confetti";
import leaderboardStore from "../ui/store/LeaderboardStore";
import {BoostAdd} from "../ui/store/BoostStore";
import collectionsStore from "../ui/store/CollectionsStore";

export default class NetworkReceiver {
    private socket: WebSocket;

    private mainScene: MainScene;
    private creatureService: CreatureService;
    private effectService: EffectService;
    private mapService: MapService;
    private cutsceneService: CutsceneService;
    private lightService: LightService;
    private soundService: SoundService;

    constructor(
        socket: WebSocketGetter,
    ) {
        this.socket = socket.get();

        this.mainScene = Container.getMainScene();
        this.creatureService = Container.getCreatureService();
        this.effectService = Container.getEffectService();
        this.mapService = Container.getMapService();
        this.cutsceneService = Container.getCutsceneService();
        this.lightService = Container.getLightService();
        this.soundService = Container.getSoundService();

        this.socket.onmessage = (event) => {
            if (!event.data.length) {
                return;
            }

            let msg = JSON.parse(event.data);

            switch (msg.packet) {
                case "serverStatus":
                    loginStore.serverOnline = true;
                    loginStore.serverStatus = msg.message;
                    tutorialStore.enabled = msg.tutorial;
                    break;
                case 'isPromoCodeValidResult':
                    if (!msg.valid) {
                        loginStore.isPromoValid = false;
                        registerStore.isPromoValid = false;
                    }
                    break;
                case "createAccountAndPlayerResult":
                    if (loginStore.state !== LoginState.STATE_HIDDEN) {
                        if (msg.success) {
                            loginStore.clear();
                            loginStore.state = LoginState.STATE_HIDDEN;
                            if (tutorialStore.enabled) {
                                tutorialStore.state = TutorialState.STATE_SHORTCUTS_INFO;
                            }
                        } else {
                            WindowCreator('notification', {
                                title: 'Try Again',
                                text: msg.message,
                                center: true,
                            });
                        }
                    } else {
                        WindowCreator('notification', {
                            title: msg.success ? 'Successfully registered' : 'Try Again',
                            text: msg.success ? 'Welcome alongside registered Heroes. Enjoy your time with Herodonia!' : msg.message,
                            center: true,
                        });

                        if (msg.success) {
                            WindowCloser('register');
                            this.creatureService.removeCreature(<number>this.mainScene.myPlayerId);
                        }
                    }

                    break;
                case "loginAccountResult":
                    if (!msg.success) {
                        WindowCreator('notification', {
                            title: 'Try Again',
                            text: msg.message,
                            center: true,
                        });
                        loginStore.email = '';
                        loginStore.password = '';
                    } else {
                        loginStore.state = LoginState.STATE_HERO_SELECTION;
                        loginStore.players = msg.message;
                    }
                    break;
                case "passwordResetRequestResult":
                    if (msg.success === true) {
                        loginStore.state = LoginState.STATE_LOST_PASSWORD_CONFIRMATION;

                        WindowCreator('notification', {
                            title: 'Success',
                            text: 'Check your mailbox for an email with Confirmation Code.',
                            center: true,
                        });
                    } else {
                        WindowCreator('notification', {
                            title: 'Something went wrong',
                            text: msg.message.length ? msg.message : 'Please try again later or contact support@herodonia.com',
                            center: true,
                        });

                        loginStore.state = LoginState.STATE_LOGIN;
                        loginStore.email = '';
                    }
                    break;
                case "passwordResetFinalResult":
                    if (msg.success === true) {
                        loginStore.state = LoginState.STATE_LOGIN;

                        WindowCreator('notification', {
                            title: 'Success',
                            text: 'New password has been set up correctly. Log in to Play.',
                            center: true,
                        });
                    } else {
                        WindowCreator('notification', {
                            title: 'Something went wrong',
                            text: msg.message.length ? msg.message : 'Please try again later or contact support@herodonia.com',
                            center: true,
                        });

                        loginStore.email = '';
                        loginStore.password = '';
                        loginStore.passwordRepeat = '';
                        loginStore.confirmationCode = '';
                    }
                    break;
                // case "createPlayerResult":
                //     this.guiScene.processCreatePlayerResult(msg.success, msg.message);
                //     break;
                case "loginPlayerResult":
                    if (!msg.success) {
                        WindowCreator('notification', {
                            title: 'Try Again',
                            text: msg.message,
                            center: true,
                        });
                    } else {
                        loginStore.state = LoginState.STATE_HIDDEN;
                        loginStore.players = [];
                        loginStore.email = '';
                        loginStore.password = '';
                        globalStore.demo = false;
                    }
                    break;
                case "resetPasswordResult":
                    if (!msg.success) {
                        loginStore.email = '';
                        loginStore.password = '';

                        WindowCreator('notification', {
                            title: 'Try Again',
                            text: msg.message,
                            center: true,
                        });
                    } else {
                        loginStore.state = LoginState.STATE_LOGIN;
                        loginStore.email = '';
                        loginStore.password = '';
                    }
                    break;
                case 'playerLoadFinished':
                    /** tbd */
                    break;
                case "creatureMove":
                    this.creatureService.directionMove(msg.id, msg.direction);

                    if (this.mainScene.myPlayerId === msg.id) {
                        this.mapService.updateEnvironment();
                    }
                    break;
                case "direction":
                    this.creatureService.directionChange(msg.id, msg.direction);
                    break;
                case "mapChange":
                    //teleport is done in "creatureTeleport"
                    const mapId = msg.mapId;
                    this.mapService.updateEnvironment(mapId);
                    this.creatureService.removeAll(false);
                    break;
                case "assignedId":
                    this.mainScene.myPlayerId =  msg.id;
                    this.creatureService.removeCreature(1337);
                    this.creatureService.removeCreature(1338);
                    this.creatureService.removeCreature(1339);
                    this.creatureService.removeCreature(1340);
                    this.mainScene.cameras.main.fadeIn(500);
                    break;
                case "newCreature":
                    this.creatureService.addCreature(
                        msg.id,
                        msg.x, msg.y,
                        msg.name,
                        msg.type,
                        msg.health, msg.maxHealth,
                        msg.walkTime,
                        msg.outfitId,
                        msg.direction
                    );

                    if (msg.id === this.mainScene.myPlayerId) {
                        this.mainScene.onPlayerLogged(msg.id);
                    }
                    break;
                case "newCreatures":
                    // @ts-ignore
                    msg.creatures.forEach((msg) => {
                        this.creatureService.addCreature(
                            msg.id,
                            msg.x, msg.y,
                            msg.name,
                            msg.type,
                            msg.health, msg.maxHealth,
                            msg.walkTime,
                            msg.outfitId,
                            msg.direction
                        );

                        if (msg.id === this.mainScene.myPlayerId) {
                            this.mainScene.onPlayerLogged(msg.id);
                        }
                    });
                    break;
                case "creatureOutfitUpdate":
                    this.creatureService.updateOutfit(msg.id, msg.outfitId)
                    break;
                case "walkTime":
                    this.creatureService.updateWalkTime(msg.id, msg.walkTime);
                    break;
                case "creatureTeleport":
                    this.creatureService.teleport(msg.id, msg.toX, msg.toY, msg.direction);

                    if (this.mainScene.myPlayerId === msg.id) {
                        this.mainScene.cameraBlip();
                        this.mapService.updateEnvironment();
                    }
                    break;
                case "updateHealth":
                    this.creatureService.updateHealth(msg.id, msg.health, msg.maxHealth);
                    if (msg.id === this.mainScene.myPlayerId) {
                        statisticsStore.health = msg.health;
                        statisticsStore.maxHealth = msg.maxHealth;
                    }
                    break;
                case "updateMana":
                    statisticsStore.mana = msg.mana;
                    statisticsStore.maxMana = msg.maxMana;
                    break;
                case "updateExperience":
                    statisticsStore.experience = msg.experience;
                    statisticsStore.maxExperience = msg.maxExperience;
                    break;
                case "updateInventoryCapacity":
                    statisticsStore.capacity = msg.capacity;
                    statisticsStore.maxCapacity = msg.maxCapacity;
                    break;
                case "updateStatistics":
                    delete msg.packet;
                    // @ts-ignore
                    this.creatureService.updateHealth(this.mainScene.myPlayerId, msg.health, msg.maxHealth);

                    statisticsStore.level = msg.level;
                    statisticsStore.archetype = msg.archetype;
                    statisticsStore.mana = msg.mana;
                    statisticsStore.maxMana = msg.maxMana;
                    statisticsStore.health = msg.health;
                    statisticsStore.maxHealth = msg.maxHealth;
                    statisticsStore.experience = msg.experience;
                    statisticsStore.maxExperience = msg.maxExperience;
                    statisticsStore.capacity = msg.capacity;
                    statisticsStore.maxCapacity = msg.maxCapacity;
                    statisticsStore.attack = msg.attack;
                    statisticsStore.maxDamage = msg.maxDamage;
                    statisticsStore.defence = msg.defence;
                    statisticsStore.damageReduction = msg.damageReduction;
                    statisticsStore.blockPercent = msg.blockPercent;
                    statisticsStore.strength = msg.strength;
                    statisticsStore.bonusStrength = msg.bonusStrength;
                    statisticsStore.agility = msg.agility;
                    statisticsStore.bonusAgility = msg.bonusAgility;
                    statisticsStore.magicPower = msg.magicPower;
                    statisticsStore.bonusMagicPower = msg.bonusMagicPower;
                    statisticsStore.availableStatisticsPoints = msg.availableStatisticsPoints;
                    break;
                case 'levelUp':
                    levelUpStore.boosts = msg.boosts;
                    levelUpStore.active = true;
                    WindowCreator('levelUpWindow');
                    break;
                case 'allBoosts':
                    for (let type in msg.boosts) {
                        for (let endTimestamp in msg.boosts[type]) {
                            BoostAdd(type, msg.boosts[type][endTimestamp], Number(endTimestamp));
                        }
                    }

                    break;
                case 'boost':
                    BoostAdd(msg.type, msg.amount, Number(msg.endTimestamp));
                    break;
                case "message":
                    ServerMessageCreator(msg.type, msg.msg);
                    break;
                case 'notification':
                    NotificationCreator(
                        msg.isTop,
                        msg.type,
                        msg.title,
                        msg.text,
                        5000,
                        msg.items ? msg.items : [],
                    );
                    break;
                case 'welcomeMessages':
                    let displayedMessages: string[] = [];
                    let stored = localStorage.getItem('welcomeMessages');
                    if (null !== stored) {
                        displayedMessages = JSON.parse(stored);
                    }

                    let parsed = '<h2 style="text-align: center;">Today\'s News</h2><ul>';

                    let isDisplay: boolean = false;
                    for (const [key, value] of Object.entries(msg.messages)) {
                        if (displayedMessages.includes(key)) {
                            continue;
                        }

                        isDisplay = true;

                        displayedMessages.push(key);
                        parsed += '<li>' + value + '</li>';
                    }

                    if (!isDisplay) {
                        break;
                    }

                    localStorage.setItem('welcomeMessages', JSON.stringify(displayedMessages))

                    parsed += '</ul>';

                    WindowCreator('notification', {
                        title: 'Herodonia News',
                        text: parsed,
                        center: false,
                    });
                    break;
                case 'popUpMessage':
                    WindowCreator('notification', {
                        title: msg.title,
                        text: msg.text,
                        center: msg.center,
                    });
                    break;
                case 'playerDeath':
                    this.mainScene.cameraBlipDeath();
                    WindowCreator('notification', {
                        title: 'You\'ve died!',
                        text: "Someone dragged you back to town and revived you.\n\nRemember that you lose some experience points each time.",
                        center: true,
                    });
                    break;
                case 'cutscene':
                    this.cutsceneService.play(msg.name);
                    break;
                case "effect":
                    this.effectService.createEffect(msg.id, msg.x, msg.y);
                    break;
                case "directedEffect":
                    this.effectService.createDirectedEffect(msg.id, msg.fromX, msg.fromY, msg.toX, msg.toY);
                    break;
                case "shootEffect":
                    this.effectService.createShootEffect(msg.id, msg.fromX, msg.fromY, msg.toX, msg.toY);
                    break;
                case "itemEffect":
                    this.effectService.createItemEffect(msg.itemIds, msg.x, msg.y);
                    break;
                case "textEffect":
                    this.effectService.createTextEffect(msg.text, msg.color, msg.x, msg.y, 750, msg.large ?? false);
                    if (msg.text.includes('I\'m too far away')) {
                        PlaySound(SoundEffect.IMPOSSIBLE);
                    }
                    break;
                case "barEffect":
                    this.effectService.createBarEffect(msg.from, msg.to, msg.max, msg.time, msg.color, msg.x, msg.y);
                    break;
                case "addContainer":
                    this.mapService.addContainer(msg.ownerId, msg.x, msg.y, msg.id);
                    break;
                case "addAllContainers":
                    // @ts-ignore
                    msg.containers.forEach((container) => {
                        this.mapService.addContainer(this.mainScene.myPlayerId === container.ownerId ? 44 : 43, container.x, container.y, container.id);
                    });
                    break;
                case 'removeContainerOwner':
                    this.mapService.removeContainerOwner(msg.x, msg.y, msg.id);
                    break;
                case "removeContainer":
                    this.mapService.removeContainer(msg.x, msg.y, msg.id);

                    if (dropPickupStore.uniqueId === msg.id) {
                        WindowCloser('dropPickup');
                    }

                    break;
                case "openTopContainer":
                    dropPickupStore.x = msg.x;
                    dropPickupStore.y = msg.y;
                    dropPickupStore.uniqueId = msg.id;
                    dropPickupStore.items = msg.items;
                    dropPickupStore.selectedPositions = [];

                    WindowCreator('dropPickup');

                    break;
                case "topContainerRemovePosition":
                    dropPickupStore.items = dropPickupStore.items.filter((item, index) => {
                        return index !== msg.position;
                    });

                    break;
                case "initialInventory":
                    inventoryStore.items = msg.items;
                    inventoryStore.gold = msg.gold
                    break;
                case "removeInventoryItem":
                    let qty = inventoryStore.items[msg.position].quantity;

                    if (qty <= msg.quantity || msg.quantity === null) {
                        inventoryStore.items = inventoryStore.items.filter((item, index) => {
                            return index !== msg.position;
                        });
                    } else {
                        let item = inventoryStore.items[msg.position];
                        item.quantity -= msg.quantity;
                        inventoryStore.items[msg.position] = item;
                    }

                    if (tutorialStore.state === TutorialState.STATE_SHORTCUTS_CLICK) {
                        tutorialStore.state = TutorialState.STATE_COLLECTABLES_INFO;
                    }

                    ShortcutsQuantityRefresher();
                    break;
                case "addInventoryItem":
                    PlaySound(SoundEffect.ITEM_PICKUP);

                    inventoryStore.items.push(msg.item);

                    if (tutorialStore.state === TutorialState.STATE_COLLECTABLES_CLICK) {
                        tutorialStore.state = TutorialState.STATE_INVENTORY_INFO;
                    }

                    ShortcutsQuantityRefresher();
                    break;
                case "updateInventoryItemQuantity":
                    let item = inventoryStore.items[msg.position];

                    if (item.quantity > msg.quantity) {
                        PlaySound(SoundEffect.ITEM_USE);
                    } else {
                        PlaySound(SoundEffect.ITEM_PICKUP);
                    }

                    item.quantity = msg.quantity;
                    inventoryStore.items[msg.position] = item;

                    ShortcutsQuantityRefresher();
                    break;
                case "equipItem":
                    if (!msg.initial) {
                        PlaySound(SoundEffect.ITEM_EQUIP);
                    }

                    const slot = EQUIPMENT_SLOT[msg.slot].toLowerCase();
                    equipmentStore[slot] = msg.item;
                    ResetSelectedItems();
                    break;
                case "deEquipItem":
                    PlaySound(SoundEffect.ITEM_EQUIP);
                    const slot2 = EQUIPMENT_SLOT[msg.slot].toLowerCase();
                    equipmentStore[slot2] = {};
                    break;
                case "updateEquipmentItemQuantity":
                    const slot3 = EQUIPMENT_SLOT[msg.slot].toLowerCase();
                    if (msg.quantity > 0) {
                        let item = equipmentStore[slot3];
                        item.quantity = msg.quantity;
                        equipmentStore[slot3] = {...item};
                    } else {
                        equipmentStore[slot3] = {};
                    }
                    break;
                case "startCooldown":
                    const clientItem = ClientItem(msg.id);
                    if (null !== clientItem) {
                        PlaySound(SoundEffect[clientItem['useSound']]);
                    } else if (msg.id === -1) {
                        PlaySound(SoundEffect.LONG_USE_SOUND);
                    }

                    ShortcutsCooldownRefresher(msg.id, msg.cooldown);
                    break;
                case "sound":
                    if (!this.cutsceneService.isActive()) {
                        PlayMapSound(SoundEffect[msg.sound], msg.x, msg.y);
                    }
                    break;
                case "shake":
                    this.mainScene.cameras.main.shake(200, 0.001, true);
                    break;
                case "confetti":
                    Confetti();
                    break;
                case "shortcuts":
                    Object.keys(msg.shortcuts).forEach((slot) => {
                        if (msg.shortcuts[slot] >= 1000000) {
                            shortcutsStore.shortcuts[slot] = {
                                id: msg.shortcuts[slot],
                                cooldown: null,
                                cooldownEndTimestamp: null
                            };
                        } else {
                            shortcutsStore.shortcuts[slot] = {
                                id: msg.shortcuts[slot],
                                quantity: 0,
                                cooldown: null,
                                cooldownEndTimestamp: null
                            };
                        }
                    });

                    ShortcutsQuantityRefresher();
                    break;
                case "targetLost":
                    if (this.mainScene.target !== undefined) {
                        this.mainScene.target.markSelected(false);
                        this.mainScene.target = undefined;
                    }
                    break;
                case "remove":
                    this.creatureService.removeCreature(msg.id);

                    /** todo: make it work with in-game registration */
                    // if (msg.id === this.mainScene.myPlayerId) {
                    //     this.mainScene.playPlayerDead();
                    // }
                    break;
                case "chat":
                    let prefix = '';
                    if (msg.sender) {
                        const now = new Date();
                        prefix = String(now.getHours()).padStart(2, '0') + ':' + String(now.getMinutes()).padStart(2, '0') + ' ' + msg.sender + ' (' + msg.level + '): ';
                    }

                    chatStore.messagesNew.unshift([prefix + msg.msg, msg.type]);

                    break;
                case "npcShopInit":
                    npcStore.shop = JSON.parse(msg.shop);
                    WindowCloser('npc-dialogue');
                    WindowCreator('npc-shop');

                    CameraZoom(1.25);
                    HudHide(true);
                    break;
                case "npcStorageInit":
                    storageStore.items = JSON.parse(msg.items);
                    WindowCloser('npc-dialogue');
                    WindowCreator('npcStorage');

                    CameraZoom(1.25);
                    HudHide(true);
                    break;
                case "npcDialogueInit":
                    // @ts-ignore
                    npcStore.clear();
                    npcStore.title = msg.name;
                    npcStore.image = msg.image;
                    npcStore.hasShop = msg.shop;

                    WindowCreator('npc-dialogue');

                    CameraZoom(1.25);
                    HudHide(true);
                    break;
                case "npcDialogueState":
                    npcStore.dialogues = msg.says;
                    npcStore.responses = msg.responses;
                    npcStore.forceDisplay = false;
                    npcStore.state = 0;
                    break;
                case "npcDialogueClose":
                    npcStore.dialogues = [];
                    npcStore.responses = {};
                    npcStore.shop = {};
                    npcStore.title = '';

                    WindowCloser('npc-dialogue');
                    break;
                case 'leaderboard':
                    leaderboardStore.leaderboard = msg.leaderboard;
                    break;
                case 'highscores':
                    leaderboardStore.highscores = msg.highscores;
                    break;
                case "updateInventoryGold":
                    PlaySound(SoundEffect.GOLD);
                    inventoryStore.gold = msg.gold;
                    break;
                case "removeStorageItem":
                    let qtyStorage = storageStore.items[msg.position].quantity;

                    if (qtyStorage <= msg.quantity || msg.quantity === null) {
                        storageStore.items = storageStore.items.filter((item, index) => {
                            return index !== msg.position;
                        });
                    } else {
                        let item = storageStore.items[msg.position];
                        item.quantity -= msg.quantity;
                        storageStore.items[msg.position] = item;
                    }
                    break;
                case "addStorageItem":
                    storageStore.items.push(msg.item);
                    break;
                case "updateStorageItemQuantity":
                    let itemStorage = storageStore.items[msg.position];
                    itemStorage.quantity = msg.quantity;
                    storageStore.items[msg.position] = itemStorage;
                    break;
                case "addAllCustomTiles":
                    //todo: remove it, move it to something else than timeout
                    setTimeout(() => {
                        msg.customTiles.forEach((msg) => {
                            this.mapService.addCustomTile(msg.x, msg.y, msg.tileId);
                        });
                    }, 1000)

                    break;
                case "addCustomTile":
                    this.mapService.addCustomTile(msg.x, msg.y, msg.tileId);
                    break;
                case "removeCustomTile":
                    this.mapService.removeCustomTile(msg.x, msg.y);
                    break;
                case "addAllCustomLights":
                    //todo: remove it, move it to something else than timeout
                    setTimeout(() => {
                        msg.customLights.forEach((msg) => {
                            this.lightService.addLight(0, msg.x, msg.y, msg.radius, msg.tint);
                        });
                    }, 1000)

                    break;
                case "addCustomLight":
                    this.lightService.addLight(
                        this.mapService.computeChunkID(msg.x, msg.y),
                        msg.x, msg.y, msg.radius, msg.tint
                    );
                    break;
                case "removeCustomLight":
                    this.lightService.removeLight(msg.x, msg.y);
                    break;
                case "addAllCollectables":
                    //todo: remove it, move it to something else than timeout
                    setTimeout(() => {
                        msg.collectables.forEach((msg) => {
                            this.mapService.addCollectable(msg.x, msg.y, msg.tileId);
                        });
                    }, 1000)

                    break;
                case "addCollectable":
                    this.mapService.addCollectable(msg.x, msg.y, msg.tileId);
                    break;
                case "removeCollectable":
                    this.mapService.removeCollectable(msg.x, msg.y);
                    break;
                case "craftingOpen":
                    craftingStore.title = msg.title;
                    craftingStore.x = msg.x;
                    craftingStore.y = msg.y;
                    craftingStore.types = msg.types;
                    WindowCreator('crafting');
                    break;
                case "allNpcLogs":
                    Object.keys(msg.npcs).forEach((npc, index) => {
                        npcLogStore.npcs[npc] = [];
                        msg.npcs[npc].forEach((message) => {
                            npcLogStore.npcs[npc].push(message);
                        })
                    });

                    break;
                case "addNpcLog":
                    if (npcLogStore.npcs[msg.npc] === undefined) {
                        npcLogStore.npcs[msg.npc] = [];
                    }
                    npcLogStore.npcs[msg.npc].push(msg.message);

                    NotificationCreator(
                        false,
                        NotificationType.TYPE_INFO,
                        msg.npc + ' - NPC Log Updated',
                        msg.message.slice(0, 50) + '...',
                        10000
                    );

                    break;
                case "allQuestLogs":
                    //ServerMessageCreator(0, event.data);
                    Object.keys(msg.quests).forEach((quest, index) => {
                        questLogStore.quests[quest] = {
                            'title': quest,
                            'states': [],
                            finished: false,
                        };
                        questLogStore.quests[quest]['finished'] = msg.quests[quest]['finished'] ?? false;
                        msg.quests[quest]['states'].forEach((message) => {
                            questLogStore.quests[quest]['states'].push(message);
                        })
                    });
                    break;
                case "addQuestLog":
                    //ServerMessageCreator(0, event.data);
                    if (questLogStore.quests[msg.quest] === undefined) {
                        questLogStore.quests[msg.quest] = {
                            title: msg.quest,
                            states: [],
                            finished: false,
                        };
                    }
                    questLogStore.quests[msg.quest]['states'].push(msg.message);
                    questLogStore.quests[msg.quest]['finished'] = msg.finished;

                    NotificationCreator(
                        false,
                        NotificationType.TYPE_INFO,
                        msg.quest + ' - Quest Log Updated',
                        msg.message,
                        10000
                    );

                    break;
                case 'allQuestObjectives':
                    Object.keys(msg.objectives).forEach((questName ) => {
                        let objectives = msg.objectives[questName];
                        // @ts-ignore
                        let questObjectives = questObjectivesStore.objectives.get(questName);

                        if (undefined === questObjectives) {
                            questObjectives = new Map<string, string>();
                            // @ts-ignore
                            questObjectivesStore.objectives.set(questName, questObjectives);
                        }

                        Object.keys(objectives).forEach((objectiveName) => {
                            let text = objectives[objectiveName];
                            // @ts-ignore
                            questObjectives.set(objectiveName, text);
                        });
                    });
                    break;
                case 'addQuestObjective':
                    let questObjectives = questObjectivesStore.objectives.get(msg.questName);

                    if (undefined === questObjectives) {
                        questObjectives = new Map<string, string>();
                        questObjectivesStore.objectives.set(msg.questName, questObjectives);
                    }

                    questObjectives.set(msg.objective, msg.text);

                    break;
                case "allAchievements":
                    Object.keys(msg.achievements).forEach((title, index) => {
                        achievementsStore.achievements[title] = msg.achievements[title]
                    });

                    break;
                case "addAchievement":
                    achievementsStore.achievements[msg.title] = msg.description;
                    PlaySound(SoundEffect.ACHIEVEMENT);

                    break;
                case 'allCollections':
                    msg.collections.forEach((collection) => {
                        collectionsStore.collections.push(collection);
                    });
                    break;
                case 'allKnownItems':
                    collectionsStore.knownItems = msg.items;
                    break;
                case 'knownItem':
                    collectionsStore.knownItems.push(msg.item);
                    break;
                case "light":
                    if (this.mapService.currentMapId === 0 || msg.level === 3) {
                        if (this.cutsceneService.isActive()) {
                            this.cutsceneService.setOriginalLightLevel(msg.level);
                        } else {
                            this.lightService.setLightLevel(msg.level);
                        }
                    }
                    break;
                case "dailyRewardOpen":
                    const defaultDate = new Date();
                    defaultDate.setTime(msg.claimedTimestamp * 1000);
                    dailyRewardStore.claimedAt = defaultDate;
                    dailyRewardStore.strike = msg.strike;
                    dailyRewardStore.message = msg.message ?? '';

                    WindowCreator('dailyReward');
                    break;
                // case "statusIcon":
                //     this.statusIcons.setStatusIcon(msg.iconType);
                //     break;
                case "pong":
                    console.log('Received pong');
                    break;
            }
        };
    }
}