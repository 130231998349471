import * as React from "react";
import Window, {WindowProps, WindowStyle} from "./Window";
import craftingStore from "../store/CraftingStore";
import {view} from "react-easy-state";
import styles from './Window.module.scss'
import CraftingRow from "../component/CraftingRow/CraftingRow";
import Accordion from "../component/Accordion/Accordion";
import {getQuantityOfItemId} from "../store/InventoryStore";
import Sprite from "../atom/Sprite/Sprite";
import TabbedWindows from "../component/TabbedWindows/TabbedWindows";
import Tab from "../component/TabbedWindows/Tab";

const CraftingWindow = ({children, windowState}: WindowProps) => {
    const canCraft = (requiredItems: [], requiredRelation: string = 'and'): boolean => {
        if (requiredRelation === 'and') {
            let allPassed = true;

            requiredItems.map((item) => {
                // @ts-ignore
                if (getQuantityOfItemId(item.id) < item.quantity) {
                    allPassed = false;
                }
            });

            return allPassed;
        } else {
            let onePassed = false;

            requiredItems.map((item) => {
                // @ts-ignore
                if (getQuantityOfItemId(item.id) >= item.quantity) {
                    onePassed = true;
                }
            });

            return onePassed;
        }
    }

    const drawSprites = (resultItems: []) => {
        return <>
            {
                // @ts-ignore
                resultItems.map((item) => <Sprite id={item.id} key={item.id}/>)
            }
        </>
    };

    const getFirstTabLabel = (): string => {
        if (!craftingStore.types.length) {
            return '';
        }

        return craftingStore.types[0].title ?? '';
    }

    return (
        <Window title={'<i class="bi bi-hammer"></i> Crafting'} windowState={windowState} windowStyle={WindowStyle.STYLE_SCROLL}>
            {craftingStore.types.length > 0 &&
                <TabbedWindows activeTab={getFirstTabLabel()}>
                    {craftingStore.types.map((type, index) => {
                        const craftable = canCraft(type.required, type.requiredRelation);
                        // @ts-ignore
                        const sprites = drawSprites(type.result);

                        return <Tab isActive={true} label={type.title} key={index} color={craftable ? "var(--color-accept-light)" : "var(--color-capacity)"} icon={sprites}>
                            <p className={styles.centerHorizontal}>{type.description}</p>
                            {type.requiredRelation === 'or' && type.required.map((item, indexNested) => {
                                // @ts-ignore
                                return <CraftingRow key={index + 'a' + indexNested} requiredItems={[item]} resultItems={type.result} canFailCreation={type.canFailCreation} craftKey={type.key} itemId={item.id}/>
                            })}
                            {type.requiredRelation !== 'or' &&
                                <CraftingRow key={type.title + 'b'} requiredItems={type.required} resultItems={type.result} canFailCreation={type.canFailCreation} craftKey={type.key} itemId={null}/>
                            }
                        </Tab>
                    })}
                </TabbedWindows>
            }
            {craftingStore.types.length === 0 &&
                <h1>You have no crafting options available here</h1>
            }
        </Window>
    );
};

export default view(CraftingWindow);