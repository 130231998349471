import * as React from "react";
import {view} from "react-easy-state";
import Typed from 'react-typed';
import {useEffect} from "react";
import {PlaySound} from "../../../util/PlaySound";
import {SoundEffect} from "../../../service/soundService";
import {StopSound} from "../../../util/StopSound";

type TypedPProps = {
    text: string,
    onComplete?: () => void,
    forceDisplay?: boolean
}

const TypedP = (props: TypedPProps) => {
    useEffect(() => {
        if (props.forceDisplay) {
            StopSound(SoundEffect.DIALOGUE_TYPING);
        } else {
            PlaySound(SoundEffect.DIALOGUE_TYPING, true);
        }
    }, []);

    return (
        <>
            {props.forceDisplay &&
                <p dangerouslySetInnerHTML={{
                    // @ts-ignore
                    __html: props.text
                }}></p>
            }
            {!props.forceDisplay &&
            <Typed
                onComplete={(self) => {
                    StopSound(SoundEffect.DIALOGUE_TYPING);
                    if (props.onComplete !== undefined) {
                        props.onComplete();
                    }
                }}
                contentType={"html"}
                style={{
                    display: 'block',
                    marginTop: '24px',
                    marginBottom: '24px'
                }}
                showCursor={false}
                strings={[props.text]}
                typeSpeed={25}>
            </Typed>
            }
            <i key={'indicator'} className="bi bi-arrow-right-circle-fill" style={{fontSize: '32px', position: 'absolute', right: '50px'}}/>
        </>
    );
};

export default view(TypedP);