import {MainScene} from "../scene/mainScene";
import Tilemap = Phaser.Tilemaps.Tilemap;
import Tileset = Phaser.Tilemaps.Tileset;
import Tile = Phaser.Tilemaps.Tile;

type Frame = {
    duration: number,
    tileId: number,
}

type AnimatedTile = {
    index: number,
    frames: Frame[],
    currentFrame: number,
    tiles: Tile[][],
    next: number,
}

type AnimatedTiles = {
    animatedTiles: AnimatedTile[]
}

export class AnimatedMapService {
    private active: boolean = false;
    private followTimeScale: boolean = true;
    private animatedTiles: Map<number, AnimatedTiles> = new Map<number, AnimatedTiles>();

    private readonly scene: MainScene;

    constructor(mainScene: MainScene) {
        this.scene = mainScene;
    }

    public resume(): void {
        this.active = true;
    }

    public pause(): void {
        this.active = false;
    }

    public shutdown(): void {
        this.animatedTiles.clear();
    }

    public initChunk(map: Tilemap, chunkId: number): void {
        const mapAnimData: AnimatedTile[] = this.getAnimatedTiles(map);

        const animatedTiles: AnimatedTiles = {
            animatedTiles: mapAnimData,
        };

        this.animatedTiles.set(chunkId, animatedTiles);
        this.active = true;
    }

    public initTile(tile: Tile, chunkId: number): void {
        this.pause();

        const animatedTiles = this.animatedTiles.get(chunkId);
        if (!animatedTiles) {
            return;
        }

        animatedTiles.animatedTiles.forEach((animatedTile: AnimatedTile) => {
            if (animatedTile.index !== tile.index) {
                return;
            }

            animatedTile.tiles.push([tile]);

            animatedTile.next = 100;
            animatedTile.currentFrame = 0;
        });

        this.animatedTiles.set(chunkId, animatedTiles);

        this.resume();
    }

    public clearChunk(chunkId: number): void {
        this.animatedTiles.delete(chunkId);
    }

    public update(time: number, delta: number): void {
        if (!this.active) {
            return;
        }

        const elapsedTime = delta * (this.followTimeScale ? this.scene.time.timeScale : 1);

        // @ts-ignore
        for (const mapAnimData of this.animatedTiles.values()) {
            mapAnimData.animatedTiles.forEach((animatedTile) => {
                animatedTile.next -= elapsedTime;

                if (animatedTile.next < 0) {
                    const currentIndex = animatedTile.currentFrame;
                    const oldTileId = animatedTile.frames[currentIndex].tileId;

                    let newIndex = currentIndex + 1;
                    if (newIndex > animatedTile.frames.length - 1) {
                        newIndex = 0;
                    }

                    animatedTile.next = animatedTile.frames[newIndex].duration;
                    animatedTile.currentFrame = newIndex;

                    animatedTile.tiles.forEach((layer) => {
                        this.updateLayer(animatedTile, layer, oldTileId);
                    });
                }
            });
        }
    }

    private updateLayer(animatedTile: AnimatedTile, layer: Tile[], oldTileId: number): void {
        // const tilesToRemove: Tile[] = [];
        const tileId: number = animatedTile.frames[animatedTile.currentFrame].tileId;

        layer.forEach((tile: Tile) => {
            if (oldTileId > -1 && (tile === null || tile.index !== oldTileId)) {
                //tilesToRemove.push(tile);
            } else {
                tile.index = tileId;
            }
        });

        // tilesToRemove.forEach(function (tile) {
        //     const pos = layer.indexOf(tile);
        //     if (pos > -1) {
        //         layer.splice(pos, 1);
        //     } else {
        //         console.error("This shouldn't happen. Not at all. Blame Phaser Animated Tiles plugin. You'll be fine though.");
        //     }
        // });
    }

    private getAnimatedTiles(map: Tilemap): AnimatedTile[] {
        const animatedTiles: AnimatedTile[] = [];

        map.tilesets.forEach((tileset: Tileset) => {
            const tileData = tileset.tileData;
            Object.keys(tileData).forEach((indexString) => {
                const index: number = parseInt(indexString);
                if (!tileData[index].hasOwnProperty('animation')) {
                    return;
                }

                const animatedTileData: AnimatedTile = {
                    index: index + tileset.firstgid,
                    frames: [],
                    currentFrame: 0,
                    tiles: [],
                    next: 0,
                };

                tileData[index].animation.forEach((frameData) => {
                    const frame: Frame = {
                        duration: frameData.duration,
                        tileId: frameData.tileid + tileset.firstgid
                    };
                    animatedTileData.frames.push(frame);
                });

                // time until jumping to next frame
                animatedTileData.next = animatedTileData.frames[0].duration;

                map.layers.forEach((layer) => {
                    if (layer && layer.tilemapLayer && layer.tilemapLayer.type === "StaticTilemapLayer") {
                        //animatedTileData.tiles.push([]);
                        return;
                    }

                    const tiles: Tile[] = [];
                    layer.data.forEach((tileRow) => {
                        tileRow.forEach((tile) => {
                            if (tile && tile.index - tileset.firstgid === index) {
                                tiles.push(tile);
                            }
                        });
                    });

                    animatedTileData.tiles.push(tiles);
                });

                animatedTiles.push(animatedTileData);
            });
        });

        return animatedTiles;
    }
}