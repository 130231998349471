import {store} from "react-easy-state";

type SettingsStore = {
    effectsVolume: number,
    backgroundVolume: number,
    musicVolume: number,
}

const settingsStore: SettingsStore = store({
    effectsVolume: 30,
    backgroundVolume: 20,
    musicVolume: 5,
});

export const loadSettings = () => {
    const serializedSettings = localStorage.getItem('settings');
    if (null === serializedSettings) {
        return;
    }

    const settings = JSON.parse(serializedSettings);
    for (const property in settings) {
        settingsStore[property] = settings[property];
    }
}

export const saveSettings = () => {
    localStorage.setItem('settings', JSON.stringify(settingsStore));
}

export default settingsStore;