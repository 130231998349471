import {MainScene} from "../scene/mainScene";
import {CreatureService} from "../service/creatureService";
import {EffectService} from "../service/effectService";
import {ContainerService} from "../service/containerService";
import {MapService} from "../service/mapService";
import NetworkReceiver from "../network/networkReceiver";
import NetworkSender from "../network/networkSender";
import WebSocketGetter from "../network/webSocketGetter";
import {CutsceneService} from "../service/cutsceneService";
import {LightService} from "../service/lightService";
import {AnimatedMapService} from "../service/animatedMapService";
import {SoundService} from "../service/soundService";

const Container = {
    getWebSocketGetter(): WebSocketGetter {
        // @ts-ignore
        if (window.webSocketGetter === undefined) {
            // @ts-ignore
            window.webSocketGetter = new WebSocketGetter();
        }

        // @ts-ignore
        return window.webSocketGetter;
    },
    getNetworkReceiver(): NetworkReceiver {
        // @ts-ignore
        if (window.networkReceiver === undefined) {
            // @ts-ignore
            window.networkReceiver = new NetworkReceiver(this.getWebSocketGetter());
        }

        // @ts-ignore
        return window.networkReceiver;
    },
    getNetworkSender(): NetworkSender {
        // @ts-ignore
        if (window.networkSender === undefined) {
            // @ts-ignore
            window.networkSender = new NetworkSender(this.getWebSocketGetter());
        }

        // @ts-ignore
        return window.networkSender;
    },
    getMainScene(): MainScene {
        // @ts-ignore
        return window.mainScene as MainScene;
    },
    getCreatureService(): CreatureService {
        // @ts-ignore
        if (window.creatureService === undefined) {
            // @ts-ignore
            window.creatureService = new CreatureService(this.getMainScene(), this.getLightService());
        }

        // @ts-ignore
        return window.creatureService;
    },
    getEffectService(): EffectService {
        // @ts-ignore
        if (window.effectService === undefined) {
            // @ts-ignore
            window.effectService = new EffectService(this.getMainScene());
        }

        // @ts-ignore
        return window.effectService;
    },
    getContainerService(): ContainerService {
        // @ts-ignore
        if (window.containerService === undefined) {
            // @ts-ignore
            window.containerService = new ContainerService(this.getMainScene());
        }

        // @ts-ignore
        return window.containerService;
    },
    getMapService(): MapService {
        // @ts-ignore
        if (window.mapService === undefined) {
            // @ts-ignore
            window.mapService = new MapService(this.getMainScene(), this.getLightService(), this.getAnimatedMapService(), this.getContainerService());
        }

        // @ts-ignore
        return window.mapService;
    },
    getAnimatedMapService(): AnimatedMapService {
        // @ts-ignore
        if (window.animatedMapService === undefined) {
            // @ts-ignore
            window.animatedMapService = new AnimatedMapService(this.getMainScene());
        }

        // @ts-ignore
        return window.animatedMapService;
    },
    getCutsceneService(): CutsceneService {
        // @ts-ignore
        if (window.cutsceneService === undefined) {
            // @ts-ignore
            window.cutsceneService = new CutsceneService(
                this.getMainScene(), this.getCreatureService(), this.getEffectService(), this.getMapService(), this.getLightService()
            );
        }

        // @ts-ignore
        return window.cutsceneService;
    },
    getLightService(): LightService {
        // @ts-ignore
        if (window.lightService === undefined) {
            // @ts-ignore
            window.lightService = new LightService(this.getMainScene());
        }

        // @ts-ignore
        return window.lightService;
    },
    getSoundService(): SoundService {
        // @ts-ignore
        if (window.soundService === undefined) {
            // @ts-ignore
            window.soundService = new SoundService(this.getMainScene());
        }

        // @ts-ignore
        return window.soundService;
    }
};

export default Container;