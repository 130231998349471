import {MainScene} from "../scene/mainScene";
import settingsStore from "../ui/store/SettingsStore";

export enum SoundEffect {
    //UI
    INTRO = 'intro.mp3',
    CLICK = 'click.mp3',
    TIP = 'tip.mp3',
    IMPOSSIBLE = 'impossible.mp3',
    NOTIFICATION = 'notification.mp3',
    DESTROY = 'destroy.mp3',
    BONUS = 'bonus.mp3',
    ACHIEVEMENT = 'achievement.mp3',
    DIALOGUE_TYPING = 'dialogue-typing.mp3',
    CHARGING = 'charging.mp3',
    //Crafting
    CRAFTING_GENERAL = 'crafting-general.mp3',
    CRAFTING_WEAPONRY = 'crafting-weaponry.mp3',
    //Collecting
    FISHING_ROD = 'fishing-rod.mp3',
    PICKAXE = 'pickaxe.mp3',
    SCYTHE = 'scythe.mp3',
    HOE = 'hoe.mp3',
    //Inventory, Equipment
    GOLD = 'gold.mp3',
    ITEM_PICKUP = 'item-pickup.mp3',
    KARMA = 'karma.mp3',
    ITEM_EQUIP = 'equipment.mp3',
    //Using Items
    ITEM_USE = 'item-use.mp3',
    LONG_USE_SOUND = 'long-use-sound.mp3',
    DRINK = 'drink.mp3',
    EATING = 'eating.mp3',
    //Combat
    DISTANCE_ATTACK = 'distance-attack.mp3',
    MELEE_ATTACK = 'melee-attack.mp3',
    MAGIC_ATTACK = 'magic-attack.mp3',
    HIT_CRITICAL = 'hit-critical.mp3',
    HIT_HEAVY = 'hit-heavy.mp3',
    HIT_PLAYER = 'hit-player.mp3',
    HIT_DEFENDED = 'hit-defended.mp3',
    DEATH = 'death.mp3',
    //Spells
    FIREBALL = 'fireball.mp3',
    SPELL_MELEE = 'spell-melee.mp3',
    SPELL_EARTH = 'spell-earth.mp3',
    SPELL_WIND = 'spell-wind.mp3',
    SPELL_HOLY = 'spell-holy.mp3',
    SPELL_HOLY_2 = 'spell-holy-2.mp3',
    SPELL_DARKNESS = 'spell-darkness.mp3',
    SPELL_DARKNESS_2 = 'spell-darkness-2.mp3',
    SPELL_WATER = 'spell-water.mp3',
    SPELL_ICE = 'spell-ice.mp3',
    SPELL_FREEZE = 'spell-freeze.mp3',
    SPELL_FIRE = 'spell-fire.mp3',
    SPELL_FIRE_2 = 'spell-fire-2.mp3',
    SPELL_FIRE_3 = 'spell-fire-3.mp3',
    SPELL_TELEPORT = 'spell-teleport.mp3',
    SPELL_MAGIC_BARRIER = 'spell-magic-barrier.mp3',
    SPELL_BUFF_END = 'spell-buff-end.mp3',
    //Map - General
    DOOR = 'door.mp3',
    //Footsteps
    FOOTSTEPS = 'footsteps.mp3',
}

export enum BackgroundSound {
    VILLAGE_AMBIENCE = 'village-ambience.mp3',
    FOREST_AMBIENCE = 'forest-ambience.mp3',
    GRASSLAND_AMBIENCE = 'grassland-ambience.mp3',
    RIVER_AMBIENCE = 'river-ambience.mp3',
    WATERFALL_AMBIENCE = 'waterfall-ambience.mp3',
    OCEAN_AMBIENCE = 'ocean-ambience.mp3',
    DUNGEON_AMBIENCE = 'dungeon-ambience.mp3',
}

export enum MusicSound {
    VILLAGE = 'village.mp3',
    INN = 'inn.mp3',
    FIELDS = 'fields.mp3',
    SACRED = 'sacred.mp3',
}

export type SoundType = SoundEffect|BackgroundSound|MusicSound;

export class SoundService {
    private muted: boolean = true;

    private readonly scene: MainScene;

    private soundsReference: Map<string, Phaser.Sound.BaseSound> = new Map<string, Phaser.Sound.BaseSound>();
    private playingNow: Array<SoundType> = [];

    constructor(
        mainScene: MainScene,
    ) {
        this.scene = mainScene;
    }

    public preload(): void {
        this.scene.load.setPath('assets/sound');

        const loadAudio = (value: string) => this.scene.load.audio(value, value);

        this.forEachObjectEntry(SoundEffect, loadAudio);
        this.forEachObjectEntry(BackgroundSound, loadAudio);
        this.forEachObjectEntry(MusicSound, loadAudio);

        this.scene.load.setPath('');
    }

    public create(): void {
        const addAudio = (value: string) => {
            this.soundsReference.set(value, this.scene.sound.add(value))
        };

        this.forEachObjectEntry(SoundEffect, addAudio);
        this.forEachObjectEntry(BackgroundSound, addAudio);
        this.forEachObjectEntry(MusicSound, addAudio);
    }

    public mute(): void {
        this.muted = true;
    }

    public unmute(): void {
        this.muted = false;
    }

    public play(soundEffect: SoundType, loop: boolean = false, initialVolume: number = 1.0): void {
        if (this.muted) {
            return;
        }

        let volume: number = this.calculateVolume(soundEffect, initialVolume);

        if (loop) {
            if (this.playingNow.includes(soundEffect)) {
                return;
            }

            this.playingNow.push(soundEffect);
        }

        if (Object.values(BackgroundSound).includes(soundEffect as BackgroundSound) || Object.values(MusicSound).includes(soundEffect as MusicSound)) {
            // @ts-ignore
            this.scene.tweens.killTweensOf(this.soundsReference.get(soundEffect));

            this.soundsReference.get(soundEffect)?.play({loop: true, volume: 0});
            this.scene.tweens.add({
                targets: this.soundsReference.get(soundEffect),
                volume: {
                    from: 0,
                    to: volume
                },
                duration: 1000
            });
        } else {
            this.scene.sound.play(soundEffect, {volume: volume, loop: loop});
        }
    }

    public stop(soundEffect: SoundType): void {
        if (Object.values(BackgroundSound).includes(soundEffect as BackgroundSound) || Object.values(MusicSound).includes(soundEffect as MusicSound)) {
            // @ts-ignore
            this.scene.tweens.killTweensOf(this.soundsReference.get(soundEffect));

            this.scene.tweens.add({
                targets:  this.soundsReference.get(soundEffect),
                volume: 0,
                duration: 1000,
                onComplete: tween => {
                    this.soundsReference.get(soundEffect)?.stop();
                }
            });
        } else {
            this.scene.sound.stopByKey(soundEffect);
        }

        this.playingNow = this.playingNow.filter((element, index) => element !== soundEffect);
    }

    public calculateVolume(soundEffect: SoundType, initialVolume: number = 1.0): number {
        let volume: number = settingsStore.effectsVolume * initialVolume;

        if (Object.values(BackgroundSound).includes(soundEffect as BackgroundSound)) {
            volume = settingsStore.backgroundVolume * initialVolume;
        }

        if (Object.values(MusicSound).includes(soundEffect as MusicSound)) {
            volume = settingsStore.musicVolume * initialVolume;
        }

        return volume/100.0;
    }

    public volumeUpdated(): void {
        this.soundsReference.forEach((sound, key) => {
            if (sound.isPlaying) {
                // @ts-ignore
                sound.volume = this.calculateVolume(key);
            }
        });
    }

    private forEachObjectEntry(object: object, callback: (value: string) => void): void {
        const entries = Object.values(object);

        entries.forEach((value, index) => {
            callback(value);
        });
    }
}