import {loginStore} from "../ui/store/LoginStore";

export default class WebSocketGetter {
    private socket: WebSocket | undefined;

    public get(): WebSocket {
        if (undefined === this.socket) {
            this.socket = new WebSocket(process.env.REACT_APP_SERVER_HOST ? process.env.REACT_APP_SERVER_HOST : 'ws://127.0.0.1:8082');

            this.socket.addEventListener('error', (event) => {
                loginStore.serverOnline = false;
                loginStore.serverStatus = 'Server is offline.';
            });

            this.socket.addEventListener('open', (event) => {
                // @ts-ignore
                this.socket.send(JSON.stringify({
                    packet: "clientLoaded",
                    agent: window.navigator.userAgent,
                    width: window.screen.width,
                    height: window.screen.height,
                    availWidth: window.screen.availWidth,
                    availHeight: window.screen.availHeight,
                }));
            });
        }

        return this.socket;
    }
}