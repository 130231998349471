import {store} from "react-easy-state";

type Collection = {
    title: string,
    code: string,
    items: any[],
}

type CollectionsStoreType = {
    collections: Collection[],
    knownItems: number[],
}

const collectionsStore: CollectionsStoreType = store({
    collections: [],
    knownItems: [],
});

export const KnownItem = (itemId: number): boolean => {
    return collectionsStore.knownItems.includes(itemId);
}

export default collectionsStore;