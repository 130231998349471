import * as React from "react";
import styles from './NpcStorage.module.scss'
import {view} from "react-easy-state";
import ItemContainer from "../ItemContainer/ItemContainer";
import storageStore from "../../store/StorageStore";
import {WindowCreator} from "../../service/WindowCreator";
import Container from "../../../di/container";
import inventoryStore from "../../store/InventoryStore";
import RangeSliderUncontrolled from "../../atom/RangeSlider/RangeSliderUncontrolled";
import npcStore from "../../store/NpcStore";
import {useRef} from "react";

type NpcStorageProps = {};

const NpcStorage = ({}: NpcStorageProps) => {
    const storageRangeRef = useRef<HTMLInputElement>(null);
    const inventoryRangeRef = useRef<HTMLInputElement>(null);

    return (
        <div className={styles.wrapper}>
            <div className={styles.itemContainers}>
                <ItemContainer
                    title={'Storage'}
                    items={storageStore.items}
                    isSelectable={true}
                    isSelectableMultiple={false}
                    noSelectOnlyClick={true}
                    onSelect={(selectedPositions, selectedIds, item) => {
                        let quantity = item['quantity'];
                        if (quantity > 1) {
                            WindowCreator(
                                'yes-no',
                                {
                                    title: 'Withdraw',
                                    text: 'Select desired quantity to withdraw from Storage',
                                    yesCallback: () => {
                                        // @ts-ignore
                                        Container.getNetworkSender().sendNpcStorageWithdraw(selectedPositions[0], storageRangeRef.current.value);
                                    },
                                    noCallback: () => {},
                                    rangeSlider:
                                        <RangeSliderUncontrolled
                                            name={'Quantity'}
                                            min={1}
                                            max={quantity}
                                            defaultValue={quantity}
                                            ref={storageRangeRef}
                                        />
                                }
                            );
                        } else {
                            Container.getNetworkSender().sendNpcStorageWithdraw(selectedPositions[0], quantity);
                        }
                    }}
                    capacity={100}
                />
                <ItemContainer
                    title={'Inventory'}
                    items={inventoryStore.items}
                    isSelectable={true}
                    isSelectableMultiple={false}
                    noSelectOnlyClick={true}
                    onSelect={(selectedPositions, selectedIds, item) => {
                        let quantity = item['quantity'];
                        if (quantity > 1) {
                            WindowCreator(
                                'yes-no',
                                {
                                    title: 'Put to Storage',
                                    text: 'Select desired quantity to put into Storage',
                                    yesCallback: () => {
                                        // @ts-ignore
                                        Container.getNetworkSender().sendNpcStorageStore(selectedPositions[0], inventoryRangeRef.current.value);
                                    },
                                    noCallback: () => {},
                                    rangeSlider:
                                        <RangeSliderUncontrolled
                                            name={'Quantity'}
                                            min={1}
                                            max={quantity}
                                            defaultValue={quantity}
                                            ref={inventoryRangeRef}
                                        />
                                }
                            );
                        } else {
                            Container.getNetworkSender().sendNpcStorageStore(selectedPositions[0], quantity);
                        }
                    }}
                    capacity={20}
                />
            </div>
            <span className={styles.info}>Tap on Item in your Inventory to put it into Storage. Opposite - to withdraw it.</span>
        </div>
    );
}

export default view(NpcStorage);