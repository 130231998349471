import {store} from "react-easy-state";

export enum LoginState {
    STATE_HIDDEN,
    STATE_LOGIN,
    STATE_HERO_SELECTION,
    STATE_LOST_PASSWORD_INITIALIZATION,
    STATE_LOST_PASSWORD_CONFIRMATION,
    STATE_REGISTRATION,
}

export type LoginPlayerType = {
    name: string,
    level: number,
    archetype: string,
}

export type LoginStoreType = {
    serverOnline: boolean,
    serverStatus: string,
    state: LoginState,
    name: string,
    email: string,
    password: string,
    passwordRepeat: string,
    promo: string,
    isPromoValid: boolean,
    terms: boolean,
    newsletter: boolean,
    confirmationCode: string
    players: LoginPlayerType[],
    invalidFieldsToMessage: {},
    clear: () => void,
}

export const loginStore: LoginStoreType = store({
    serverOnline: false,
    serverStatus: 'Connecting...',
    state: LoginState.STATE_LOGIN,
    name: '',
    email: '',
    password: '',
    passwordRepeat: '',
    promo: '',
    isPromoValid: true,
    terms: false,
    newsletter: false,
    confirmationCode: '',
    players: [],
    invalidFieldsToMessage: {},
    clear: () => {
        loginStore.state = LoginState.STATE_LOGIN;
        loginStore.name = '';
        loginStore.email = '';
        loginStore.password = '';
        loginStore.passwordRepeat = '';
        loginStore.promo = '';
        loginStore.isPromoValid = true;
        loginStore.terms = false;
        loginStore.newsletter = false;
        loginStore.confirmationCode = '';
        loginStore.players = [];
        loginStore.invalidFieldsToMessage = {};
    }
});