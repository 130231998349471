import {store} from "react-easy-state";

export enum TutorialState {
    STATE_HIDDEN,
    STATE_SHORTCUTS_INFO,
    STATE_SHORTCUTS_CLICK,
    STATE_COLLECTABLES_INFO,
    STATE_COLLECTABLES_CLICK,
    STATE_INVENTORY_INFO,
    STATE_INVENTORY_CLICK,
    STATE_MENU_INFO,
    STATE_MENU_CLICK,
    STATE_INVENTORY_OPENED_CLICK,
    STATE_INVENTORY_OPENED_INFO,
    // second tutorial (fighting)
    STATE_SETTING_SHORTCUT_INFO,
    STATE_SETTING_SHORTCUT_CLICK,
    STATE_SETTING_SHORTCUT_SELECT_SKILL_INFO,
    STATE_SETTING_SHORTCUT_SELECT_SKILL_CLICK,
}

export type TutorialStoreType = {
    state: TutorialState,
    enabled: boolean,
}

export const tutorialStore: TutorialStoreType = store({
    state: TutorialState.STATE_HIDDEN,
    enabled: true,
});