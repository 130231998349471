import * as React from "react";
import styles from './Checkbox.module.scss';
import {view} from "react-easy-state";

export type CheckboxProps = {
    name: string,
    onUpdate: (inputName: string, isChecked: boolean) => void,
    label: string,
    checked?: boolean,
    required?: boolean,
    error?: string|undefined,
};

const Checkbox = ({ name, onUpdate, label, checked = false, required = false, error = undefined }: CheckboxProps) => {
    return (
        <div className={styles.wrapper}>
            <input type="checkbox" defaultChecked={checked} onInput={(e) => {
                let isChecked = !!(e.target as HTMLInputElement).value;
                onUpdate(name, isChecked);
            }} className={`${styles.input} ${error !== undefined ? styles.error : ''}`} required={required} id={name}/>
            <label className={styles.label} htmlFor={name}>
                {required &&
                <span className={styles.required}> *</span>
                }
                <span dangerouslySetInnerHTML={{__html: label}}/>
            </label>
            {error !== undefined &&
            <small>{error}</small>
            }
        </div>
    );
};

export default view(Checkbox);