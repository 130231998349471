import React, {useEffect} from 'react';
import styles from './Button.module.scss'
import {PlaySound} from "../../../util/PlaySound";
import {SoundEffect} from "../../../service/soundService";

export enum ButtonType {
    TYPE_YES = 'yes',
    TYPE_NO = 'no',
    TYPE_ACCEPT = 'accept',
    TYPE_REJECT = 'reject',
    TYPE_CANCEL = 'cancel',
    TYPE_MENU = 'menu',
}

export enum ButtonSize {
    TYPE_SMALL = 'small',
    TYPE_MEDIUM = 'medium',
    TYPE_LARGE = 'large',
    TYPE_EXTRA_LARGE = 'extraLarge',
    TYPE_WIDE = 'wide',
}

type MyProps = {
    text: string,
    icon?: string,
    type: ButtonType,
    size?: ButtonSize,
    htmlType:  'button' | 'submit' | 'reset' | undefined,
    isActive?: boolean,
    click: any,
};

const Button = (props: MyProps) => {
    useEffect(() => {
        return () => (undefined);
    }, []);

    useEffect(() => {
    }, [props]);

    const accentClassName: string = styles[props.type];
    const inactiveClassName: string = styles['inactive'];
    const size = props.size ?? ButtonSize.TYPE_MEDIUM;

    const text: string = props.text ?? props.type[0].toUpperCase() + props.type.substring(1);

    return (
        <button
            onClick={props.isActive ? (event) => {PlaySound(SoundEffect.CLICK); props.click()} : undefined}
            type={props.htmlType}
            className={`${styles.button} ${accentClassName} ${styles[size]} ${props.isActive ? '' : inactiveClassName}`}
        >
            {props.icon &&
            <span className={styles.icon} dangerouslySetInnerHTML={{
                // @ts-ignore
                __html: props.icon
            }}/>
            }
            <span className={props.icon ? styles['iconText'] : ''}>
               {text}
            </span>
        </button>
    );
};

Button.defaultProps = {
    isActive: true,
    type: ButtonType.TYPE_ACCEPT,
    htmlType: 'button',
}

export default Button;