import * as React from "react";
import {useEffect, useState} from "react";
import styles from './ActionsPopup.module.scss'
import Button from "../../atom/Button/Button";
import ButtonContainer from "../../atom/ButtonContainer/ButtonContainer";
import {ActionsPopupDetailSingular} from "./ActionsPopupDetail";
import globalStore from "../../store/GlobalStore";

const ActionsPopup = () => {
    const [show, setShow] = useState<boolean>(false);
    const [details, setDetails] = useState<ActionsPopupDetailSingular[]>([]);

    const closeHandler = () => {
        setShow(false);
        setDetails([]);
    }

    useEffect(() => {
        window.addEventListener('actions-popup-create', (event) => {
            setShow(true);
            // @ts-ignore
            setDetails(event.detail.details);
        });
    }, []);

    return (
        <div className={styles.overlay} style={{
            visibility: show ? "visible" : "hidden",
        }} onClick={(event) => {
            closeHandler();
        }}
        >
            <div className={styles.actions} style={{
                visibility: show ? "visible" : "hidden",
                opacity: show ? 1 : 0,
                marginLeft: globalStore.clickPosition[0],
                marginTop: globalStore.clickPosition[1] - 64,
                transform: "translateX(-50%) translateY(-100%)"
            }}>
                <ButtonContainer>
                    {
                        details.map(function(detail, key) {
                            return <Button text={detail.text} type={detail.type} click={detail.callback} key={key}/>
                        })
                    }
                </ButtonContainer>
            </div>
        </div>
    );
}

export default ActionsPopup;