import {store} from "react-easy-state";

type NpcStoreType = {
    visible: boolean,
    state: number,
    title: string,
    image: string,
    dialogues: [],
    forceDisplay: boolean,
    responses: {},
    hasShop: boolean,
    shop: {},
    selectedIdPurchase: number|null,
    purchaseItemName: string,
    purchaseItemValue: number,
    selectedIdSell: number|null,
    sellItemName: string,
    sellItemQuantity: number,
    clear: () => void,
}

const npcStore: NpcStoreType = store({
    visible: false,
    state: 0,
    title: '',
    image: 'none',
    dialogues: [],
    forceDisplay: false,
    responses: {},
    hasShop: false,
    shop: {},
    selectedIdPurchase: null,
    purchaseItemName: '',
    purchaseItemValue: 0,
    selectedIdSell: null,
    sellItemName: '',
    sellItemQuantity: 0,
    clear: () => {
        npcStore.visible = false;
        npcStore.state = 0;
        npcStore.title = '';
        npcStore.image = 'none';
        npcStore.dialogues = [];
        npcStore.forceDisplay = false;
        npcStore.responses = {};
        npcStore.hasShop = false;
        npcStore.shop = {};
        npcStore.selectedIdPurchase = null;
        npcStore.purchaseItemName = '';
        npcStore.purchaseItemValue = 0;
        npcStore.selectedIdSell = null;
        npcStore.sellItemName = '';
        npcStore.sellItemQuantity = 0;
    }
});

export default npcStore;